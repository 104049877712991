import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
    selector: 'app-logo',
    templateUrl: './logo.component.html',
    styleUrls: ['./logo.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true
})
export class LogoComponent {
  @Input() size: number | '100%' | 'auto' = '100%';
}
