export enum InputTypeEnum {
  Date = 'date',
  Datetime = 'datetime-local',
  Email = 'email',
  Hidden = 'hidden',
  Number = 'number',
  Password = 'password',
  Range = 'range',
  Tel = 'tel',
  Text = 'text',
  Time = 'time',
  Url = 'url',
}

export enum InputModeEnum {
  None = 'none',
  Text = 'text',
  Decimal = 'decimal',
  Numeric = 'numeric',
  Tel = 'tel',
  Search = 'search',
  Email = 'email',
  Url = 'url',
}
