import { Pipe, PipeTransform } from '@angular/core';
import { Statistics } from '@app/config/statistics';
import { Player } from '@app/domain/players/models/player.model';

@Pipe({
    name: 'playerScore',
    standalone: true
})
export class PlayerScorePipe implements PipeTransform {
  transform(
    abilities: Player.Abilities | null | undefined,
    position?: Player.Position | null,
    overall?: number
  ): number {
    if (!abilities) {
      return overall || 0;
    }

    if (position === Player.Position.GoalKeeper) {
      return abilities.goalKeeping ? Math.round(abilities.goalKeeping / Statistics.maxPlayerAbility * 100) : 0;
    }

    if (position === Player.Position.Defense) {
      return Math.round((
        abilities.defense / Statistics.maxPlayerAbility * 100 +
        abilities.strength / Statistics.maxPlayerAbility * 100 +
        abilities.passing / Statistics.maxPlayerAbility * 100
      ) / 3);
    }

    if (position === Player.Position.Midfield) {
      return Math.round((
        abilities.speed / Statistics.maxPlayerAbility * 100 +
        abilities.passing / Statistics.maxPlayerAbility * 100 +
        abilities.dribbling / Statistics.maxPlayerAbility * 100 +
        abilities.strength / Statistics.maxPlayerAbility * 100
      ) / 4);
    }

    if (position === Player.Position.Offense) {
      return Math.round((
        abilities.shooting / Statistics.maxPlayerAbility * 100 +
        abilities.speed / Statistics.maxPlayerAbility * 100 +
        abilities.dribbling / Statistics.maxPlayerAbility * 100
      ) / 3);
    }

    const calculatedAbilities = [
      abilities.shooting / Statistics.maxPlayerAbility * 100,
      abilities.speed / Statistics.maxPlayerAbility * 100,
      abilities.dribbling / Statistics.maxPlayerAbility * 100,
      abilities.defense / Statistics.maxPlayerAbility * 100,
      abilities.strength / Statistics.maxPlayerAbility * 100,
      abilities.passing / Statistics.maxPlayerAbility * 100
    ];

    let abilitiesTotal: number = 0;
    // if (position === Player.Position.Midfield) {
    //   const usedAbilityCount = 3;
    //   abilitiesTotal = calculatedAbilities
    //     .sort((a,b) => b - a)
    //     .slice(0, usedAbilityCount)
    //     .reduce((prev, next) => prev + next);
    //   console.log(Math.round(abilitiesTotal));
    //   return Math.round(abilitiesTotal / usedAbilityCount);
    // }

    abilitiesTotal = calculatedAbilities.reduce((prev, next) => prev + next, 0);
    return Math.round(abilitiesTotal / Statistics.maxStats);

    // return Math.round(abilities.overall);
  }
}
