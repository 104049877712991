import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PipesModule } from '@app/modules/shared/pipes/pipes.module';

import { PlayerClassBadgeComponent } from './player-class-badge.component';

@NgModule({
    exports: [
        PlayerClassBadgeComponent
    ],
    imports: [
        CommonModule,
        PipesModule,
        PlayerClassBadgeComponent,
    ]
})
export class PlayerClassBadgeModule {}
