import { Pipe, PipeTransform } from '@angular/core';
import { Statistics } from '@app/config/statistics';
import { Player } from '@app/domain/players/models/player.model';

@Pipe({
    name: 'playerClass',
    standalone: true
})
export class PlayerClassPipe implements PipeTransform {
  transform(value: number): Player.Class {
    if (!value) {
      return Player.Class.X;
    }
    const classSymbol = Object.keys(Player.Class).find(playerClass => value >= Player.ClassThreshold[playerClass]) || Player.Class.X;
    return Player.Class[classSymbol];
  }
}
