import { Injectable, NgZone } from '@angular/core';
import { Observable, of } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class ZoneUtilsService {
    constructor(private ngZone: NgZone) {}

    /**
     * Invoke this method instead of hacking with setTimeout(() => {}, 0)
     */
    scheduleDelayedAction(): Observable<void> {
        if (this.ngZone.isStable) {
            return of(undefined);
        }

        return this.ngZone.onStable.pipe(take(1));
    }
}
