import { Pipe, PipeTransform } from '@angular/core';
import { Timestamp } from 'firebase/firestore';

import { DateFormatter } from '@app/utils/date-formatter';

@Pipe({
    name: 'playtime',
    standalone: true
})
export class PlaytimePipe implements PipeTransform {
  transform(startTime: Timestamp, endTime: Timestamp, format: 'hours' | 'minutes' = 'minutes'): string {
    const suffix = format.charAt(0);
    const difference = DateFormatter.timeDifference(startTime, endTime, format);
    return `${difference}${suffix}`;
  }
}
