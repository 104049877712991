import { Pipe, PipeTransform } from '@angular/core';
import { Player } from '@app/domain/players/models/player.model';

@Pipe({
    name: 'playerPosition',
    standalone: true
})
export class PlayerPositionPipe implements PipeTransform {
  transform(position: Player.Position | null): string {
    switch (position) {
      default:
        return 'X';
      case Player.Position.Defense:
        return 'DEF';
      case Player.Position.Offense:
        return 'ATT';
      case Player.Position.Midfield:
        return 'MID';
      case Player.Position.GoalKeeper:
        return 'GK';
    }
  }
}
