import { ComponentRef } from '@angular/core';
import { firstValueFrom, Observable, Subject } from 'rxjs';

import { ModalComponent } from '@app/modules/shared/modal/modal.component';
import { take } from 'rxjs/operators';

export namespace Modal {
  export abstract class Model<InputType, ResultType> {
    modalInstance: ModalRef<InputType, ResultType>;

    abstract onInjectInputs(inputs: InputType): void;

    close(output?: ResultType): void {
      this.modalInstance?.close(output);
    }

    dismiss(output?: ResultType): void {
      this.modalInstance?.dismiss(output);
    }
  }

  export class ModalRef<InputType, ResultType> {
    private result$ = new Subject<ResultType | undefined>();

    constructor(
      private modalContainer: ComponentRef<ModalComponent>,
      private modal: ComponentRef<Model<InputType, ResultType>>
    ) {
      if (this.modal) {
        this.modal.instance.modalInstance = this;
      }
    }

    close(output?: ResultType): void {
      this.result$.next(output);
      this.destroy();
    }

    dismiss(output?: ResultType): void {
      this.result$.error(output);
      this.destroy();
    }

    onResult(): Promise<ResultType | undefined> {
      return firstValueFrom(this.result$);
    }

    onConfirm(): Observable<ResultType | undefined> {
      return this.result$.asObservable().pipe(take(1));
    }

    private destroy(): void {
      this.modal?.destroy();
      this.modalContainer.destroy();
      this.result$.complete();
    }
  }
}
