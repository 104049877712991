import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { StorageKeysEnum } from '@app/config/storage-keys.enum';
import { NewUpdateModalComponent } from '@app/modules/shared/modal/components/new-update-modal/new-update-modal.component';
import { ModalService } from '@app/modules/shared/modal/services/modal.service';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppUpdateService {
  constructor(
    private readonly swUpdate: SwUpdate,
    private readonly modalService: ModalService
  ) {}

  checkAvailableUpdate(): void {
    if (this.swUpdate.isEnabled) {
      this.swUpdate.checkForUpdate()

        .then(hasNewUpdate => hasNewUpdate
          ? this.modalService.open<unknown, boolean, NewUpdateModalComponent>(NewUpdateModalComponent).onResult()
          : false
        )
        .then(hasNewUpdate => hasNewUpdate
          ? this.swUpdate.activateUpdate()
          : undefined
        )
        .then(hasNewUpdate => {
          if (hasNewUpdate) {
            localStorage.setItem(StorageKeysEnum.RememberLastPage, 'true');
            document.location.reload();
          }
        });
    }
  }
}
