import { Pipe, PipeTransform } from '@angular/core';

import { Options } from '@app/domain/options/models/options.model';
import { Venue } from '@app/domain/venues/models/venue.model';

@Pipe({
    name: 'pitchSize',
    standalone: true
})
export class PitchSizePipe implements PipeTransform {
  transform(size: Venue.PitchSize[]): string {
    const options = Options.statusOptions(Venue.PitchSize);
    return size?.length
      ? size.map(type => options.find(option => option.id === type)?.name || '?')
        .sort((a,b) =>
          Number(a.split('v')[0]) - Number(b.split('v')[0])
        ).join(', ')
      : 'N/A';
  }
}
