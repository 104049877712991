import { Injectable } from '@angular/core';
import {
  collection,
  collectionData,
  CollectionReference,
  deleteDoc,
  doc,
  docData,
  DocumentReference,
  Firestore,
  getDoc,
  getDocs,
  setDoc,
  UpdateData,
  Timestamp,
  updateDoc,
  writeBatch, collectionGroup, Query, query, where, collectionSnapshots,
} from '@angular/fire/firestore';
import { BehaviorSubject, Observable } from 'rxjs';
import { combineLatestWith, map } from 'rxjs/operators';

import { RolesEnum } from '@app/config/roles.enum';
import { User } from '@app/domain/users/models/user.model';
import { UsersService } from '@app/domain/users/services/users.service';
import { FirestoreService } from '@app/domain/firestore/firestore.service';
import { GroupsQueries, GroupsQueryParams } from '@app/domain/groups/services/groups.queries';
import { Group } from '@app/domain/groups/models/group.model';
import Model = Group.Model;
import Params = GroupsQueryParams.Params;

@Injectable({
  providedIn: 'root'
})
export class GroupsService extends FirestoreService<Model> {
  static readonly collectionId: string = 'groups';
  static readonly subCollectionId: string = 'members';
  private readonly queries = new GroupsQueries(this.collectionRef);

  private memberRoles$ = new BehaviorSubject<Group.MemberRoles | null>(null);

  constructor(
    override firestore: Firestore,
    private readonly usersService: UsersService,
  ) {
    super(GroupsService.collectionId, firestore);
  }

  get userGroupRoles() {
    return this.memberRoles$.value;
  }

  get userGroupRoles$() {
    return this.memberRoles$.asObservable();
  }

  get userMembersCollectionGroup() {
    return query(collectionGroup(
      this.firestore, GroupsService.subCollectionId) as Query<Group.Member>,
      where('id', '==', this.usersService.currentUserId)
    );
  }

  membersRef(group: string) {
    return collection(
      this.firestore,
      `${GroupsService.collectionId}/${group}/${GroupsService.subCollectionId}`
    ) as CollectionReference<Group.Member>;
  }

  memberRef(group: string, member: string) {
    return doc(
      this.firestore,
      `${GroupsService.collectionId}/${group}/${GroupsService.subCollectionId}/${member}`
    ) as DocumentReference<Group.Member>;
  }

  override async fetchAll() {
    return super.fetchAll(this.queries.sortByDate());
  }

  override observeAll() {
    return super.observeAll(this.queries.sortByDate());
  }

  async fetchAllByParams(params: Params) {
    const snapshots = await getDocs(this.queries.byParams(params));
    return snapshots.docs.map(snapshot => snapshot.data());
  }

  observeAllByParams(params: Params) {
    return collectionData(this.queries.byParams(params));
  }

  async fetchMember(group: string, id: string) {
    const snapshot = await getDoc(this.memberRef(group, id));
    return snapshot.data();
  }

  observeMember(group: string, id: string) {
    return docData(this.memberRef(group, id));
  }

  async fetchMembers(group: string) {
    const snapshots = await getDocs(this.membersRef(group));
    const members = snapshots.docs.map(snapshot => snapshot.data());
    const users = await this.usersService.fetchForGroups([group]);
    return users.map(user => ({...user, ...members.find(member => member.id === user.id) }))
  }

  observeMembers(group: string): Observable<Group.UnionMember[]> {
    return collectionData(this.membersRef(group))
      .pipe(
        combineLatestWith(this.usersService.observeForGroups([group])),
        map(([members, users]) =>
          users.map(user => ({...user, ...members.find(member => member.id === user.id) }))
        )
      );
  }

  observeMembersCount(group: string) {
    return collectionSnapshots(this.membersRef(group)).pipe(map(snapshots => snapshots.length));
  }

  observeUserGroupRoles() {
    return collectionData(this.userMembersCollectionGroup)
      .pipe(map(members => {
        const groups: Group.MemberRoles = {
          [RolesEnum.Admin]: false,
          [RolesEnum.Organiser]: false,
          [RolesEnum.Analyst]: false,
          groups: {},
        };
        members.forEach(member => {
          if (member[RolesEnum.Admin]) {
            groups[RolesEnum.Admin] = true;
          }
          if (member[RolesEnum.Organiser]) {
            groups[RolesEnum.Organiser] = true;
          }
          if (member[RolesEnum.Analyst]) {
            groups[RolesEnum.Analyst] = true;
          }
          groups.groups[member.group] = {
            [RolesEnum.Admin]: member[RolesEnum.Admin],
            [RolesEnum.Organiser]: member[RolesEnum.Organiser],
            [RolesEnum.Analyst]: member[RolesEnum.Analyst],
          }
        });
        this.memberRoles$.next(groups);
        return groups;
      }));
  }

  override async create(model: Group.Model) {
    const group = {
      ...model,
      createdAt: Timestamp.now(),
      createdBy: this.usersService.currentUserId
    };
    return super.create(group)
      .then(() => this.createMember({
        id: this.usersService.currentUserId,
        group: model.id,
        admin: true,
        analyst: this.usersService.currentUser?.analyst || false,
        organiser: this.usersService.currentUser?.organiser || false,
      }));
  }

  async createMember(model: Group.Member) {
    if (!model) {
      return;
    }
    return setDoc(this.memberRef(model.group, model.id), model);
  }

  async updateMember(group: string, id: string, model: UpdateData<Group.Member | null>): Promise<void> {
    if (!model) {
      return;
    }
    return updateDoc(this.memberRef(group, id), model);
  }

  async deleteMember(group: string, id: string) {
    if (!group || !id) {
      return;
    }
    return deleteDoc(this.memberRef(group, id));
  }

  async addUsersToGroup() {
    const snapshots = await getDocs(collection(this.firestore, UsersService.collectionId));
    const batch = writeBatch(this.firestore);
    for (let snapshot of snapshots.docs) {
      const user = snapshot.data() as User.Model;
      if (user.groups?.length) {
        for (let group of user.groups) {
          const memberRef = this.memberRef(group, user.id);
          const member = await getDoc(memberRef);
          if (!member.exists()) {
            batch.set(memberRef, {
              id: user.id,
              group,
              admin: user.admin,
              analyst: user.analyst,
              organiser: user.organiser
            });
          }
        }
      }
    }
    return batch.commit();
  }
}
