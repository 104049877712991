import {
  Component,
  ComponentRef,
  Type,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { Modal } from '@app/modules/shared/modal/models/modal.model';

@Component({
    selector: 'app-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.scss'],
    standalone: true
})
export class ModalComponent {
  @ViewChild('modalContainer', { read: ViewContainerRef }) private modalContainer: ViewContainerRef;

  createModal<
    InputType,
    ResultType,
    ModelComponentType extends Modal.Model<InputType, ResultType>
  >(component: Type<ModelComponentType>): ComponentRef<ModelComponentType> {
    this.modalContainer?.clear();

    return this.modalContainer?.createComponent(component, { index: 0 });
  }
}
