import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Auth, UserCredential, UserMetadata } from '@angular/fire/auth';
import { updateDoc, deleteDoc, Firestore } from '@angular/fire/firestore';
import { Storage, deleteObject, listAll, ref } from '@angular/fire/storage';
import { defaultCountry } from '@static/countries';
import { BehaviorSubject } from 'rxjs';
import { map, shareReplay, tap } from 'rxjs/operators';

import { Player } from '@app/domain/players/models/player.model';
import { UsersQueries } from '@app/domain/users/services/users.queries';
import { FirestoreService } from '@app/domain/firestore/firestore.service';
import { StorageKeysEnum } from '@app/config/storage-keys.enum';
import { AuthService } from '@app/domain/auth/services/auth.service';
import { User } from '@app/domain/users/models/user.model';
import { NotificationService } from '@app/modules/shared/notification/services/notification.service';
import Model = User.Model;

@Injectable({
  providedIn: 'root'
})
export class UsersService extends FirestoreService<Model> {
  static readonly collectionId: string = 'users';
  private cachedUser$ = new BehaviorSubject<Model | undefined>(undefined);
  private readonly queries = new UsersQueries(this.collectionRef);

  get currentUser$() {
    return this.cachedUser$.asObservable();
  }

  get currentUser() {
    return this.cachedUser$.getValue();
  }

  get currentUserId() {
    return this.currentUser?.id || '';
  }

  get currentUserRef() {
    return this.ref(this.currentUserId);
  }

  constructor(
    private router: Router,
    private auth: Auth,
    override firestore: Firestore,
    private storage: Storage,
    private authService: AuthService,
    private notificationService: NotificationService,
  ) {
    super(UsersService.collectionId, firestore);
  }

  async deleted(id: string) {
    const snapshot = id ? await this.fetch(id) : null;
    return snapshot?.deleted || false;
  }

  override observe(id: string) {
    return super.observe(id).pipe(
      tap(user => {
        if (user && (user.id === this.currentUser?.id)) {
          this.cachedUser$.next(user);
        }
      }),
      shareReplay(1)
    );
  }

  override observeAll() {
    return super.observeAll(this.queries.sortedByName())
      .pipe(map(users => User.sortByDisplayName(users)));
  }

  override async fetchAll() {
    return super.fetchAll(this.queries.sortedByName())
      .then(users => User.sortByDisplayName(users));
  }

  async fetchForGroups(groups: string[]) {
    return super.fetchAll(this.queries.groups({ groups }))
      .then(users => User.sortByDisplayName(users));
  }

  observeForGroups(groups: string[]) {
    return super.observeAll(this.queries.groups({ groups }))
      .pipe(map(users => User.sortByDisplayName(users)));
  }

  async fetchOrganisers() {
    return super.fetchAll(this.queries.organiser())
      .then(users => User.sortByDisplayName(users));
  }

  async fetchCurrent() {
    const authUser = await this.authService.authUser$();
    return authUser
      ? await super.fetch(authUser.uid)
        .then(user => {
          this.cachedUser$.next(user || undefined);
          return user;
        })
        .catch(errorResponse => {
          this.notificationService.error(errorResponse.message);
          return null;
        })
      : null;
  }

  async updateCurrent(model: Partial<User.Model | null>) {
    if (model?.displayName && model.photoUrl) {
      await this.authService.updateAuthUserProfile(model);
    }
    return await this.update(this.currentUserId, model);
  }

  async deleteData(id: string) {
    return updateDoc(this.ref(id), {
      active: false,
      displayName: null,
      email: null,
      phoneNumber: null,
      photoUrl: null,
      deleted: true,
    });
  }

  async restoreData(credential: UserCredential) {
    return updateDoc(this.ref(credential.user.uid), {
      active: true,
      deleted: false,
      displayName: credential.user.displayName,
      email: credential.user.email,
      emailVerified: credential.user.emailVerified,
      metadata: {
        creationTime: credential.user.metadata.creationTime,
        lastSignInTime: credential.user.metadata.lastSignInTime,
      },
      phoneNumber: credential.user.phoneNumber,
      photoUrl: credential.user.photoURL,
    });
  }

  async hardDelete(id: string) {
    // Delete pictures
    const images = await listAll(ref(this.storage, `${UsersService.collectionId}/${id}`));
    for (const itemsRef of images.items) {
      await deleteObject(itemsRef);
    }

    // Delete user from database
    return deleteDoc(this.ref(id));
  }

  async signInWithPhoneNumber(phoneNumber: string) {
    return this.authService.signInWithPhoneNumber(phoneNumber)
      .catch(errorResponse => {
        this.notificationService.error(errorResponse.message);
        throw errorResponse;
      });
  }

  async confirmWithCode(code: string, displayName?: string) {
    return this.authService.getCredentialsWithConfirmationCode(code)
      .then(credentials => {
        if (credentials) {
          return displayName
            ? this.handleUserRegistration(credentials, displayName)
            : this.handleUserLogin(credentials)
        }
        return null;
      })
      .catch(errorResponse => {
        this.notificationService.error(errorResponse.message);
        throw errorResponse;
      });
  }

  async logout() {
    this.notificationService.clear();
    try {
      await this.authService.signOut();
      this.cachedUser$.next(undefined);
    } catch(errorResponse: any) {
      this.notificationService.error(errorResponse.message);
      throw errorResponse;
    }
  }

  async handleUserRegistration(credentials: UserCredential, displayName: string) {
    return this.authService.updateAuthUserProfile({ displayName })
      .then(() => this.handleUserLogin(credentials));
  }

  async handleUserLogin(credentials: UserCredential) {
    const user = credentials.user;
    const userExists = await this.exists(user.uid);
    const metadata: UserMetadata = {
      creationTime: credentials.user.metadata.creationTime,
      lastSignInTime: credentials.user.metadata.lastSignInTime,
    }
    if (userExists) {
      const deletedUser = await this.deleted(user.uid);
      // If user has been deleted, restore it
      if (deletedUser) {
        await this.restoreData(credentials);
      } else {
        // Or update with metadata
        await this.update(user.uid, { metadata });
      }
    } else {
      // If new user, create it
      await this.create({
        id: user.uid,
        abilities: {
          overall: 0,
          dribbling: 0,
          shooting: 0,
          passing: 0,
          strength: 0,
          defense: 0,
          speed: 0,
          goalKeeping: 0,
        },
        active: true,
        admin: false,
        analyst: false,
        country: defaultCountry.id,
        deleted: false,
        displayName: user.displayName,
        email: user.email,
        emailVerified: user.emailVerified,
        groups: [],
        hasMultiSport: false,
        injured: false,
        language: localStorage.getItem(StorageKeysEnum.Language) || 'en-GB',
        leg: null,
        metadata,
        organiser: false,
        payments: {
          blik: null,
          cash: false,
          paypal: null,
          preferred: null,
          revolut: null,
          transfer: null,
        },
        phoneNumber: user.phoneNumber,
        photoUrl: user.photoURL,
        positions: {
          best: null,
          [Player.Position.Offense]: 0,
          [Player.Position.Defense]: 0,
          [Player.Position.Midfield]: 0,
          [Player.Position.GoalKeeper]: 0,
        },
        preferredPosition: Player.Position.Free,
      });
    }

    return this.fetchCurrent();
  }
}
