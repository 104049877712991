import { Component, Input } from '@angular/core';
import { NgControl } from '@angular/forms';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { Icons } from '@app/config/icons';
import { BaseControlValueAccessorComponent } from '@app/modules/shared/form/components/base-control-value-accessor/base-control-value-accessor.component';
import { InputModeEnum, InputTypeEnum } from '@app/modules/shared/form/components/input/enums/input-type.enum';
import { InputSizeEnum } from '@app/modules/shared/form/components/input/enums/input-size.enum';
import { CustomValidators } from '@app/utils/customValidators';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-input',
    templateUrl: './input.component.html',
    styleUrls: ['./input.component.scss'],
    standalone: true,
    imports: [NgIf, FaIconComponent],
})
export class InputComponent extends BaseControlValueAccessorComponent<string | number> {
  @Input() label: string;
  @Input() icon: IconProp;
  @Input() set type(type: InputTypeEnum) {
    this._type = type;
  }
  get type(): InputTypeEnum {
    return this._type;
  }
  @Input() placeholder = '';
  @Input() prefix = '';
  @Input() suffix = '';
  @Input() size = InputSizeEnum.Large;
  @Input() pattern: string;
  @Input() maxlength: number;
  @Input() max: number;
  @Input() showPasswordToggle: boolean;
  @Input() showPasswordValidation = true;
  @Input() inputmode: InputModeEnum;

  passwordVisible = false;

  readonly InputTypeEnum = InputTypeEnum;
  readonly CustomValidators = CustomValidators;
  readonly ActionIcons = Icons.Actions;

  private _type = InputTypeEnum.Text;

  constructor(override controlDirective: NgControl) {
    super(controlDirective);
  }

  override writeValue(value: string | number): void {
    if (this.maxlength && value && value.toString().length > this.maxlength) {
      value = value.toString().substring(0, this.maxlength);
    }
    if (this.type === InputTypeEnum.Number) {
      const regex = this.inputmode === InputModeEnum.Decimal ? /[^\d+.]/ : /[^\d+]/;
      value = value?.toString().replace(',', '.').replace(regex, '');
      value = value || value === '0' ? Number(value) : '';
    }

    super.writeValue(value);
  }

  onKeyDown(value: string | number, event: KeyboardEvent): boolean {
    if (this.type !== InputTypeEnum.Number) {
      return true;
    }
    const key = event.key;
    const allowedKeys = [
      '1', '2', '3', '4', '5', '6', '7', '8', '9', '0',
      'Backspace', 'Enter', 'Tab', 'Home', 'End',
      'ArrowLeft', 'ArrowRight', 'ArrowDown', 'ArrowUp',
      'Delete', 'Insert'
    ];
    if (
      allowedKeys.includes(key) ||
      (
        this.inputmode === InputModeEnum.Decimal &&
        (key === ',' || key === '.') &&
        !value.toString().includes('.') &&
        !value.toString().includes(',')
      )
    ) {
      return true;
    }
    event.preventDefault();
    return false;
  }

  togglePassword(): void {
    this._type = this.passwordVisible
      ? InputTypeEnum.Password
      : InputTypeEnum.Text;
    this.passwordVisible = !this.passwordVisible;
  }
}
