import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ModalInputs } from '@app/modules/shared/modal/models/modal-inputs.model';
import { Modal } from '@app/modules/shared/modal/models/modal.model';
import { PaymentInfoComponent } from '../../../payment-info/payment-info.component';
import { NgIf } from "@angular/common"

@Component({
    selector: 'app-payment-info-modal',
    templateUrl: './payment-info-modal.component.html',
    styleUrls: ['./payment-info-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
  imports: [PaymentInfoComponent, NgIf],
})
export class PaymentInfoModalComponent extends Modal.Model<ModalInputs.PaymentInfo, void> {
  inputs: ModalInputs.PaymentInfo;

  constructor() {
    super();
  }

  override onInjectInputs(inputs: ModalInputs.PaymentInfo) {
    this.inputs = inputs;
  }

}
