import { Inject, Injectable, InjectionToken } from '@angular/core';
import {
  collection,
  CollectionReference,
  deleteDoc,
  doc,
  docData,
  Firestore,
  getDoc,
  getDocs,
  setDoc,
  updateDoc,
  UpdateData, collectionData, Query, writeBatch,
} from '@angular/fire/firestore';
import { DomainModel } from '@app/domain/models/domain.model';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export abstract class FirestoreService<Model extends DomainModel> {
  protected readonly collectionRef: CollectionReference<Model>;

  protected constructor(
    @Inject(InjectionToken) private collectionId: string,
    protected firestore: Firestore,
  ) {
    this.collectionRef = collection(firestore, collectionId) as CollectionReference<Model>;
  }

  get autoId(): string {
    return doc(this.collectionRef).id;
  }

  ref(id: string) {
    return doc(this.collectionRef, id);
  }

  get(id: string) {
    return getDoc(this.ref(id));
  }

  async exists(id: string){
    const snapshot = id ? await this.get(id) : null;
    return snapshot?.exists() || false;
  }

  async fetch(id: string) {
    const snapshot = id ? await this.get(id) : null;
    return snapshot?.data() || null;
  }

  observe(id: string) {
    return id ? docData(this.ref(id)) : of(undefined);
  }

  async fetchAll(query?: Query<Model>) {
    const snapshots = await getDocs(query || this.collectionRef);
    return snapshots.docs.map(snapshot => snapshot.data());
  }

  observeAll(query?: Query<Model>){
    return collectionData(query || this.collectionRef);
  }

  async create(model: Model) {
    if (!model) {
      return;
    }
    return setDoc(this.ref(model.id), model);
  }

  async update(id: string, model: UpdateData<Model | null>) {
    if (!model) {
      return;
    }
    return updateDoc(this.ref(id), model);
  }

  async updateAll(model: UpdateData<Model | null>) {
    if (!model) {
      return;
    }
    const records = await this.fetchAll();
    const batch = writeBatch(this.firestore);
    for (let record of records) {
      batch.update(this.ref(record.id), model);
    }
    return batch.commit();
  }

  delete(id: string) {
    return deleteDoc(this.ref(id));
  }
}
