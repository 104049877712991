<label
  *ngIf="label || icon"
  [for]="id"
  class="checkbox__label"
  [class.required]="isRequired"
>
  <fa-icon *ngIf="icon" class="icon-prefix" [icon]="icon"></fa-icon>
  {{ label }}
</label>
<div class="checkbox__container" [class.disabled]="disabled">
  <input
    [id]="id"
    [attr.name]="id"
    type="checkbox"
    [value]="value"
    autocomplete="off"
    class="checkbox__element"
    [disabled]="disabled"
    [readOnly]="readonly"
    [checked]="value"
    (change)="changeValue()"
  />
  <label
    [for]="id"
    class="checkbox__button"
    [class.checkbox__button--checked]="value"
  >
    {{ options[0] }}
  </label>
  <label
    [for]="id"
    class="checkbox__button"
    [class.checkbox__button--checked]="!value"
  >
    {{ options[1] }}
  </label>
</div>
