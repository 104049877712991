import { SafeHtml } from '@angular/platform-browser';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

export namespace Notification {
  export class Model {
    id: string;
    type: Type;
    message: SafeHtml | string;
    autoClose: boolean;
    keepAfterRouteChange?: boolean;
    icon?: IconProp;
    imageUrl?: string;
    staticUrl?: boolean;
    fontSize: Size;

    constructor(init?: Partial<Notification.Model>) {
      Object.assign(this, init);
    }
  }

  export enum Type {
    Error = 'error',
    Warning = 'warning',
    Success = 'success',
    Info = 'info'
  }

  export enum Size {
    Large = 'lg',
    Medium = 'md',
    Small = 'sm'
  }
}
