import { ConnectionPositionPair, Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { CdkPortal, TemplatePortalDirective } from '@angular/cdk/portal';
import { ChangeDetectionStrategy, Component, Input, OnDestroy, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ZoneUtilsService } from '@app/services/zone-utils.service';
import { DropdownConfig } from '@app/modules/shared/dropdown/models/dropdown.config';

@Component({
    selector: 'app-dropdown',
    templateUrl: './dropdown.component.html',
    styleUrls: ['./dropdown.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [TemplatePortalDirective],
})
export class DropdownComponent implements OnDestroy {
    @Input() set config(config: DropdownConfig) {
        this._config = { ...this._config, ...config };
    }
    get config(): DropdownConfig {
        return this._config;
    }

    @Input() positions: ConnectionPositionPair[] = [
        {
            originX: 'start',
            originY: 'bottom',
            overlayX: 'start',
            overlayY: 'top',
        },
        {
            originX: 'start',
            originY: 'top',
            overlayX: 'start',
            overlayY: 'bottom',
        },
    ];
    @ViewChild(CdkPortal, { static: false }) cdkPortal: CdkPortal;

    private _config: DropdownConfig = {
        stickTo: undefined,
        adjustWidth: true,
    };

    private visible = false;

    private get overlayRef(): OverlayRef {
        if (!this._overlayRef) {
            this.initOverlayRef();
        }

        return this._overlayRef;
    }

    private _overlayRef: OverlayRef;
    private readonly unsubscribe$ = new Subject<void>();

    constructor(private overlay: Overlay, private zoneUtilsService: ZoneUtilsService) {}

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.overlayRef?.detach();
    }

    show(): void {
        if (this.visible) {
            return;
        }

        this.visible = true;
        this.overlayRef?.attach(this.cdkPortal);
        this.updateOverlayWidth();
    }

    hide(): void {
        this.visible = false;
        this.overlayRef?.detach();
    }

    updatePosition(): void {
        this.zoneUtilsService
            .scheduleDelayedAction()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(() => {
                if (this.overlayRef?.hasAttached()) {
                    this.overlayRef?.updatePosition();
                }
            });
    }

    private initOverlayRef(): void {
      if (this.config?.stickTo) {
        const positionStrategy = this.overlay
            .position()
            .flexibleConnectedTo(this.config.stickTo)
            .withFlexibleDimensions(false)
            .withPush(false)
            .withPositions(this.positions);
        const config = new OverlayConfig({
            scrollStrategy: this.overlay.scrollStrategies.reposition(),
            positionStrategy,
        });

        this._overlayRef = this.overlay.create(config);
      }
    }

    private updateOverlayWidth(): void {
        if (!this.config.adjustWidth) {
            return;
        }

        this.overlayRef?.updateSize({ width: this.config.stickTo?.getBoundingClientRect().width || 0 });
    }
}
