<label
  *ngIf="label || icon"
  [for]="id"
  class="select__label"
  [class.required]="isRequired"
>
  <fa-icon *ngIf="icon" class="icon-prefix" [icon]="icon"></fa-icon>
  {{ label }}
</label>
<div #selectContainer class="select__container">
  <input
    #filterInput
    class="select__element"
    type="text"
    autocomplete="off"
    [id]="id + '-filter'"
    [attr.name]="id + '-filter'"
    [placeholder]="placeholderText"
    [disabled]="disabled || readonly"
    [readOnly]="!config.filterable || readonly"
    (keyup)="onFilterKeyUp($event)"
    (keydown)="onFilterKeyDown($event)"
    (focus)="onFilterFocus()"
    (blur)="onFilterBlur()"
    (click)="turnOnInputMode()"
  />
  <select
    tabindex="-1"
    class="select__element"
    autocomplete="off"
    multiple
    title="Selected options"
    [disabled]="disabled || readonly"
    [class.select__element--empty]="!selectedOptions?.length"
    [class.select__element--filled]="value?.length"
    [value]="value"
    [id]="id"
    [attr.name]="id"
  >
    <option
      *ngFor="let selectedOption of value; trackBy: trackByFn"
      [value]="selectedOption['id'] || selectedOption"
      selected
    >{{
      selectedOption['name'] || value
      }}</option>
  </select>
  <div class="selected__elements" [class.disabled]="disabled">
    <div *ngIf="!selectedOptions?.length" class="selected__elements__item--empty">{{placeholderText}}</div>
    <div
      *ngFor="let selectedOption of selectedOptions; let index = index; trackBy: trackByFn"
      class="selected__elements__item"
      [title]="selectedOption['name'] || selectedOption"
    >
      <fa-icon
        *ngIf="selectedOption['icon']"
        [icon]="selectedOption['icon']"
        class="select__list-option__icon"
        size="sm"
      ></fa-icon>
      <div *ngIf="selectedOption.hasOwnProperty('photoUrl')" class="select__list-option__image">
        <img
          alt=""
          [default]="'assets/images/flags/square/unknown.svg'"
          loading="lazy"
          [src]="selectedOption['photoUrl'] | photoUrl: 64: staticUrl"
        />
      </div>

      <span class="select__list-option__text" [class.select__placeholder]="!value">{{
        selectedOption['name'] || selectedOption
      }}</span>
      <button *ngIf="!disabled && !readonly" type="button" class="btn--link btn--sm select__clear">
        <fa-icon
          [icon]="ActionIcons.Clear"
          (click)="clearSelect(selectedOption)"
        ></fa-icon>
      </button>
    </div>
  </div>
  <fa-icon
    class="select__icon"
    icon="chevron-down"
    [class.select__icon--open]="isListVisible"
  ></fa-icon>
</div>

<app-dropdown [config]="{ stickTo: selectContainer }">
  <div
    *ngIf="filteredOptions && inputMode"
    class="select__list"
    [excludedElements]="[selectContainer]"
    (clickElsewhere)="hideList()"
  >
    <cdk-virtual-scroll-viewport
      #cdkVirtualScrollViewport
      [itemSize]="config.optionHeight"
      class="select__viewport"
      [style.height.px]="calculatedListHeight"
    >
      <div
        *cdkVirtualFor="
          let option of filteredOptions;
          let index = index;
          trackBy: trackByFn
        "
        class="select__list-option"
        [class.select__list-option--active]="activeOptionIndex === index"
        [class.select__list-option--selected]="value | optionSelected: option"
        [title]="option?.['name'] || option"
        [style.height.px]="config.optionHeight"
        (click)="selectOption(option, $event)"
      >
        <fa-icon
          *ngIf="option?.['icon']"
          [icon]="option['icon']"
          class="select__list-option__icon"
          size="sm"
        ></fa-icon>
        <div *ngIf="option?.hasOwnProperty('photoUrl')" class="select__list-option__image">
          <img
            alt=""
            [default]="'assets/images/flags/square/unknown.svg'"
            loading="lazy"
            [src]="option['photoUrl'] | photoUrl: 64: staticUrl"
          />
        </div>
        <span class="select__list-option__text">{{ option?.['name'] || option }}</span>
      </div>
    </cdk-virtual-scroll-viewport>
    <div
      *ngIf="!filteredOptions?.length"
      class="select__list-option select__list-option--empty"
      [style.height.px]="config.optionHeight"
    >
      No options are available
    </div>
  </div>
</app-dropdown>
