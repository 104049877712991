import { Pipe, PipeTransform } from '@angular/core';

import { Options } from '@app/domain/options/models/options.model';
import { Venue } from '@app/domain/venues/models/venue.model';

@Pipe({
    name: 'pitchFlooring',
    standalone: true
})
export class PitchFlooringPipe implements PipeTransform {
  transform(flooring: Venue.PitchFlooring[]): string {
    const options = Options.statusOptions(Venue.PitchFlooring);
    return flooring?.length
      ? flooring.map(type => options.find(option => option.id === type)?.name || '?').join(', ')
      : 'N/A';
  }
}
