import { Component, HostBinding, Input } from '@angular/core';
import { NgControl } from '@angular/forms';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { BaseControlValueAccessorComponent } from '@app/modules/shared/form/components/base-control-value-accessor/base-control-value-accessor.component';
import { Radio } from '@app/modules/shared/form/components/radio/models/radio.model';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { NgIf, NgFor, NgClass } from '@angular/common';

@Component({
    selector: 'app-radio',
    templateUrl: './radio.component.html',
    styleUrls: ['./radio.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        FaIconComponent,
        NgFor,
        NgClass,
    ],
})
export class RadioComponent<
  ValueType
> extends BaseControlValueAccessorComponent<ValueType> {
  @Input() label: string;
  @Input() icon: IconProp;
  @Input() options: ValueType[] = [];
  @Input() size: Radio.Size = Radio.Size.Normal;
  @Input() @HostBinding('class.vertical') vertical = false;

  constructor(override controlDirective: NgControl) {
    super(controlDirective);
  }

  trackByFn(index: number): number {
    return index;
  }

  selectOption(option: ValueType): void {
    this.value = option;
  }
}
