<div *ngIf="fixture.organiser | userData | async as organiser; else loader">
  <div class="organiser">
    <fa-icon [icon]="RoleIcons.organiser" class="icon-prefix"></fa-icon>
    <app-avatar id="organiser" class="organiser__avatar" [user]="organiser" [imageSize]="64"></app-avatar>
    <span class="organiser__name">{{ organiser.displayName }}</span>
  </div>

  <div class="price__info">
    <div class="price" [class.calculated]="fixture.pricePerPlayer">
      <fa-icon [icon]="VenueIcons.price"></fa-icon>
      <span id="price">{{ fixture | pricePerPlayer }}</span>
    </div>
    <span *ngIf="!fixture.pricePerPlayer" class="not-finalised"><fa-icon [icon]="ActionIcons.Unpaid" class="icon-suffix"></fa-icon> not finalised</span>
  </div>

  <div class="payment__info">
    <h4 class="payment__info__title">Payment methods</h4>
    <div *ngIf="organiser.payments.blik" class="payment__method">
      <label for="blik-btn"><fa-icon [icon]="PaymentIcons.blik"></fa-icon> BLIK</label>
      <button
        type="button"
        id="blik-btn"
        class="btn--sm btn--link btn--inverted"
        [copyToClipboard]="organiser.payments.blik.substring(3)"
        [copyMessage]="'Phone number copied to clipboard:<br/><strong>' + organiser.payments.blik + '</strong>'"
      >
        {{ organiser.payments.blik }}
        <fa-icon class="icon-suffix" [icon]="ActionIcons.Copy"></fa-icon>
      </button>
    </div>

    <div *ngIf="organiser.payments.paypal" class="payment__method">
      <label for="paypal-btn"><fa-icon [icon]="PaymentIcons.paypal"></fa-icon> PayPal</label>
      <a
        id="paypal-btn"
        [href]="'https://paypal.me/' + organiser.payments.paypal"
        target="_blank"
        rel="noopener nofollow"
      >
        paypal.me/{{ organiser.payments.paypal }}
        <fa-icon class="icon-suffix" [icon]="ActionIcons.Link"></fa-icon>
      </a>
    </div>

    <div *ngIf="organiser.payments.transfer" class="payment__method">
      <label for="transfer-btn"><fa-icon [icon]="PaymentIcons.transfer"></fa-icon> Transfer</label>
      <button
        type="button"
        id="transfer-btn"
        class="btn--sm btn--link btn--inverted"
        [copyToClipboard]="organiser.payments.transfer"
      >
        {{ organiser.payments.transfer }}
        <fa-icon class="icon-suffix" [icon]="ActionIcons.Copy"></fa-icon>
      </button>
    </div>

    <div *ngIf="organiser.payments.revolut" class="payment__method">
      <label for="revolut-btn"><fa-icon [icon]="PaymentIcons.revolut"></fa-icon> Revolut</label>
      <a
        id="revolut-btn"
        [href]="'https://revolut.me/' + organiser.payments.revolut"
        target="_blank"
        rel="noopener nofollow"
      >
        revolut.me/{{ organiser.payments.revolut}}
        <fa-icon class="icon-suffix" [icon]="ActionIcons.Link"></fa-icon>
      </a>
    </div>

    <div class="payment__method">
      <label><fa-icon [icon]="PaymentIcons.cash"></fa-icon> Cash</label>
      <span class="cash">
        <ng-container *ngIf="organiser.payments.cash; else noCash">
          <fa-icon [icon]="ActionIcons.Ok"></fa-icon>
          Accepts cash
        </ng-container>
        <ng-template #noCash>
          <fa-icon [icon]="ActionIcons.Ban"></fa-icon>
          Does not accept cash
        </ng-template>
      </span>
    </div>
  </div>
</div>

<ng-template #loader>
  <app-loader></app-loader>
</ng-template>
