import { ApplicationConfig, DEFAULT_CURRENCY_CODE, importProvidersFrom, isDevMode, LOCALE_ID } from "@angular/core"
import { BrowserModule, HAMMER_GESTURE_CONFIG, HammerModule } from "@angular/platform-browser"
import { OverlayModule } from "@angular/cdk/overlay"
import { NotificationModule } from "@app/modules/shared/notification/notification.module"
import { ModalModule } from "@app/modules/shared/modal/modal.module"
import { ServiceWorkerModule } from "@angular/service-worker"
import { environment } from "@env/environment"
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome"
import { appHammerConfig } from "@app/config/touch-events"
import { getApp, initializeApp, provideFirebaseApp } from "@angular/fire/app"
import { connectAuthEmulator, getAuth, provideAuth } from "@angular/fire/auth"
import { connectFunctionsEmulator, getFunctions, provideFunctions } from "@angular/fire/functions"
import { defaultRegion } from "../config/firebase.config"
import { connectFirestoreEmulator, getFirestore, provideFirestore } from "@angular/fire/firestore"
import { connectStorageEmulator, getStorage, provideStorage } from "@angular/fire/storage"
import { connectDatabaseEmulator, getDatabase, provideDatabase } from "@angular/fire/database"
import { getMessaging, provideMessaging } from "@angular/fire/messaging"
import { getPerformance, providePerformance } from "@angular/fire/performance"
import { getRemoteConfig, provideRemoteConfig } from "@angular/fire/remote-config"
import { provideAnimations } from "@angular/platform-browser/animations"
import { provideHttpClient, withInterceptorsFromDi } from "@angular/common/http"
import { provideRouter, withInMemoryScrolling, withRouterConfig } from "@angular/router"
import { routes } from "@app/app.routes"

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(
      routes,
      withRouterConfig({
        onSameUrlNavigation: 'reload',
      }),
      withInMemoryScrolling({
        scrollPositionRestoration: 'enabled',
        anchorScrolling: 'enabled',
      }),
    ),
    importProvidersFrom(
      BrowserModule,
      HammerModule,
      OverlayModule,
      NotificationModule.forRoot(),
      ModalModule.forRoot(),
      ServiceWorkerModule.register('/ngsw-worker.js', {
        enabled: environment.production
      }),
      FontAwesomeModule
    ),
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: appHammerConfig,
    },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'PLN' },
    { provide: LOCALE_ID, useValue: 'pl-PL' },
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => {
      const auth = getAuth(getApp());
      if (environment.useEmulators.auth) {
        connectAuthEmulator(auth, 'https://127.0.0.1:9099');
      }
      return auth;
    }),
    provideFunctions(() => {
      const functions = getFunctions(getApp(), defaultRegion);
      if (environment.useEmulators.functions) {
        connectFunctionsEmulator(functions, '127.0.0.1', 5001);
      }
      return functions;
    }),
    provideFirestore(() => {
      const firestore = getFirestore(getApp());
      if (environment.useEmulators.firestore) {
        connectFirestoreEmulator(firestore, '127.0.0.1', 8080);
      }
      return firestore;
    }),
    provideStorage(() => {
      const storage = getStorage(getApp());
      if (environment.useEmulators.storage) {
        connectStorageEmulator(storage, '127.0.0.1', 6001);
      }
      return storage;
    }),
    provideDatabase(() => {
      const db = getDatabase(getApp());
      if (environment.useEmulators.database) {
        connectDatabaseEmulator(db, '127.0.0.1', 9000);
      }
      return db;
    }),
    provideMessaging(() => getMessaging(getApp())),
    providePerformance(() => getPerformance(getApp())),
    provideRemoteConfig(() => getRemoteConfig(getApp())),
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi())
  ]
};
