import { DOCUMENT } from '@angular/common';
import { Directive, ElementRef, EventEmitter, Inject, Input, OnDestroy, Output } from '@angular/core';

@Directive({
    selector: '[clickElsewhere]',
    standalone: true,
})
export class ClickElsewhereDirective implements OnDestroy {
    @Output() clickElsewhere = new EventEmitter<MouseEvent>();
    @Input() excludedClassNames: string[] = [];
    @Input() excludedElements: HTMLElement[] = [];

    constructor(private elementRef: ElementRef, @Inject(DOCUMENT) private document: Document) {
        this.document.addEventListener('click', this.documentListener, true);
    }

    // Workaround for issue https://github.com/angular/angular/issues/11200
    documentListener = (event: MouseEvent) => {
        const hasEventDirectiveElementInPath = event
            .composedPath()
            .every(htmlElement => htmlElement !== this.elementRef.nativeElement);
        if (hasEventDirectiveElementInPath && !this.hasExcludedElement(event)) {
            this.clickElsewhere.emit(event);
        }
    };

    ngOnDestroy(): void {
        this.document.removeEventListener('click', this.documentListener, true);
    }

    private hasExcludedElement(event: MouseEvent): boolean {
        return (
            (this.excludedClassNames &&
                this.excludedClassNames.some(cssClass => (event.target as HTMLElement).classList.contains(cssClass))) ||
            (this.excludedElements && this.excludedElements.some(element => event.composedPath().includes(element)))
        );
    }
}
