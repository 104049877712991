import { Pipe, PipeTransform } from '@angular/core';
import { Team } from '@app/domain/teams/models/team.model';

@Pipe({
    name: 'teamClass',
    standalone: true
})
export class TeamClassPipe implements PipeTransform {
  transform(value: number): Team.Class {
    if (!value) {
      return Team.Class.X;
    }
    const classSymbol = Object.keys(Team.Class).find(teamClass => value >= Team.ClassThreshold[teamClass]) || Team.Class.X;
    return Team.Class[classSymbol];
  }
}
