import { Component, Input } from '@angular/core';
import { NgControl } from '@angular/forms';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { BaseControlValueAccessorComponent } from '@app/modules/shared/form/components/base-control-value-accessor/base-control-value-accessor.component';
import { Icons } from '@app/config/icons';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-textarea',
    templateUrl: './textarea.component.html',
    styleUrls: ['./textarea.component.scss'],
    standalone: true,
    imports: [NgIf, FaIconComponent],
})
export class TextareaComponent extends BaseControlValueAccessorComponent<string | number> {
  @Input() label: string;
  @Input() icon: IconProp;
  @Input() placeholder = '';
  @Input() maxlength: number;

  readonly ActionIcons = Icons.Actions;

  constructor(override controlDirective: NgControl) {
    super(controlDirective);
  }

  override writeValue(value: string | number): void {
    if (this.maxlength && value && value.toString().length > this.maxlength) {
      value = value.toString().substring(0, this.maxlength);
    }

    super.writeValue(value);
  }
}
