import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'flag',
    standalone: true
})
export class FlagPipe implements PipeTransform {
  constructor() {}

  transform(country: string): string {
    return country ? `assets/images/flags/square/${country}.svg` :
      'assets/images/flags/square/unknown.svg';
  }
}
