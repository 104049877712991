import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

import { Options } from '@app/domain/options/models/options.model';
import { staticCountries } from '@static/countries';
import { PhotoUrlPipe } from '../pipes/photo-url/photo-url.pipe';
import { ImagePreloadDirective } from '../directives/image-preload.directive';

@Component({
    selector: 'app-flag',
    templateUrl: './flag.component.html',
    styleUrls: ['./flag.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [ImagePreloadDirective, PhotoUrlPipe]
})
export class FlagComponent {
  @Input() set countryCode(countryCode: string | null | undefined) {
    if (countryCode) {
      this._country = staticCountries.find(country => country.id === countryCode);
    }
  }

  get country(): Options.SelectModel | undefined {
    return this._country;
  }

  private _country: Options.SelectModel | undefined;
}
