import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FlagModule } from '@app/modules/shared/flag/flag.module';

import { PipesModule } from '@app/modules/shared/pipes/pipes.module';
import { AvatarComponent } from './avatar.component';


@NgModule({
    exports: [
        AvatarComponent
    ],
    imports: [
    CommonModule,
    PipesModule,
    RouterModule,
    FlagModule,
    AvatarComponent,
]
})
export class AvatarModule { }
