import { Component, EventEmitter, Input, Output } from '@angular/core';
import { getDownloadURL, getMetadata, ref, Storage, StorageReference, uploadBytesResumable } from '@angular/fire/storage';
import { Firestore } from '@angular/fire/firestore';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { Icons } from '@app/config/icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { BehaviorSubject } from 'rxjs';

import {
  BaseControlValueAccessorComponent
} from '@app/modules/shared/form/components/base-control-value-accessor/base-control-value-accessor.component';
import { defaultImageSize } from '@app/modules/shared/pipes/photo-url/photo-url.pipe';
import { DateFormatter } from '@app/utils/date-formatter';
import { FirebaseTools } from '@app/domain/utils/firebase-tools';
import { StorageBucketEnum } from '@app/domain/enums/storage-bucket.enum';
import { Notification } from '@app/modules/shared/notification/models/notification.model';
import { NotificationService } from '@app/modules/shared/notification/services/notification.service';
import { PhotoUrlPipe } from '../../../pipes/photo-url/photo-url.pipe';
import { LoaderComponent } from '../../../loader/loader.component';
import { ProgressBarComponent } from '../../../progress-bar/progress-bar.component';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { NgIf, AsyncPipe } from '@angular/common';

@Component({
    selector: 'app-image-upload',
    templateUrl: './image-upload.component.html',
    styleUrls: ['./image-upload.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        FaIconComponent,
        ProgressBarComponent,
        LoaderComponent,
        AsyncPipe,
        PhotoUrlPipe,
    ],
})
export class ImageUploadComponent extends BaseControlValueAccessorComponent<string> implements ControlValueAccessor {
  @Input() label: string;
  @Input() icon: IconProp;
  @Input() placeholder = 'Upload Image';
  @Input() storageBucket = StorageBucketEnum.Users;
  @Input() folder: string;
  @Input() customFileName: string;
  @Input() keepFileName: boolean;
  @Input() extensions: string = '.png,.jpg,.jpeg,.gif,.webp';
  @Output() imageUrl = new EventEmitter<string>();

  readonly uploading$ = new BehaviorSubject<boolean>(false);
  readonly processing$ = new BehaviorSubject<boolean>(false);
  readonly uploadProgress$ = new BehaviorSubject<number>(0);

  readonly ActionIcons = Icons.Actions;

  private uploadTime: number;
  private readonly maxAttempts = 20;

  constructor(
    override readonly controlDirective: NgControl,
    private readonly firestore: Firestore,
    private readonly firebaseTools: FirebaseTools,
    private readonly storage: Storage,
    private readonly notificationService: NotificationService,
  ) {
    super(controlDirective);
  }

  async upload(event: Event): Promise<void> {
    const file = (event.target as HTMLInputElement)?.files?.[0];
    if (!file) {
      console.error('No file is selected');
      return;
    }
    const extension = file.name
      .substring(file.name.lastIndexOf('.') + 1)
      .toLowerCase();
    const fileName = this.customFileName
      ? `${this.customFileName}.${extension}`
      : this.keepFileName
        ? file.name
        : `${this.firebaseTools.uniqueId()}.${extension}`;
    const uploadUrl = this.folder
      ? `/${this.storageBucket}/${this.folder}/${fileName}`
      : `${this.storageBucket}/${fileName}`;
    this.uploadTime = DateFormatter.nowMillis();
    this.uploading$.next(true);
    const uploadTaskSnapshot = await uploadBytesResumable(ref(this.storage, uploadUrl), file);
    uploadTaskSnapshot.task.on('state_changed', snapshot => {
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      this.uploadProgress$.next(progress);
    });

    await uploadTaskSnapshot.task
      .then(image => {
        const suffix = `_${defaultImageSize}x${defaultImageSize}`;
        const imageRef = image.ref.toString();
        const refUrl = `${imageRef.substring(
          0,
          imageRef.lastIndexOf('.')
        )}${suffix}.${extension}`;
        this.processImage(refUrl);
      })
      .catch(error => this.notificationService.error(error.message))
      .finally(() => this.uploading$.next(false));
  }

  private async processImage(refUrl: string, attempt = 0): Promise<void> {
    if (attempt > this.maxAttempts) {
      this.notificationService.error('Max attempts reached to fetch download URL. Please retry.');
      this.processing$.next(false);
      return;
    }
    this.processing$.next(true);
    setTimeout(() => this.attemptToFetchMetaData(refUrl), 1000);
  }

  private async attemptToFetchMetaData(refUrl: string, attempt = 0): Promise<void> {
    const imageRef = ref(this.storage, refUrl);
    await getMetadata(imageRef)
      .then(metadata => {
        if (
          metadata.customMetadata?.['resizedImage'] &&
          DateFormatter.nowMillis(metadata.updated) > this.uploadTime
        ) {
          return this.fetchNewImageUrl(imageRef);
        }
        throw new Error('Resized image not ready');
      })
      .catch(() => this.processImage(refUrl, attempt++));
  }

  private async fetchNewImageUrl(imageRef: StorageReference): Promise<void> {
    return getDownloadURL(imageRef)
      .then(downloadUrl => {
        this.value = downloadUrl;
        this.imageUrl.emit(downloadUrl);
        this.notificationService.success(`Image saved`, {
          imageUrl: this.value,
          fontSize: Notification.Size.Large
        });
      })
      .catch(error => this.notificationService.error(error.message))
      .finally(() => this.processing$.next(false));
  }
}
