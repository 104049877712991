import { Component, ChangeDetectionStrategy, Input, HostBinding } from '@angular/core';

import { Player } from '@app/domain/players/models/player.model';
import { PlayerPositionPipe } from '../../pipes/players/player-position.pipe';
import { NgClass } from '@angular/common';

@Component({
    selector: 'app-player-position-badge',
    templateUrl: './player-position-badge.component.html',
    styleUrls: ['./player-position-badge.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgClass, PlayerPositionPipe]
})
export class PlayerPositionBadgeComponent {
  @Input() position: Player.Position | null | undefined;
  @Input() @HostBinding('class.small') small: boolean;
}
