import { Component, ChangeDetectionStrategy } from '@angular/core';

import { Icons } from '@app/config/icons';
import { ModalInputs } from '@app/modules/shared/modal/models/modal-inputs.model';
import { Modal } from '@app/modules/shared/modal/models/modal.model';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-confirmation-modal',
    templateUrl: './confirmation-modal.component.html',
    styleUrls: ['./confirmation-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, FaIconComponent]
})
export class ConfirmationModalComponent extends Modal.Model<ModalInputs.Confirmation, boolean> {
  inputs: ModalInputs.Confirmation;

  readonly ActionIcons = Icons.Actions;

  onInjectInputs(inputs: ModalInputs.Confirmation): void {
    this.inputs = inputs;
  }
}
