import { Injectable } from '@angular/core';
import {
  collection,
  collectionData,
  CollectionReference,
  deleteDoc,
  doc,
  docData,
  DocumentReference,
  Firestore,
  getDoc,
  getDocs,
  setDoc,
  UpdateData,
  updateDoc
} from '@angular/fire/firestore';

import { FirestoreService } from '@app/domain/firestore/firestore.service';
import { VenuesQueries, VenuesQueryParams } from '@app/domain/venues/services/venues.queries';
import { Venue } from '@app/domain/venues/models/venue.model';
import Model = Venue.Model;

@Injectable({
  providedIn: 'root'
})
export class VenuesService extends FirestoreService<Model>{
  static readonly collectionId: string = 'venues';
  static readonly subCollectionId: string = 'pitches';
  private readonly queries = new VenuesQueries(this.collectionRef);

  constructor(override firestore: Firestore) {
    super(VenuesService.collectionId, firestore);
  }

  pitchesRef(venue: string) {
    return collection(
      this.firestore,
      `${VenuesService.collectionId}/${venue}/${VenuesService.subCollectionId}`
    ) as CollectionReference<Venue.Pitch>;
  }

  pitchRef(venue: string, pitch: string) {
    return doc(
      this.firestore,
      `${VenuesService.collectionId}/${venue}/${VenuesService.subCollectionId}/${pitch}`
    ) as DocumentReference<Venue.Pitch>;
  }

  async fetchForCapacity(params?: VenuesQueryParams.Capacity) {
    const snapshots = await getDocs(this.queries.capacity(params));
    return snapshots.docs.map(snapshot => snapshot.data());
  }

  observeForCapacity(params?: VenuesQueryParams.Capacity) {
    return collectionData(this.queries.capacity(params));
  }

  async fetchPitch(venue: string, id: string) {
    const snapshot = await getDoc(this.pitchRef(venue, id));
    return snapshot.data() || undefined;
  }

  observePitch(venue: string, id: string)  {
    return docData(this.pitchRef(venue, id));
  }

  async fetchPitches(venue: string) {
    const snapshots = await getDocs(this.queries.pitches(this.pitchesRef(venue)));
    return snapshots.docs.map(snapshot => snapshot.data());
  }

  observePitches(venue: string) {
    return collectionData(this.queries.pitches(this.pitchesRef(venue)));
  }

  async createPitch(model: Venue.Pitch) {
    if (!model) {
      return;
    }
    return setDoc(this.pitchRef(model.venue, model.id), model);
  }

  async updatePitch(model: UpdateData<Venue.Pitch>) {
    const venue = model?.venue as string;
    const id = model?.id as string;
    if (venue && id) {
      return updateDoc(this.pitchRef(venue, id), model);
    }
    return;
  }

  async deletePitch(model: Venue.Pitch) {
    if (!model) {
      return;
    }
    return deleteDoc(this.pitchRef(model.venue, model.id));
  }
}
