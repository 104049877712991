import { CollectionReference, orderBy, query, Query, where, QueryConstraint } from '@angular/fire/firestore';

import { Queries } from '@app/domain/models/queries.model';

export namespace GroupsQueryParams {
  export interface Params extends Queries.FirebaseParams {
    private?: boolean;
    ids?: string[];
  }
}

export class GroupsQueries<DocumentData> {
  constructor(private collectionRef: CollectionReference<DocumentData>) {}

  sortByDate(): Query<DocumentData> {
    return query(
      this.collectionRef,
      orderBy('createdAt', 'desc')
    );
  }

  byParams(params: GroupsQueryParams.Params) {
    let queries: QueryConstraint[] = [];
    if (!params) {
      queries = [];
    } else if (params.private) {
      queries = [
        where('private', '==', params.private),
      ];
    } else if (params.ids?.length) {
      queries = [
        where('id', 'in', params.ids)
      ]
    }

    return query(
      this.collectionRef,
      ...queries,
      orderBy('createdAt', params?.orderByDirection || 'desc')
    );
  }
}
