import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  QueryList,
  ViewChildren
} from '@angular/core';
import { NgControl, FormsModule } from '@angular/forms';

import { Icons } from '@app/config/icons';
import {
  BaseControlValueAccessorComponent
} from '@app/modules/shared/form/components/base-control-value-accessor/base-control-value-accessor.component';
import { trackByIndex } from '@app/utils/track-by';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { InputTypeEnum } from "@app/modules/shared/form/components/input/enums/input-type.enum"
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'app-code',
    templateUrl: './code.component.html',
    styleUrls: ['./code.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        FaIconComponent,
        NgFor,
        FormsModule,
    ],
})
export class CodeComponent extends BaseControlValueAccessorComponent<string> implements AfterViewInit {
  @ViewChildren('fieldInput') inputs: QueryList<ElementRef>;
  @Input() label: string;
  @Input() icon: IconProp;
  @Input() set type(value: InputTypeEnum) {
    this._type = value;
  }
  get type(): InputTypeEnum {
    return this._type;
  }
  @Input() placeholder = '';
  @Input() prefix = '';
  @Input() suffix = '';
  @Input() pattern: string | RegExp = /\d/;
  @Input() set length(length: number) {
    this._length = length || 6;
    this.valueArray = Array.from({length: this._length}, () => '');
  }
  @Input() showPasswordToggle: boolean;
  @Output() onFilled = new EventEmitter<string | null>();
  valueArray: Array<string> = [];

  readonly trackByIndex = trackByIndex;

  readonly InputTypeEnum = InputTypeEnum;
  readonly ActionIcons = Icons.Actions;

  private _type: InputTypeEnum = InputTypeEnum.Text;
  private _length: number;

  constructor(override controlDirective: NgControl) {
    super(controlDirective);
  }

  ngAfterViewInit(): void {
    this.inputs?.get(0)?.nativeElement.focus();
  }

  override writeValue(value: string): void {
    if (this.length && value && value.toString().length > this.length) {
      value = value.toString().substring(0, this.length);
    }

    super.writeValue(value);
  }

  onKeyUp(event: KeyboardEvent, index: number): void {
    if (event.ctrlKey) {
      event.preventDefault();
      event.stopPropagation();
      if (event.key !== 'v') {
        this.valueArray[index] = '';
      } else {
        this.inputs.get(index)?.nativeElement.blur();
      }
      return;
    }

    this.fill();
    if (this.valueArray[index] === '') {
      this.inputs.get(index - 1)?.nativeElement.focus();
    } else if (this.valueArray[index] && index === this.valueArray.length - 1) {
      this.onFilled.emit(this.value);
    } else {
      this.inputs.get(index + 1)?.nativeElement.focus();
    }
  }

  onKeyDown(value: string | number, event: KeyboardEvent, index: number): boolean {
    const key = event.key;
    const allowedKeys = [
      '1', '2', '3', '4', '5', '6', '7', '8', '9', '0',
      'Backspace', 'Enter', 'Tab', 'Home', 'End',
      'ArrowLeft', 'ArrowRight', 'ArrowDown', 'ArrowUp',
      'Delete', 'Insert'
    ];
    if (allowedKeys.includes(key)) {
      return true;
    }
    event.preventDefault();
    return false;
  }

  fill(): void {
    this.value = this.valueArray.join('');
  }

  paste(event: ClipboardEvent): void {
    const data = event.clipboardData?.getData('text/plain');
    if (!data) {
      return;
    }
    const valueArray = data.substring(0, this._length).split('');
    if (valueArray.some(char => Number.isNaN(Number(char)))) {
      return;
    }
    event.preventDefault();
    event.stopPropagation();
    this.valueArray = valueArray;
    this.fill();
    this.onFilled.emit(this.value);
  }
}
