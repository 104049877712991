<label
  *ngIf="label && type !== InputTypeEnum.Hidden"
  [for]="id"
  class="input__label"
  [class.required]="isRequired"
>{{ label }}</label>
<div class="input__container" [class]="size" [class.disabled]="disabled">
  <fa-icon *ngIf="icon" [icon]="icon" [fixedWidth]="true" class="input__icon"></fa-icon>
  <label *ngIf="prefix" [for]="id" class="input__prefix">{{ prefix }}</label>
  <input
    #fieldInput
    [id]="id"
    [attr.name]="id"
    [type]="type === InputTypeEnum.Number ? InputTypeEnum.Tel : type"
    [attr.inputmode]="!inputmode && type === InputTypeEnum.Number ? 'numeric' : inputmode"
    [value]="value"
    autocomplete="off"
    [placeholder]="placeholder"
    class="input__element"
    [class.filled]="value || value === 0"
    [class.has-suffix]="suffix || showPasswordToggle"
    [disabled]="disabled"
    [pattern]="pattern || !inputmode && type === InputTypeEnum.Number ? '[0-9]*' : null"
    [readOnly]="readonly || null"
    [attr.maxLength]="maxlength"
    [attr.max]="max"
    (keydown)="onKeyDown(fieldInput.value, $event)"
    (input)="writeValue(fieldInput.value)"
  />
  <label *ngIf="suffix" [for]="id" class="input__suffix">{{ suffix }}</label>
  <button
    *ngIf="showPasswordToggle"
    type="button"
    class="password-toggle"
    [title]="passwordVisible ? 'Show password' : 'Hide password'"
    (click)="togglePassword()"
  >
    <fa-icon *ngIf="!passwordVisible" [icon]="ActionIcons.Show"></fa-icon>
    <fa-icon *ngIf="passwordVisible" [icon]="ActionIcons.Hide"></fa-icon>
  </button>
</div>

<!--<p-->
<!--  *ngIf="controlDirective.control?.dirty &&-->
<!--  controlDirective.control?.touched &&-->
<!--  controlDirective.control?.errors"-->
<!--  class="input__error">-->
<!--  <ng-container *ngFor="let error of controlDirective.control.errors | keyvalue">-->
<!--    {{ CustomValidators.errorMessages[error.key] || "Invalid input" }}-->
<!--  </ng-container>-->
<!--</p>-->
