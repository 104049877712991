import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { Icons } from '@app/config/icons';
import { UserDataPipe } from '../pipes/reference/user-reference.pipe';
import { LoaderComponent } from '../loader/loader.component';
import { CopyToClipboardDirective } from '../directives/copy-to-clipboard.directive';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { AvatarComponent } from '../avatar/avatar.component';
import { NgIf, AsyncPipe } from '@angular/common';
import { Fixture } from "@app/domain/fixtures/models/fixture.model"
import { PricePerPlayerPipe } from "@app/modules/shared/pipes/price/price.pipe"

@Component({
    selector: 'app-payment-info',
    templateUrl: './payment-info.component.html',
    styleUrls: ['./payment-info.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
  imports: [NgIf, AvatarComponent, FaIconComponent, CopyToClipboardDirective, LoaderComponent, AsyncPipe, UserDataPipe, PricePerPlayerPipe],
})
export class PaymentInfoComponent implements OnInit {
  @Input() fixture: Fixture.Model;

  readonly ActionIcons = Icons.Actions;
  readonly PaymentIcons = Icons.Payments;
  readonly RoleIcons = Icons.Roles;
  readonly VenueIcons = Icons.Venues;

  constructor() { }

  ngOnInit(): void {
  }

}
