import { Component, ChangeDetectionStrategy, Input, HostBinding } from '@angular/core';

import { Player } from '@app/domain/players/models/player.model';
import { PlayerScorePipe } from '../../pipes/players/player-score.pipe';
import { PlayerClassPipe } from '../../pipes/players/player-class.pipe';
import { NgIf, NgClass } from '@angular/common';

@Component({
    selector: 'app-player-class-badge',
    templateUrl: './player-class-badge.component.html',
    styleUrls: ['./player-class-badge.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, NgClass, PlayerClassPipe, PlayerScorePipe]
})
export class PlayerClassBadgeComponent {
  @Input() abilities: Player.Abilities | null | undefined;
  @Input() overall: number;
  @Input() position: Player.Position | null;
  @Input() @HostBinding('class.small') small: boolean;
  @Input() @HostBinding('class.inverted') inverted: boolean;

  readonly Position = Player.Position;
}
