import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';

import { Fixture } from '@app/domain/fixtures/models/fixture.model';
import { FixturesService } from '@app/domain/fixtures/services/fixtures.service';
import Model = Fixture.Model;

@Pipe({
    name: 'fixtureData',
    standalone: true
})
export class FixtureDataPipe implements PipeTransform {
  constructor(private readonly fixturesService: FixturesService) {}

  async transform(id: string) {
    return this.fixturesService.fetch(id);
  }
}

@Pipe({
    name: 'fixtureChanges',
    standalone: true
})
export class FixtureChangesPipe implements PipeTransform {
  constructor(private readonly fixturesService: FixturesService) {}

  transform(id: string) {
    return this.fixturesService.observe(id);
  }
}
