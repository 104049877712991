import { Pipe, PipeTransform } from '@angular/core';
import { Statistics } from '@app/config/statistics';
import { Player } from '@app/domain/players/models/player.model';

@Pipe({
    name: 'teamAbilities',
    standalone: true
})
export class TeamAbilitiesPipe implements PipeTransform {

  transform(players: Player.Model[]): Player.Abilities {
    const unratedAbilityDefault = Statistics.maxPlayerAbility / 2;
    const abilities: Player.Abilities = {
      overall: 0,
      defense: 0,
      dribbling: 0,
      passing: 0,
      speed: 0,
      shooting: 0,
      strength: 0,
      goalKeeping: 0,
    };
    let reserves = 0;
    let midfielders = 0;
    let attackers = 0;
    let defenders = 0;
    let goalies = 0;
    let reserveGoalies = 0;
    const hasOffense = (players || []).some(player => player.position === Player.Position.Offense);
    const hasMidfield = (players || []).some(player => player.position === Player.Position.Midfield);
    const hasDefense = (players || []).some(player => player.position === Player.Position.Defense);
    const hasGoalKeeper = (players || []).some(player => player.position === Player.Position.GoalKeeper);
    (players || []).forEach(player => {
      const unratedPlayer = !player.abilities?.overall && !player.overall;
      if (!player.abilities) {
        return;
      }
      if (player.position === Player.Position.GoalKeeper) {
        if (!hasMidfield && !hasDefense) {
          abilities.speed += unratedPlayer ? unratedAbilityDefault : player.abilities.speed;
          abilities.passing += unratedPlayer ? unratedAbilityDefault : player.abilities.passing;
          abilities.dribbling += unratedPlayer ? unratedAbilityDefault : player.abilities.dribbling;
          abilities.strength += unratedPlayer ? unratedAbilityDefault : player.abilities.strength;
          midfielders++;
          defenders++;
        } else if (!hasDefense) {
          abilities.strength += unratedPlayer ? unratedAbilityDefault : player.abilities.strength;
          abilities.passing += unratedPlayer ? unratedAbilityDefault : player.abilities.passing;
          defenders++;
        }
        abilities.goalKeeping += player.abilities.goalKeeping || 0;
        abilities.defense += unratedPlayer ? unratedAbilityDefault : (player.abilities.defense || 0);
        goalies++;
      } else if (player.position === Player.Position.Defense) {
        if (!hasOffense && !hasMidfield) {
          abilities.shooting += unratedPlayer ? unratedAbilityDefault : player.abilities.shooting;
          attackers++;
        }
        if (!hasMidfield) {
          abilities.speed += unratedPlayer ? unratedAbilityDefault : player.abilities.speed;
          abilities.dribbling += unratedPlayer ? unratedAbilityDefault : player.abilities.dribbling;
          midfielders++;
        }
        abilities.defense += unratedPlayer ? unratedAbilityDefault : player.abilities.defense;
        abilities.strength += unratedPlayer ? unratedAbilityDefault : player.abilities.strength;
        abilities.passing += unratedPlayer ? unratedAbilityDefault : player.abilities.passing;
        if (!hasGoalKeeper) {
          abilities.goalKeeping += player.abilities?.goalKeeping || 0;
          reserveGoalies++;
        }
        defenders++;
      } else if (player.position === Player.Position.Midfield) {
        if (!hasOffense) {
          abilities.shooting += unratedPlayer ? unratedAbilityDefault : player.abilities.shooting;
          attackers++;
        }
        abilities.speed += unratedPlayer ? unratedAbilityDefault : player.abilities.speed;
        abilities.passing += unratedPlayer ? unratedAbilityDefault : player.abilities.passing;
        abilities.dribbling += unratedPlayer ? unratedAbilityDefault : player.abilities.dribbling;
        abilities.strength += unratedPlayer ? unratedAbilityDefault : player.abilities.strength;
        if (!hasDefense) {
          abilities.defense += unratedPlayer ? unratedAbilityDefault : player.abilities.defense;
          defenders++;
        }
        if (!hasGoalKeeper) {
          abilities.goalKeeping += player.abilities.goalKeeping || 0;
          reserveGoalies++;
        }
        midfielders++;
      } else if (player.position === Player.Position.Offense) {
        abilities.shooting += unratedPlayer ? unratedAbilityDefault : player.abilities.shooting;
        abilities.dribbling += unratedPlayer ? unratedAbilityDefault : player.abilities.dribbling;
        abilities.speed += unratedPlayer ? unratedAbilityDefault : player.abilities.speed;
        if (!hasMidfield) {
          abilities.passing += unratedPlayer ? unratedAbilityDefault : player.abilities.passing;
          abilities.strength += unratedPlayer ? unratedAbilityDefault : player.abilities.strength;
          midfielders++;
        }
        if (!hasMidfield && !hasDefense) {
          abilities.defense += unratedPlayer ? unratedAbilityDefault : player.abilities.defense;
          defenders++;
        }
        if (!hasGoalKeeper) {
          abilities.goalKeeping += player.abilities.goalKeeping || 0;
          reserveGoalies++;
        }
        attackers++;
      } else {
        Object.keys(abilities).forEach(key => abilities[key] += unratedPlayer ? unratedAbilityDefault : (player.abilities[key] || 0));
        reserves++;
      }
    });
    abilities.shooting = abilities.shooting ? Math.round(abilities.shooting / ((attackers + reserves) || 1)) : 0;
    abilities.dribbling = abilities.dribbling ? Math.round(abilities.dribbling / ((attackers + midfielders + reserves) || 1)) : 0;
    abilities.speed = abilities.speed ? Math.round(abilities.speed / ((attackers + midfielders + reserves) || 1)) : 0;
    abilities.defense = abilities.defense ? Math.round(abilities.defense / ((defenders + goalies + reserves) || 1)) : 0;
    abilities.strength = abilities.strength ? Math.round(abilities.strength / ((defenders + midfielders + reserves) || 1)) : 0;
    abilities.passing = abilities.passing ? Math.round(abilities.passing / ((defenders + midfielders + reserves) || 1)) : 0;
    abilities.goalKeeping = abilities.goalKeeping ? Math.round(abilities.goalKeeping / ((goalies + reserveGoalies + reserves) || 1)) : 0;
    abilities.overall = Math.round((
      (abilities.shooting ? (abilities.shooting / Statistics.maxPlayerAbility * 100) : 0) +
      (abilities.dribbling ? (abilities.dribbling / Statistics.maxPlayerAbility * 100) : 0) +
      (abilities.speed ? (abilities.speed / Statistics.maxPlayerAbility * 100) : 0) +
      (abilities.defense ? (abilities.defense / Statistics.maxPlayerAbility * 100) : 0) +
      (abilities.strength ? (abilities.strength / Statistics.maxPlayerAbility * 100) : 0) +
      (abilities.passing ? (abilities.passing / Statistics.maxPlayerAbility * 100) : 0) +
      (abilities.goalKeeping && hasGoalKeeper ? (abilities.goalKeeping / Statistics.maxPlayerAbility * 100) : 0)
    ) / (hasGoalKeeper ? 7 : 6));

    return abilities;
  }

}
