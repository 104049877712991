<h2 class="header">New update available!</h2>

<div class="content">
  <fa-icon [icon]="ActionIcons.Refresh" size="3x"></fa-icon>
  <h4>
    In order for this application to work properly, please get the newest update.
  </h4>
</div>

<div class="actions">
  <button type="button" class="btn--secondary btn--lg" (click)="close(true)">
    <fa-icon class="icon-prefix" [icon]="ActionIcons.Submit"></fa-icon>
    Update now
  </button>
</div>
