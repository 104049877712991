export const firebaseConfig = {
  projectId: 'footsy-340115',
  appId: '1:3087952296:web:37f727820ba9004b75159d',
  databaseURL: 'https://footsy-340115-default-rtdb.europe-west1.firebasedatabase.app',
  storageBucket: 'footsy-340115.appspot.com',
  locationId: 'europe-central2',
  apiKey: 'AIzaSyBXVYmfMyWzFqR__789YES1hAzUVbvoHmU',
  authDomain: 'footsy-340115.firebaseapp.com',
  messagingSenderId: '3087952296',
  measurementId: 'G-BCRNFZ8F4Z',
};

export const defaultRegion = 'europe-central2'; // Warsaw
