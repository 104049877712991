import { Component, Input } from '@angular/core';
import { NgControl, FormsModule } from '@angular/forms';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { Icons } from '@app/config/icons';
import { BaseControlValueAccessorComponent } from '@app/modules/shared/form/components/base-control-value-accessor/base-control-value-accessor.component';
import { InputTypeEnum } from '@app/modules/shared/form/components/input/enums/input-type.enum';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-time-picker',
    templateUrl: './time-picker.component.html',
    styleUrls: ['./time-picker.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        FaIconComponent,
        FormsModule,
    ],
})
export class TimePickerComponent extends BaseControlValueAccessorComponent<string | null> {
  @Input() label: string;
  @Input() icon: IconProp;
  @Input() timePlaceholder = 'HH:mm';

  readonly InputTypeEnum = InputTypeEnum;
  readonly ActionIcons = Icons.Actions;

  constructor(override controlDirective: NgControl) {
    super(controlDirective);
  }
}
