import { HostBinding, Input, Directive, Self, Optional } from '@angular/core';
import { ControlValueAccessor, NgControl, Validators } from '@angular/forms';

@Directive()
export abstract class BaseControlValueAccessorComponent<T> implements ControlValueAccessor {
  @HostBinding('attr.id') externalId: string | null = '';

  @Input() set id(value: string) {
    this._id = value;
    this.externalId = null;
  }
  get id(): string {
    return this._id;
  }
  @Input() readonly = false;
  @Input('value') _value: T | null;
  disabled = false;

  private _id = '';

  get isRequired(): any {
    return this.controlDirective.control?.hasValidator(Validators.required);
  }

  onChange: any = () => {};
  onTouched: any = () => {};

  set value(val: T | null) {
    this._value = val;
    this.onChange(val);
    this.onTouched();
  }

  get value(): T | null {
    return this._value;
  }

  protected constructor(@Self() @Optional() protected controlDirective: NgControl) {
    this.controlDirective.valueAccessor = this;
  }

  registerOnChange(fn: () => {}): void {
    this.onChange = fn;
  }

  writeValue(value: T): void {
    this.value = typeof value === 'string' && value === '' ? null : value;
  }

  registerOnTouched(fn: () => {}): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
