import { Component, Input } from '@angular/core';
import { NgControl } from '@angular/forms';
import { DateFormatter } from '@app/utils/date-formatter';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { Icons } from '@app/config/icons';
import { BaseControlValueAccessorComponent } from '@app/modules/shared/form/components/base-control-value-accessor/base-control-value-accessor.component';
import { InputTypeEnum } from '@app/modules/shared/form/components/input/enums/input-type.enum';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-date-time-picker',
    templateUrl: './date-time-picker.component.html',
    styleUrls: ['./date-time-picker.component.scss'],
    standalone: true,
    imports: [NgIf, FaIconComponent],
})
export class DateTimePickerComponent extends BaseControlValueAccessorComponent<string | null> {
  @Input() label: string;
  @Input() icon: IconProp;
  @Input() datePlaceholder = 'YYYY-MM-DD';
  @Input() timePlaceholder = 'HH:mm';
  date: string | null;
  time: string | null;
  timezoneOffset: string = 'Z';

  readonly min = DateFormatter.nowDate();
  readonly InputTypeEnum = InputTypeEnum;
  readonly ActionIcons = Icons.Actions;

  constructor(override controlDirective: NgControl) {
    super(controlDirective);
  }

  override writeValue(value: string | null) {
    if (value) {
      const [date, time] = value.substring(0,16).split('T');
      this.timezoneOffset = value.substring(value.length - 6);
      this.time = time;
      this.date = date;
    } else {
      this.time = null;
      this.date = null;
    }
    super.writeValue(value);
  }

  writeDate(date: string | null): void {
    this.date = date;
    const time = this.time ? `${this.time}:00${this.timezoneOffset}` : `00:00:00${this.timezoneOffset}`;
    this.value = `${date}T${time}`;
  }

  writeTime(time: string | null): void {
    this.time = time;
    this.value = `${this.date}T${time}:00${this.timezoneOffset}`;
  }
}
