import { CollectionReference, orderBy, query, Query, where, QueryConstraint } from '@angular/fire/firestore';

import { Queries } from '@app/domain/models/queries.model';

export namespace AwardsQueryParams {

  export interface Params extends Queries.FirebaseParams {
    player?: string;
    user?: string;
    fixture?: string;
  }
}

export class AwardsQueries<DocumentData> {
  constructor(private collectionRef: CollectionReference<DocumentData>) {}

  sortByDate(){
    return query(
      this.collectionRef,
      orderBy('date', 'desc')
    );
  }

  byParams(params: AwardsQueryParams.Params) {
    let queries: QueryConstraint[] = [];
    if (!params) {
      queries = [];
    } else if (params.player) {
      queries = [
        where('players', 'array-contains', params.player),
      ];
    } else if (params.user && params.fixture) {
      queries = [
        where('fixture', '==', params.fixture),
        where('users', 'array-contains', params.user),
      ];
    } else if (params.user) {
      queries = [
        where('users', 'array-contains', params.user),
      ];
    } else if (params.fixture) {
      queries = [
        where('fixture', '==', params.fixture)
      ];
    }

    return query(
      this.collectionRef,
      ...queries,
      orderBy('date', params?.orderByDirection || 'desc')
    );
  }
}
