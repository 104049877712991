<h2 class="header">
  Confirmation Code
</h2>
<form [formGroup]="confirmationForm" (ngSubmit)="confirm()">
  <div class="content">
    <button
      type="button"
      class="btn--link btn--sm resend-button"
      [loading]="isSubmitting$ | async"
      (click)="resendCode()"
    >
      <fa-icon class="icon-prefix" [icon]="ActionIcons.Refresh"></fa-icon>
      Resend code
    </button>
    <app-code
      id="code"
      [formControl]="confirmationForm.controls.code"
      [length]="6"
      [type]="InputTypeEnum.Number"
      (onFilled)="confirm()"
    ></app-code>
  </div>

  <div class="actions">
    <button
      type="button"
      class="btn--lg btn--error"
      [disabled]="isSubmitting$ | async"
      (click)="close(null)"
    >
      <fa-icon class="icon-prefix" [icon]="ActionIcons.Cancel"></fa-icon>
      Cancel
    </button>

    <button type="submit" class="btn--lg" [loading]="isSubmitting$ | async">
      <fa-icon class="icon-prefix" [icon]="ActionIcons.Submit"></fa-icon>
      Confirm
    </button>
  </div>
</form>
