import { enableProdMode } from '@angular/core';

import { environment } from './environments/environment';
import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';

import localePl from '@angular/common/locales/pl';
import { bootstrapApplication } from '@angular/platform-browser';
import { registerLocaleData } from "@angular/common";

if (environment.production) {
  enableProdMode();
}
registerLocaleData(localePl);

bootstrapApplication(AppComponent, appConfig)
  .catch(err => console.error(err));
