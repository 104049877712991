import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@app/domain/auth/services/auth.service';
import { SectionsEnum } from '@app/config/sections.enum';

@Injectable({
  providedIn: 'root'
})
export class UnAuthGuard  {
  constructor(
    private router: Router,
    private authService: AuthService
  ) {}

  async canActivate(): Promise<boolean> {
    return await this.condition();
  }

  async canActivateChild(): Promise<boolean> {
    return await this.condition();
  }

  async canMatch(): Promise<boolean> {
    return await this.condition();
  }

  private async condition(): Promise<boolean> {
    const authUser = await this.authService.authUser$();
    if (authUser?.uid) {
      await this.router.navigate(['/', SectionsEnum.Core]);
      return false;
    }

    return true;
  }
}
