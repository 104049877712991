import { Icons } from '@app/config/icons';
import { Options } from '@app/domain/options/models/options.model';

export namespace Messages {
  export enum CategoryEnum {
    General = 'general',
    Fixtures = 'fixtures',
    Alerts = 'alerts',
    Teams = 'teams',
  }

  export interface Model {
    id: string;
    read: boolean;
    deleted: boolean;
    timestamp: string;
    payload: MessagingPayload;
  }

  export interface MessagingPayload {
    data?: DataMessagePayload;
    notification?: NotificationMessagePayload;
  }

  export interface DataMessagePayload {
    redirectUrl?: string;
    category?: CategoryEnum;
  }

  export interface NotificationMessagePayload {
    title: string;
    body?: string | null;
    icon?: string | null;
  }

  export function toOptions(): Options.SelectModel<CategoryEnum>[] {
    return Object.values(Messages.CategoryEnum)
      .sort()
      .map(category => ({
        id: category,
        name: category.toLocaleUpperCase(),
        icon: Icons.MessageCategories[category],
      }));
  }
}
