import { WeekDay } from '@angular/common';
import * as moment from 'moment';
import { Timestamp } from '@angular/fire/firestore';

export namespace DateFormatter {
  export const dateFormat = 'YYYY-MM-DD';
  export const timeFormat = 'HH:mm:ss.SSS';
  export const dateUiFormat = 'y.MM.dd';
  export const timeUiFormat = 'HH:mm';
  export const dateTimeUiFormat = 'y.MM.dd, HH:mm';
  export const dateTimeUiShortFormat = 'MM.dd, HH:mm';
  export const dateTimeFormat = `${dateFormat}T${timeFormat}Z`;

  export function nowString(): string {
    return moment(Timestamp.now().toDate()).format(dateTimeFormat);
  }

  export function nowUTCString(): string {
    return `${Timestamp.now().toDate().toISOString().split('.')[0]}+00:00`;
  }

  export function nowDate(): string {
    return moment(Timestamp.now().toDate()).format(dateFormat);
  }

  export function nowMillis(date?: string | number | Date): number {
    return date ? new Date(date).valueOf() : Timestamp.now().toMillis();
  }

  export function nowTimeStamp(): Timestamp {
    return Timestamp.now();
  }

  export function toDateString(dateTime: Timestamp | string | number | Date): string {
    const timestamp = dateTime instanceof Timestamp ? dateTime.toDate() : new Date(dateTime);
    return moment(timestamp).format(dateTimeFormat);
  }

  export function toTimeStamp(dateString: string): Timestamp {
    return Timestamp.fromDate(new Date(dateString));
  }

  export function toFormattedDateTime(dateTime: Timestamp | string | number | Date, format?: string): string | null {
    const timestamp = dateTime instanceof Timestamp ? dateTime.toDate() : new Date(dateTime);
    return moment(timestamp).format(format || dateTimeUiFormat);
  }

  export function hoursBefore(hours: number, time?: number): string {
    const date = time || Timestamp.now().toMillis();
    return toDateString(
      Timestamp.fromMillis(date - (hours * 60 * 60 * 1000))
    );
  }

  export function getNextDayOfTheWeek({ weekDay, time, excludeToday = true, refDate = new Date() }: {
    weekDay: WeekDay,
    time?: string,
    excludeToday?: boolean,
    refDate?: Date
  }) {
    const [hour, minute] = time?.split(':').map(fragment => Number(fragment)) || [0, 0];
    refDate.setHours(hour, minute,0,0);
    refDate.setDate(refDate.getDate() + +excludeToday + (weekDay + 7 - refDate.getDay() - +excludeToday) % 7);
    return moment(refDate).format(dateTimeFormat);
  }

  export function timeDifference(startTime: Timestamp, endTime: Timestamp, format: 'hours' | 'minutes' = 'minutes'): number {
    if (!startTime || !endTime) {
      return 0;
    }

    const divider = format === 'minutes' ? 60 : 3600;
    const diffInSeconds = (endTime.toMillis() - startTime.toMillis()) / 1000;

    return diffInSeconds / divider;
  }
}
