import { Routes } from "@angular/router"
import { SectionsEnum } from "@app/config/sections.enum"
import { AuthGuard } from "@app/guards/auth.guard"
import { UserGroupRolesResolver, UserResolver } from "@app/resolvers/user.resolver"
import { UnAuthGuard } from "@app/guards/un-auth.guard"

export const routes: Routes = [
  {
    path: '',
    redirectTo: SectionsEnum.Core,
    pathMatch: 'full'
  },
  {
    path: SectionsEnum.Core,
    loadChildren: () =>
      import('./modules/core/core.module').then(m => m.CoreModule),
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    resolve: { userGroupRoles: UserGroupRolesResolver }
  },
  {
    path: SectionsEnum.Login,
    loadChildren: () =>
      import('./modules/login/login.module').then(m => m.LoginModule),
    resolve: { user: UserResolver }
  },
  {
    path: SectionsEnum.Logout,
    loadChildren: () =>
      import('./modules/login/login.module').then(m => m.LoginModule),
    resolve: { user: UserResolver },
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    path: SectionsEnum.Register,
    loadChildren: () =>
      import('./modules/register/register.module').then(m => m.RegisterModule),
    resolve: { user: UserResolver },
    canActivate: [UnAuthGuard],
    canActivateChild: [UnAuthGuard],
  },
  {
    path: SectionsEnum.Eula,
    loadChildren: () =>
      import('./modules/eula/eula.module').then(m => m.EulaModule),
  },
  {
    path: '**',
    redirectTo: SectionsEnum.Login
  }
];
