import { Pipe, PipeTransform } from '@angular/core';
import {
  collection,
  CollectionReference,
  doc, docData,
  DocumentReference,
  DocumentSnapshot,
  Firestore,
  getDoc
} from '@angular/fire/firestore';
import { Observable, of } from 'rxjs';
import { take } from 'rxjs/operators';

@Pipe({
    name: 'reference',
    standalone: true
})
export class ReferencePipe implements PipeTransform {
  async transform<Model>(reference: DocumentReference<Model>): Promise<DocumentSnapshot<Model> | null> {
    if (!reference) {
      return null;
    }

    return getDoc(reference);
  }
}

@Pipe({
    name: 'referenceData',
    standalone: true
})
export class ReferenceDataPipe implements PipeTransform {
  constructor(private readonly firestore: Firestore) {}

  async transform<Model>(id: string, collectionId: string, model: Model): Promise<Model | null> {
    if (!id || !collectionId) {
      return null;
    }
    const collectionRef = collection(this.firestore, collectionId) as CollectionReference<Model>;
    const reference = doc(collectionRef, id);
    const documentSnapshot = await getDoc(reference);
    return documentSnapshot.data() || null;
  }
}

@Pipe({
    name: 'referenceChanges',
    standalone: true
})
export class ReferenceChangesPipe implements PipeTransform {
  constructor(private readonly firestore: Firestore) {}

  transform<Model>(id: string, collectionId: string, model: Model): Observable<Model | null> {
    if (!id || !collectionId) {
      return of(null).pipe(take(1));
    }
    const collectionRef = collection(this.firestore, collectionId) as CollectionReference<Model>;
    const reference = doc(collectionRef, id);
    return docData(reference);
  }
}
