import { Injectable } from '@angular/core';
import { collectionData, collectionSnapshots, Firestore, getDocs, increment } from '@angular/fire/firestore';
import { Observable } from 'rxjs';

import { Queries } from '@app/domain/models/queries.model';
import { FirestoreService } from '@app/domain/firestore/firestore.service';
import { FixturesQueries } from '@app/domain/fixtures/services/fixtures.queries';
import { Fixture } from '@app/domain/fixtures/models/fixture.model';
import { map } from 'rxjs/operators';
import Model = Fixture.Model;

@Injectable({
  providedIn: 'root'
})
export class FixturesService extends FirestoreService<Model> {
  static readonly collectionId: string = 'fixtures';
  private readonly queries = new FixturesQueries(this.collectionRef);

  constructor(override firestore: Firestore) {
    super(FixturesService.collectionId, firestore);
  }

  override async fetchAll(): Promise<Model[]> {
    return super.fetchAll(this.queries.sortedByDate());
  }

  override observeAll(): Observable<Model[]> {
    return super.observeAll(this.queries.sortedByDate());
  }

  async fetchUpcoming(params?: Queries.FirebaseParams): Promise<Model[]> {
    const snapshots = await getDocs(this.queries.upcoming(params));
    return snapshots.docs.map(snapshot => snapshot.data());
  }

  observeUpcoming(params?: Queries.FirebaseParams): Observable<Model[]> {
    return collectionData(this.queries.upcoming(params));
  }

  async fetchPrevious(params?: Queries.FirebaseParams): Promise<Model[]> {
    const snapshots = await getDocs(this.queries.previous(params));
    return snapshots.docs.map(snapshot => snapshot.data());
  }

  observePrevious(params?: Queries.FirebaseParams): Observable<Model[]> {
    return collectionData(this.queries.previous(params));
  }

  async fetchForVenue(venue: string): Promise<Model[]> {
    const snapshots = await getDocs(this.queries.venue({ venue }));
    return snapshots.docs.map(snapshot => snapshot.data());
  }

  observeForVenue(venue: string): Observable<Model[]> {
    return collectionData(this.queries.venue({ venue }));
  }

  observeForVenueCount(venue: string): Observable<number> {
    return collectionSnapshots(this.queries.venue({ venue })).pipe(map(snapshots => snapshots.length));
  }

  observeForGroups(groups: string[], open?: boolean, limit?: number): Observable<Model[]> {
    return collectionData(this.queries.groups({ groups, open, limit }));
  }

  observeForGroupCount(group: string, open?: boolean): Observable<number> {
    return collectionSnapshots(this.queries.groups({ groups: [group], open })).pipe(map(snapshots => snapshots.length));
  }

  async pay(fixture: string, fee: number = 0): Promise<void> {
    return this.update(fixture, { paid: increment(fee) });
  }
}
