import theme from './map-themes/futbalowski-dark-alt.json';

export const googleConfig = {
  apiKey: 'AIzaSyDsW9nbeSo0ahcthbprr73AqbO1c3CdJ44',
  maps: {
    endpoint: 'https://maps.googleapis.com/maps/api/js',
    theme,
    ids: {
      venues: '1674593d285cb6c0'
    }
  },
  streetView: {
    endpoint: 'https://maps.googleapis.com/maps/api/streetview',
  },
  geoCoding: {
    endpoint: 'https://maps.googleapis.com/maps/api/geocode/json',
  },
  directions: {
    endpoint: 'https://maps.googleapis.com/maps/api/directions/json',
  }
};
