import { Pipe, PipeTransform } from '@angular/core';
import { Options } from '@app/domain/options/models/options.model';
import { Venue } from '@app/domain/venues/models/venue.model';

@Pipe({
    name: 'pitchCover',
    standalone: true
})
export class PitchCoverPipe implements PipeTransform {
  transform(cover: Venue.PitchCover[]): string {
    const options = Options.statusOptions(Venue.PitchCover);
    return cover?.length
      ? cover.map(type => options.find(option => option.id === type)?.name || '?').join(', ')
      : 'N/A';
  }
}
