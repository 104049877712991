import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { Fixture } from '@app/domain/fixtures/models/fixture.model';

@Pipe({
    name: 'price',
    standalone: true
})
export class PricePipe implements PipeTransform {
  constructor(private readonly currencyPipe: CurrencyPipe) {}

  transform(price: number, currency: string = 'PLN'): string {
    return this.currencyPipe.transform(price, currency, 'symbol-narrow', '1.0-2') || 'N/A';
  }
}

@Pipe({
    name: 'pricePerPlayer',
    standalone: true
})
export class PricePerPlayerPipe implements PipeTransform {
  constructor(private readonly currencyPipe: CurrencyPipe) {}

  transform(fixture: Fixture.Model): string | null {
    const price = fixture.pricePerPlayer || Math.ceil(fixture.price / (fixture.maxTeams * fixture.playersPerTeam));
    const currency = this.currencyPipe.transform(price, fixture.currency, 'symbol-narrow', '1.0-2');
    return fixture.pricePerPlayer ? currency : 'max. ' + currency;
  }
}

@Pipe({
    name: 'priceAfterDiscount',
    standalone: true
})
export class PriceAfterDiscountPipe implements PipeTransform {
  constructor(private readonly currencyPipe: CurrencyPipe) {}

  transform(fixture: Fixture.Model): string | null {
    const price = fixture.priceAfterDiscount && fixture.priceAfterDiscount !== 0 ? fixture.priceAfterDiscount : fixture.price;
    const currency = this.currencyPipe.transform(price, fixture.currency, 'symbol-narrow', '1.0-2');
    return fixture.priceAfterDiscount && fixture.priceAfterDiscount !== 0 ? currency : 'max. ' + currency;
  }
}
