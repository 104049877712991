<label
  *ngIf="label || icon"
  [for]="id"
  class="input__label"
  [class.required]="isRequired"
>
  <fa-icon *ngIf="icon" class="icon-prefix" [icon]="icon"></fa-icon>
  {{ label }}
</label>

<input
  [id]="id"
  [attr.name]="id"
  type="time"
  autocomplete="off"
  [placeholder]="timePlaceholder"
  class="input__element time"
  [class.filled]="value"
  [disabled]="disabled"
  [readOnly]="readonly"
  [(ngModel)]="value"
/>
