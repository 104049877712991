import { Pipe, PipeTransform } from '@angular/core';
import { FixturesService } from '@app/domain/fixtures/services/fixtures.service';

import { Venue } from '@app/domain/venues/models/venue.model';
import { VenuesService } from '@app/domain/venues/services/venues.service';

@Pipe({
    name: 'venueData',
    standalone: true
})
export class VenueDataPipe implements PipeTransform {
  constructor(private readonly venuesService: VenuesService) {}

  async transform(id: string) {
    return this.venuesService.fetch(id);
  }
}

@Pipe({
    name: 'venueChanges',
    standalone: true
})
export class VenueChangesPipe implements PipeTransform {
  constructor(private readonly venuesService: VenuesService) {}

  transform(id: string) {
    return this.venuesService.observe(id);
  }
}

@Pipe({
    name: 'venueFixturesCount',
    standalone: true
})
export class VenueFixturesCountPipe implements PipeTransform {
  constructor(private readonly fixturesService: FixturesService) {}

  transform(id: string) {
    return this.fixturesService.observeForVenueCount(id);
  }
}

@Pipe({
    name: 'pitchData',
    standalone: true
})
export class PitchDataPipe implements PipeTransform {
  constructor(private readonly venuesService: VenuesService) {}

  async transform(id: string, venue: string) {
    return this.venuesService.fetchPitch(venue, id);
  }
}

@Pipe({
    name: 'pitchChanges',
    standalone: true
})
export class PitchChangesPipe implements PipeTransform {
  constructor(private readonly venuesService: VenuesService) {}

  transform(id: string, venue: string) {
    return this.venuesService.observePitch(venue, id);
  }
}
