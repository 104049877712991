import { CollectionReference, limit, orderBy, query, where } from '@angular/fire/firestore';

import { Queries } from '@app/domain/models/queries.model';

export namespace UserQueryParams {
  export interface Search extends Queries.FirebaseParams {
    query: string;
  }

  export interface PhoneNumber extends Queries.FirebaseParams {
    phoneNumber: string;
  }

  export interface Groups extends Queries.FirebaseParams {
    groups: string[];
    exclude?: boolean;
  }
}

export class UsersQueries<DocumentData> {
  constructor(private collectionRef: CollectionReference<DocumentData>) {}

  sortedByName(params?: UserQueryParams.Search) {
    const queries = params?.query ? [
      where('displayName', '>=', params.query),
      where('displayName', '<=', `${params.query}\uf8ff`)
    ]: [];

    return query(
      this.collectionRef,
      ...queries,
      orderBy('displayName', params?.orderByDirection || 'asc'),
    );
  }

  phoneNumber(params: UserQueryParams.PhoneNumber) {
    const phoneNumber = params.phoneNumber;
    return query(
      this.collectionRef,
      where('phoneNumber', '==', phoneNumber),
      limit(1)
    );
  }

  organiser(params?: Queries.FirebaseParams){
    return query(
      this.collectionRef,
      where('organiser', '==', true),
      orderBy('displayName', params?.orderByDirection || 'asc')
    );
  }

  groups(params: UserQueryParams.Groups) {
    return query(
      this.collectionRef,
      where('groups', 'array-contains-any', params.groups),
      orderBy('displayName', params?.orderByDirection || 'asc')
    )
  }
}
