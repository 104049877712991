import { Component, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { Icons } from '@app/config/icons';
import { Team } from '@app/domain/teams/models/team.model';
import { TeamsService } from '@app/domain/teams/services/teams.service';
import { ModalInputs } from '@app/modules/shared/modal/models/modal-inputs.model';
import { Modal } from '@app/modules/shared/modal/models/modal.model';
import { NotificationService } from '@app/modules/shared/notification/services/notification.service';
import { trackById } from '@app/utils/track-by';
import { DisplayNamePipe } from '../../../pipes/display-name/display-name.pipe';
import { UserDataPipe } from '../../../pipes/reference/user-reference.pipe';
import { LoaderComponent } from '../../../loader/loader.component';
import { LoadingDirective } from '../../../directives/loading.directive';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { TeamColorBadgeComponent } from '../../../badges/team-color-badge/team-color-badge.component';
import { NgFor, AsyncPipe } from '@angular/common';

@Component({
    selector: 'app-team-select-modal',
    templateUrl: './team-select-modal.component.html',
    styleUrls: ['./team-select-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgFor, TeamColorBadgeComponent, FaIconComponent, LoadingDirective, LoaderComponent, AsyncPipe, UserDataPipe, DisplayNamePipe]
})
export class TeamSelectModalComponent extends Modal.Model<ModalInputs.TeamSelect, Team.Model | null>{
  inputs: ModalInputs.TeamSelect;
  teamOptions: Team.Model[];
  selectedTeam: Team.Model | null;

  readonly isLoading$ = new BehaviorSubject<boolean>(true);

  readonly ActionIcons = Icons.Actions;
  readonly TeamColor = Team.Color;
  readonly trackById = trackById;

  constructor(
    private readonly teamsService: TeamsService,
    private readonly notificationService: NotificationService,
    private readonly changeDetectorRef: ChangeDetectorRef,
  ) {
    super();
  }

  override onInjectInputs(inputs: ModalInputs.TeamSelect) {
    this.inputs = inputs;
    this.teamsService.fetchAllForFixture(this.inputs.fixture.id)
      .then(teams => {
        this.teamOptions = [...teams, { id: '-1', name: 'Reserve' } as Team.Model];
        this.selectedTeam = teams.find(team => team.id === inputs.player.team) || null;
        this.changeDetectorRef.detectChanges();
      })
      .catch(errorResponse => {
        this.notificationService.error(errorResponse.message);
        return this.close(null);
      })
      .finally(() => this.isLoading$.next(false));
  }

  submit(): void {
    this.close(this.selectedTeam);
  }

  selectTeam(team: Team.Model | null): void {
    this.selectedTeam = team;
  }
}
