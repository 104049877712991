<label
  *ngIf="label || icon"
  [for]="id"
  class="input__label"
  [class.required]="isRequired"
>
  <fa-icon *ngIf="icon" class="icon-prefix" [icon]="icon"></fa-icon>
  {{ label }}
</label>
<div class="input__container" [class.disabled]="disabled">
  <textarea
    #fieldInput
    [id]="id"
    [attr.name]="id"
    [value]="value"
    autocomplete="off"
    [placeholder]="placeholder"
    class="input__element"
    [class.filled]="value || value === 0"
    [disabled]="disabled"
    [readOnly]="readonly"
    [attr.maxLength]="maxlength"
    (input)="writeValue(fieldInput.value)"
  ></textarea>
</div>
