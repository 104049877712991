import { CollectionReference, orderBy, query, where, WhereFilterOp, FieldPath } from '@angular/fire/firestore';

import { Queries } from '@app/domain/models/queries.model';
import { Player } from '@app/domain/players/models/player.model';
import Position = Player.Position;

export namespace PlayersQueryParams {
  export interface Fixture extends Queries.FirebaseParams {
    fixture: string;
  }

  export interface Team extends Queries.FirebaseParams {
    team: string;
  }

  export interface Position extends Fixture {
    positions: Player.Position[];
  }

  export interface Unpaid extends Fixture {
    unpaid: boolean;
  }

  export interface User extends Queries.FirebaseParams {
    user: string;
    group?: string | null;
  }
}

export class PlayersQueries<DocumentData> {
  constructor(private collectionRef: CollectionReference<DocumentData>) {}

  fixture(params: PlayersQueryParams.Fixture) {
    return query(
      this.collectionRef,
      where('fixture', '==', params.fixture),
      orderBy('checkInTime', 'asc')
    );
  }

  team(params: PlayersQueryParams.Team) {
    return query(
      this.collectionRef,
      where('team', '==', params.team),
      orderBy('position', 'asc')
    );
  }

  positions(params: PlayersQueryParams.Position) {
    let operator: WhereFilterOp;
    let value: Player.Position | Player.Position[];
    let orderKey: string | FieldPath;
    if (params.positions.length === 1) {
      operator = '==';
      value = params.positions[0];
      orderKey = value === Position.GoalKeeper ? 'abilities.goalKeeping' : 'overall';
    } else {
      operator = 'in';
      value = params.positions;
      orderKey = 'overall';
    }
    return query(
      this.collectionRef,
      where('fixture', '==', params.fixture),
      where('reserve', '==', false),
      where('position', operator, value),
      orderBy(orderKey, params.orderByDirection || 'desc'));
  }

  unpaid(params: PlayersQueryParams.Unpaid) {
    return query(
      this.collectionRef,
      where('fixture', '==', params.fixture),
      where('paymentType', params.unpaid ? '==' : '!=', null),
      orderBy('checkInTime', 'asc')
    );
  }

  user(params: PlayersQueryParams.User) {
    const queries = params.group ? [
      where('user', '==', params.user),
      where('group', '==', params.group),
      where('reserve', '==', false),
    ] : [
      where('user', '==', params.user),
      where('reserve', '==', false),
    ];
    return query(
      this.collectionRef,
      ...queries,
      orderBy('checkInTime', 'desc')
    );
  }
}
