import { IconProp } from '@fortawesome/fontawesome-svg-core';

export namespace Options {
  export interface Model<OptionType> {
    options: Array<OptionType>;
  }

  export interface SelectModel<IdType = string, DataType = any> {
    id: IdType;
    name?: string | null;
    photoUrl?: string | null;
    icon?: IconProp;
    data?: DataType;
  }

  export const allId = 'all';

  export function statusOptions<StatusType>(statuses: StatusType, withAll?: boolean): Options.SelectModel[] {
    const options = Object.keys(statuses).map(status => ({
      id: statuses[status],
      name: status.replace(/([A-Z])/g, ' $1').trim(),
    }));

    if (withAll) {
      options.unshift({
        id: allId,
        name: 'All',
      })
    }

    return options;
  }

  export function sortingByName<IdType>(
    options: Array<SelectModel<IdType>>
  ): Array<SelectModel<IdType>> {
    return (options || [])
      .sort((a, b) =>
        parseFloat(
          a?.name ? a.name.toLocaleLowerCase() : '0'
        ) - parseFloat(
          b?.name ? b.name.toLocaleLowerCase() : '0')
      );
  }
}
