import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ConfirmationResult } from '@angular/fire/auth';
import { FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { User } from '@app/domain/users/models/user.model';
import { BehaviorSubject } from 'rxjs';

import { Icons } from '@app/config/icons';
import { UsersService } from '@app/domain/users/services/users.service';
import { ModalInputs } from '@app/modules/shared/modal/models/modal-inputs.model';
import { Modal } from '@app/modules/shared/modal/models/modal.model';
import { InputTypeEnum } from "@app/modules/shared/form/components/input/enums/input-type.enum"
import { AsyncPipe } from '@angular/common';
import { CodeComponent } from '../../../form/components/code/code.component';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { LoadingDirective } from '../../../directives/loading.directive';

@Component({
    selector: 'app-phone-number-confirmation-modal',
    templateUrl: './phone-number-confirmation-modal.component.html',
    styleUrls: ['./phone-number-confirmation-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, LoadingDirective, FaIconComponent, CodeComponent, AsyncPipe]
})
export class PhoneNumberConfirmationModalComponent extends Modal.Model<ModalInputs.PhoneNumberConfirmation, User.Model | null> {
  inputs: ModalInputs.PhoneNumberConfirmation;
  readonly confirmationForm = new FormGroup({
    code: new FormControl<string | null>(null, [
      Validators.required,
      Validators.pattern(/\d{6}/),
      Validators.maxLength(6)
    ]),
  });

  constructor(private usersService: UsersService) {
    super();
  }

  readonly isSubmitting$ = new BehaviorSubject<boolean>(false);

  readonly ActionIcons = Icons.Actions;
  protected readonly InputTypeEnum = InputTypeEnum

  onInjectInputs(inputs: ModalInputs.PhoneNumberConfirmation): void {
    this.inputs = inputs;
  }

  async confirm(): Promise<void> {
    this.confirmationForm.markAllAsTouched();
    if (this.confirmationForm.invalid) {
      return;
    }
    this.isSubmitting$.next(true);
    const formValue = this.confirmationForm.getRawValue();
    if (!formValue.code) {
      return;
    }
    return this.usersService.confirmWithCode(formValue.code, this.inputs.displayName)
      .then(user => this.close(user))
      .finally(() => this.isSubmitting$.next(false));
  }

  async resendCode(): Promise<void | ConfirmationResult> {
    this.isSubmitting$.next(true);
    return this.usersService.signInWithPhoneNumber(this.inputs.phoneNumber)
      .finally(() => this.isSubmitting$.next(false));
  }

}
