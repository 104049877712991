<div class="checkbox__container">
  <input
    [id]="id"
    [attr.name]="id"
    type="checkbox"
    [value]="value"
    autocomplete="off"
    class="checkbox__input"
    [disabled]="disabled"
    [readOnly]="readonly"
    [checked]="value"
    (change)="changeValue()"
  />
  <label
    [for]="id"
    class="checkbox__label"
    [class.checkbox__label--checked]="value"
    [class.checkbox__label--disabled]="disabled || readonly"
    [class.required]="isRequired"
  >
    <span class="checkbox__element">
      <fa-icon class="icon" [icon]="ActionIcons.Ok"></fa-icon>
    </span>
    <div class="checkbox__content">
      <ng-content></ng-content>
    </div>
  </label>
</div>
