import { CollectionReference, orderBy, query, where } from '@angular/fire/firestore';

import { Queries } from '@app/domain/models/queries.model';

export namespace TeamsQueryParams {
  export interface Fixture extends Queries.FirebaseParams {
    fixture: string;
  }
}

export class TeamsQueries<DocumentData> {
  constructor(private collectionRef: CollectionReference<DocumentData>) {}

  fixture(params: TeamsQueryParams.Fixture) {
    return query(
      this.collectionRef,
      where('fixture', '==', params.fixture),
      orderBy('order', 'asc')
    );
  }
}
