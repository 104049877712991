import { Injectable } from '@angular/core';
import { collectionData, collectionSnapshots, Firestore, getDocs } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';

import { Fixture } from '@app/domain/fixtures/models/fixture.model';
import { FixturesService } from '@app/domain/fixtures/services/fixtures.service';
import { DateFormatter } from '@app/utils/date-formatter';
import { Payment } from '@app/domain/models/payment.model';
import { User } from '@app/domain/users/models/user.model';
import { UsersService } from '@app/domain/users/services/users.service';
import { FirestoreService } from '@app/domain/firestore/firestore.service';
import { PlayersQueries } from '@app/domain/players/services/players.queries';
import { Player } from '@app/domain/players/models/player.model';
import { Team } from '@app/domain/teams/models/team.model';
import Model = Player.Model;

@Injectable({
  providedIn: 'root'
})
export class PlayersService extends FirestoreService<Model> {
  static readonly collectionId: string = 'players';
  private readonly queries = new PlayersQueries(this.collectionRef);

  constructor(
    override firestore: Firestore,
    private usersService: UsersService,
    private fixturesService: FixturesService,
  ) {
    super(PlayersService.collectionId, firestore);
  }

  playerId(fixture: string, player?: string) {
    return `${fixture}-${player || this.usersService.currentUserId}`;
  }

  observeForFixture(fixture: string) {
    return this.observe(this.playerId(fixture));
  }

  async fetchGoalKeepersForFixture(fixture: string) {
    const snapshots = await getDocs(this.queries.positions({
      fixture,
      positions: [Player.Position.GoalKeeper],
    }));
    return snapshots.docs.map(snapshot => snapshot.data());
  }

  async fetchFieldPlayersForFixture(fixture: string) {
    const snapshots = await getDocs(this.queries.positions({
      fixture,
      positions: [
        Player.Position.Offense,
        Player.Position.Midfield,
        Player.Position.Defense,
        Player.Position.Free
      ],
    }));
    return snapshots.docs.map(snapshot => snapshot.data());
  }

  observeUnpaidForFixture(fixture: string) {
    return collectionData<Model>(this.queries.unpaid({ fixture, unpaid: true }));
  }

  observeAllForFixture(fixture: string) {
    return collectionData<Model>(this.queries.fixture({ fixture }));
  }

  async fetchAllForFixture(fixture: string) {
    const snapshots = await getDocs(this.queries.fixture({ fixture }));
    return snapshots.docs.map(snapshot => snapshot.data());
  }

  observeCountForFixture(fixture: string) {
    return collectionSnapshots(this.queries.fixture({ fixture })).pipe(map(snapshots => snapshots.length));
  }

  observeAllForTeam(team: string) {
    return collectionData<Model>(this.queries.team({ team }));
  }

  observeAllForUser(user: string, group?: string | null) {
    return this.observeAll(this.queries.user({ user, group }));
  }

  async checkInForFixture({ fixture, user, organiser, replaceOrganiser }: {
    fixture: Fixture.Model,
    user?: User.Model,
    organiser?: boolean,
    replaceOrganiser?: Player.Model | null
  }) {
    const player = user || this.usersService.currentUser;
    if (!player) {
      return;
    }
    return this.create({
      id: this.playerId(fixture.id, player.id),
      abilities: {...player.abilities },
      checkInTime: replaceOrganiser?.checkInTime || DateFormatter.nowTimeStamp(),
      color: replaceOrganiser?.color || null,
      currency: fixture.currency,
      fixture: fixture.id,
      group: fixture.group,
      hasMultiSport: player.hasMultiSport,
      injured: player.injured || false,
      leg: player.leg,
      organiser: Boolean(organiser || replaceOrganiser),
      overall: player.abilities.overall,
      paid: replaceOrganiser?.paid || 0,
      paymentType: organiser || replaceOrganiser ? player.payments.preferred || null : null,
      position: replaceOrganiser?.position || player.preferredPosition,
      reserve: false,
      team: replaceOrganiser?.team || null,
      user: player.id,
    });
  }

  async changeTeam(player: string, team: Team.Model | null) {
    return this.update(player, { team: team?.id || null, color: team?.color || null });
  }

  async changePosition(player: string, position: Player.Position) {
    return this.update(player, { position });
  }

  async checkOutFromFixture(fixture: string, player?: string) {
    return this.delete(player || this.playerId(fixture));
  }

  async pay(player: string, paymentType: Payment.Type, fixture: Fixture.Model) {
    return this.update(player, { paymentType, paid: fixture.pricePerPlayer })
      .then(() => this.fixturesService.pay(fixture.id, fixture.pricePerPlayer));
  }
}
