import { Injectable } from '@angular/core';
import { collectionData, Firestore, getDocs, writeBatch } from '@angular/fire/firestore';

import { FirestoreService } from '@app/domain/firestore/firestore.service';
import { Award } from '@app/domain/awards/models/award.model';
import { AwardsQueries, AwardsQueryParams } from '@app/domain/awards/services/awards.queries';
import Model = Award.Model;

@Injectable({
  providedIn: 'root'
})
export class AwardsService extends FirestoreService<Model> {
  static readonly collectionId: string = 'awards';
  private readonly queries = new AwardsQueries(this.collectionRef);

  constructor(override firestore: Firestore) {
    super(AwardsService.collectionId, firestore);
  }

  override async fetchAll() {
    return super.fetchAll(this.queries.sortByDate());
  }

  override observeAll() {
    return super.observeAll(this.queries.sortByDate());
  }

  async fetchAllByParams(params: AwardsQueryParams.Params) {
    const snapshots = await getDocs(this.queries.byParams(params));
    return snapshots.docs.map(snapshot => snapshot.data());
  }

  observeAllByParams(params: AwardsQueryParams.Params){
    return collectionData(this.queries.byParams(params));
  }

  async createBulk(formValue: Award.Update) {
    const batch = writeBatch(this.firestore);
    for (const type in formValue) {
      const award = formValue[type];
      const awardExists = await this.exists(award.id);
      const ref = this.ref(award.id);

      awardExists ? batch.update(ref, award) : batch.set(ref, award);
    }
    return batch.commit();
  }
}
