import { Icons } from '@app/config/icons';
import { Options } from '@app/domain/options/models/options.model';

export namespace Payment {
  export enum Type {
    Revolut = 'revolut',
    Blik = 'blik',
    PayPal = 'paypal',
    Transfer = 'transfer',
    Cash = 'cash',
  }

  export function toOptions(): Options.SelectModel[] {
    return Options.statusOptions(Payment.Type).map(item => ({
      id: item.id,
      name: item.name,
      icon: Icons.Payments[item.id],
    }));
  }
}
