import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';

import { Award } from '@app/domain/awards/models/award.model';
import { AwardsService } from '@app/domain/awards/services/awards.service';
import Model = Award.Model;

@Pipe({
    name: 'awardData',
    standalone: true
})
export class AwardDataPipe implements PipeTransform {
  constructor(private readonly awardsService: AwardsService) {}

  async transform(playerId: string): Promise<Model[] | null> {
    return this.awardsService.fetchAllByParams({ player: playerId });
  }
}

@Pipe({
    name: 'awardChanges',
    standalone: true
})
export class AwardChangesPipe implements PipeTransform {
  constructor(private readonly awardsService: AwardsService) {}

  transform(playerId: string): Observable<Model[] | null> {
    return this.awardsService.observeAllByParams({ player: playerId });
  }
}
