<label
  *ngIf="label || icon"
  [for]="id"
  class="select__label"
  [class.required]="isRequired"
>
  <fa-icon *ngIf="icon" class="icon-prefix" [icon]="icon"></fa-icon>
  {{ label }}
</label>
<div #selectContainer class="select__container">
  <input
    #filterInput
    class="select__element"
    type="text"
    autocomplete="off"
    [id]="id + '-filter'"
    [attr.name]="id + '-filter'"
    [class.select__element--filterable]="config.filterable"
    [class.select__element--clearable]="config.clearable"
    [class.select__element--filled]="value?.['id'] || value"
    [value]="selectedOption?.['name'] || value || ''"
    [placeholder]="placeholderText"
    [disabled]="disabled || readonly"
    [readOnly]="!config.filterable || readonly"
    (keyup)="onFilterKeyUp($event)"
    (keydown)="onFilterKeyDown($event)"
    (focus)="onFilterFocus()"
    (blur)="onFilterBlur()"
    (click)="turnOnInputMode()"
  />
  <select
    tabindex="-1"
    class="select__element"
    autocomplete="off"
    [title]="selectedOption?.['name'] || selectedOption"
    [disabled]="disabled || readonly"
    [class.select__element--empty]="!selectedOption"
    [class.select__element--filled]="value?.['id'] || value"
    [value]="value?.['id'] || value"
    [id]="id"
    [attr.name]="id"
  >
    <option [value]="null" disabled>{{ placeholderText }}</option>
    <option [value]="undefined" disabled>{{ placeholderText }}</option>
    <option [value]="value?.['id'] || value" [selected]="value?.['id'] || value">{{
      selectedOption?.['name'] || value
      }}</option>
  </select>
  <div class="selected__element" [class.disabled]="disabled" [title]="selectedOption?.['name'] || value || placeholderText">
    <fa-icon
      *ngIf="selectedOption?.['icon']"
      [icon]="selectedOption?.['icon']"
      class="select__list-option__icon"
      size="sm"
    ></fa-icon>
    <div *ngIf="selectedOption?.hasOwnProperty('photoUrl')" class="select__list-option__image">
      <img
        alt=""
        [default]="'assets/images/flags/square/unknown.svg'"
        [src]="selectedOption?.['photoUrl'] | photoUrl: 64: staticUrl"
      />
    </div>
    <span class="select__list-option__text" [class.select__placeholder]="!value">{{
      selectedOption?.['name'] || value || placeholderText
      }}</span>
  </div>
  <fa-icon
    *ngIf="value && config.clearable"
    class="select__clear"
    [icon]="ActionIcons.Clear"
    (click)="clearSelect()"
  ></fa-icon>
  <fa-icon
    class="select__icon"
    icon="chevron-down"
    [class.select__icon--open]="isListVisible"
  ></fa-icon>
</div>

<app-dropdown [config]="{ stickTo: selectContainer }">
  <div
    *ngIf="filteredOptions && inputMode"
    class="select__list"
    [excludedElements]="[selectContainer]"
    (clickElsewhere)="hideList()"
  >
    <cdk-virtual-scroll-viewport
      #cdkVirtualScrollViewport
      [itemSize]="config.optionHeight"
      class="select__viewport"
      [style.height.px]="calculatedListHeight"
    >
      <div
        *cdkVirtualFor="
          let option of filteredOptions;
          let index = index;
          trackBy: trackByFn
        "
        class="select__list-option"
        [class.select__list-option--active]="activeOptionIndex === index"
        [class.select__list-option--selected]="
          option?.['id'] ? option['id'] === value?.['id'] : option === value
        "
        [title]="option?.['name'] || option"
        [style.height.px]="config.optionHeight"
        (click)="selectOption(option, $event)"
      >
        <fa-icon
          *ngIf="option?.['icon']"
          [icon]="option['icon']"
          class="select__list-option__icon"
          size="sm"
        ></fa-icon>
        <div *ngIf="option?.hasOwnProperty('photoUrl')" class="select__list-option__image">
          <img
            alt=""
            [default]="'assets/images/flags/square/unknown.svg'"
            loading="lazy"
            [src]="option?.['photoUrl'] | photoUrl: 64: staticUrl"
          />
        </div>
        <span class="select__list-option__text">{{ option?.['name'] || option }}</span>
      </div>
    </cdk-virtual-scroll-viewport>
    <div
      *ngIf="!filteredOptions?.length"
      class="select__list-option select__list-option--empty"
      [style.height.px]="config.optionHeight"
    >
      No options are available
    </div>
  </div>
</app-dropdown>
