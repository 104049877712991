import { Pipe, PipeTransform } from '@angular/core';
import { Forecast, LegacyForecast } from '@app/domain/forecast/models/forecast.model';

@Pipe({
    name: 'forecast',
    standalone: true
})
export class ForecastPipe implements PipeTransform {

  transform(forecast: Forecast.Model | LegacyForecast.Model | null | undefined): Partial<Forecast.Model> | null {
    if (!forecast) {
      return null;
    }
    // Transform legacy model into new model
    if (Array.isArray(forecast.weather)) {
      const legacyForecastModel = forecast as LegacyForecast.Model;
      const weather = legacyForecastModel.weather[0];
      return {
        weather: {
          code: weather.id,
          description: weather.description,
          icon: weather.icon,
        },
        temp: legacyForecastModel.main.temp,
        wind_spd: legacyForecastModel.wind.speed
      };
    }

    return forecast as Forecast.Model;
  }

}
