import { FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';

export namespace CustomValidators {
  export enum ErrorKeys {
    Email = 'email',
    Mismatch = 'mismatch',
    MinLength = 'minlength',
    Pattern = 'pattern',
    Required = 'required',
    MissingUppercase = 'missingUppercase',
    MissingLowercase = 'missingLowercase',
    MissingDigit = 'missingDigit',
    MissingSpecialChar = 'missingSpecialChar',
    MinPasswordLength = 'minPasswordLength',
    PasswordMatch = 'passwordMatch',
  }

  export const errorMessages: { [key in ErrorKeys]?: string } = {
    [ErrorKeys.Mismatch]: 'Vale mismatch',
    [ErrorKeys.Email]: 'E-mail address is invalid',
    [ErrorKeys.MinLength]: 'Value is too short',
    [ErrorKeys.Pattern]: 'Value does not match the pattern',
    [ErrorKeys.Required]: 'Value cannot be empty',
    [ErrorKeys.MissingUppercase]: 'At least one uppercase letter',
    [ErrorKeys.MissingLowercase]: 'At least one lowercase letter',
    [ErrorKeys.MissingDigit]: 'At least one digit',
    [ErrorKeys.MissingSpecialChar]: 'At least one special character (!|@|#|$|%|^|&|*)',
    [ErrorKeys.MinPasswordLength]: 'At least 8 characters',
    [ErrorKeys.PasswordMatch]: 'Password cannot be the same as the previous one',
  };

  // export const strongPasswordRegx: RegExp = /^(?=[^A-Z]*[A-Z])(?=[^a-z]*[a-z])(?=.*[!@#$%^&*])(?=\D*\d).{8,}$/;
  //
  // export const matchPassword: ValidatorFn = (formGroup: FormGroup) => {
  //   const returnValue = formGroup?.controls.password?.value === formGroup?.controls.passwordConfirm?.value
  //     ? null
  //     : { [ErrorKeys.Mismatch]: true };
  //
  //   formGroup?.controls.passwordConfirm?.setErrors(returnValue);
  //
  //   return returnValue;
  // };
  //
  // export const newPassword: ValidatorFn = (formGroup: FormGroup) => {
  //   const returnValue = formGroup?.controls.password?.value === formGroup?.controls.newPassword?.value
  //     ? { [ErrorKeys.PasswordMatch]: true }
  //     : null;
  //
  //   formGroup?.controls.password?.setErrors(returnValue);
  //
  //   return returnValue;
  // };
  //
  // export const strongPassword: ValidatorFn = (formControl: FormControl<string>) => {
  //   const value = formControl?.value || '';
  //   let errors: { [key in ErrorKeys]?: boolean } | null = null;
  //   if (strongPasswordRegx.test(value)) {
  //     return errors;
  //   } else {
  //     errors = {};
  //   }
  //   if (!value.match('^(?=.*[A-Z])')) {
  //     errors[ErrorKeys.MissingUppercase] = true;
  //   }
  //   if (!value.match('(?=.*[a-z])')) {
  //     errors[ErrorKeys.MissingLowercase] = true;
  //   }
  //   if (!value.match('(.*[0-9].*)')) {
  //     errors[ErrorKeys.MissingDigit] = true;
  //   }
  //   if (!value.match('(?=.*[!@#$%^&*])')) {
  //     errors[ErrorKeys.MissingSpecialChar] = true;
  //   }
  //   if (!value.match('.{8,}')) {
  //     errors[ErrorKeys.MinPasswordLength] = true;
  //   }
  //
  //   return errors;
  // };
}
