import { UserMetadata } from '@angular/fire/auth';
import { DomainModel } from '@app/domain/models/domain.model';
import { Payment } from '@app/domain/models/payment.model';
import { Options } from '@app/domain/options/models/options.model';
import { Player } from '@app/domain/players/models/player.model';

export namespace User {
  export interface Model extends DomainModel {
    abilities: Player.Abilities;
    active: boolean;
    admin: boolean;
    analyst: boolean;
    country: string | null;
    deleted: boolean;
    displayName: string | null;
    email: string | null;
    emailVerified: boolean;
    groups: string[];
    hasMultiSport: boolean;
    injured: boolean;
    language: string;
    leg: Player.Leg | null;
    metadata: UserMetadata;
    organiser: boolean;
    payments: Payments;
    phoneNumber: string | null;
    photoUrl: string | null;
    positions: Player.Positions;
    preferredPosition: Player.Position | null;
  }

  export interface Payments {
    blik: string | null;
    cash: boolean;
    paypal: string | null;
    preferred: Payment.Type | null;
    revolut: string | null;
    transfer: string | null;
  }

  export function toOptions(items: Model[], withData = true): Options.SelectModel<string, Model | null>[] {
    return (items || [])
      .map(item => ({
        id: item.id,
        name: item.displayName || 'Deleted User',
        photoUrl: item.photoUrl,
        data: withData ? item : null,
      }));
  }

  export function sortByDisplayName(items: Model[], sortBy = 'displayName'): Model[] {
    return items.sort((a,b) =>
      a?.[sortBy] && b?.[sortBy] ? a?.[sortBy]?.localeCompare(b?.[sortBy]) : 0
    );
  }
}
