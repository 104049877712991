import { Clipboard } from '@angular/cdk/clipboard';
import { Directive, HostBinding, Input } from '@angular/core';

import { NotificationService } from '@app/modules/shared/notification/services/notification.service';

@Directive({
    selector: '[copyToClipboard]',
    host: {
        '(tap)': 'copy(copyToClipboard)',
    },
    standalone: true,
})
export class CopyToClipboardDirective {
  @Input() @HostBinding('copyToClipboard') copyToClipboard: any;
  @Input() @HostBinding('copyMessage') copyMessage = 'Copied to clipboard';
  @HostBinding('class.copy-text') copyText = true;

  constructor(
    private readonly clipboard: Clipboard,
    private readonly notificationService: NotificationService,
  ) {}

  copy(value: any): void {
    this.clipboard.copy(value);
    this.notificationService.success(this.copyMessage, { icon: 'copy' });
  }
}
