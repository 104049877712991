import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PipesModule } from '@app/modules/shared/pipes/pipes.module';

import { FlagComponent } from './flag.component';

@NgModule({
    exports: [
        FlagComponent,
    ],
    imports: [
    CommonModule,
    PipesModule,
    FlagComponent,
]
})
export class FlagModule { }
