import { Component, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

import { Icons } from '@app/config/icons';
import { Payment } from '@app/domain/models/payment.model';
import { Options } from '@app/domain/options/models/options.model';
import { User } from '@app/domain/users/models/user.model';
import { UsersService } from '@app/domain/users/services/users.service';
import { ModalInputs } from '@app/modules/shared/modal/models/modal-inputs.model';
import { Modal } from '@app/modules/shared/modal/models/modal.model';
import { NotificationService } from '@app/modules/shared/notification/services/notification.service';
import { PricePipe } from '../../../pipes/price/price.pipe';
import { LoaderComponent } from '../../../loader/loader.component';
import { LoadingDirective } from '../../../directives/loading.directive';
import { CopyToClipboardDirective } from '../../../directives/copy-to-clipboard.directive';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { SelectComponent } from '../../../form/components/select/select.component';
import { NgIf, NgSwitch, NgSwitchCase, AsyncPipe } from '@angular/common';

@Component({
    selector: 'app-payment-modal',
    templateUrl: './payment-modal.component.html',
    styleUrls: ['./payment-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, SelectComponent, FormsModule, ReactiveFormsModule, NgSwitch, NgSwitchCase, FaIconComponent, CopyToClipboardDirective, LoadingDirective, LoaderComponent, AsyncPipe, PricePipe]
})
export class PaymentModalComponent extends Modal.Model<ModalInputs.Payment, Payment.Type | null>{
  inputs: ModalInputs.Payment;
  paymentOptions: Options.SelectModel[] = Payment.toOptions();
  readonly paymentControl = new FormControl<Options.SelectModel<Payment.Type> | null>(null, Validators.required);

  organiser: User.Model | null;
  readonly isLoading$ = new BehaviorSubject<boolean>(true);

  readonly PaymentType = Payment.Type;
  readonly ActionIcons = Icons.Actions;

  constructor(
    private readonly usersService: UsersService,
    private readonly notificationService: NotificationService,
    private readonly changeDetectorRef: ChangeDetectorRef,
  ) {
    super();
  }

  get canSubmit(): boolean {
    const paymentType = this.paymentControl.value?.id;
    return Boolean(paymentType && this.organiser?.payments[paymentType]);
  }

  override onInjectInputs(inputs: ModalInputs.Payment) {
    this.inputs = inputs;
    this.usersService.fetch(inputs.fixture.organiser)
      .then(organiser => {
        this.organiser = organiser;
        const preferredPayment = organiser?.payments.preferred;
        this.paymentControl.setValue(preferredPayment ? { id: preferredPayment } : null);
        this.changeDetectorRef.detectChanges();
        return;
      })
      .catch(errorResponse => {
        this.notificationService.error(errorResponse.message);
        return this.close(null);
      })
      .finally(() => this.isLoading$.next(false));

  }

  submit(): void {
    if (!this.canSubmit) {
      return;
    }
    this.close(this.paymentControl.value?.id);
  }
}
