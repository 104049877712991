import { Injectable } from '@angular/core';
import { collectionData, Firestore, getDocs } from '@angular/fire/firestore';

import { FirestoreService } from '@app/domain/firestore/firestore.service';
import { TeamsQueries } from '@app/domain/teams/services/teams.queries';
import { Team } from '@app/domain/teams/models/team.model';
import Model = Team.Model;

@Injectable({
  providedIn: 'root'
})
export class TeamsService extends FirestoreService<Model> {
  static readonly collectionId: string = 'teams';
  private readonly queries = new TeamsQueries(this.collectionRef);

  constructor(override firestore: Firestore) {
    super(TeamsService.collectionId, firestore);
  }

  async fetchAllForFixture(fixture: string) {
    const snapshots = await getDocs(this.queries.fixture({ fixture }));
    return snapshots.docs.map(snapshot => snapshot.data());
  }

  observeAllForFixture(fixture: string) {
    return collectionData(this.queries.fixture({ fixture }));
  }
}
