import { Component, ChangeDetectionStrategy, Input, HostBinding } from '@angular/core';
import { SectionsEnum } from '@app/config/sections.enum';
import { User } from '@app/domain/users/models/user.model';
import { Loader } from '@app/modules/shared/loader/models/loader.model';
import { defaultImageSize, ImageSize } from '@app/modules/shared/pipes/photo-url/photo-url.pipe';
import { DisplayNamePipe } from '../pipes/display-name/display-name.pipe';
import { PhotoUrlPipe } from '../pipes/photo-url/photo-url.pipe';
import { AbbreviationPipe } from '../pipes/abbreviation/abbreviation.pipe';
import { FlagComponent } from '../flag/flag.component';
import { ImagePreloadDirective } from '../directives/image-preload.directive';
import { RouterLink } from '@angular/router';
import { LoaderComponent } from '../loader/loader.component';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-avatar',
    templateUrl: './avatar.component.html',
    styleUrls: ['./avatar.component.scss'],
    host: {
        '[class.rounded]': 'rounded',
        '[class.circle]': 'circle'
    },
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, LoaderComponent, RouterLink, ImagePreloadDirective, FlagComponent, AbbreviationPipe, PhotoUrlPipe, DisplayNamePipe]
})
export class AvatarComponent {
  @Input() user: Partial<User.Model> | undefined;
  @Input() imageSize: ImageSize = defaultImageSize;
  @Input() rounded = true;
  @Input() circle = false;
  @Input() showFlag = true;
  @Input() group: string | null | undefined;

  readonly LoaderSizeEnum = Loader.Size;
  readonly SectionsEnum = SectionsEnum;
}
