import { Options } from '@app/domain/options/models/options.model';
import { Timestamp } from 'firebase/firestore';

import { DomainModel } from '@app/domain/models/domain.model';
import { Payment } from '@app/domain/models/payment.model';
import { Team } from '@app/domain/teams/models/team.model';

export namespace Player {
  export interface Model extends DomainModel {
    abilities: Player.Abilities;
    checkInTime: Timestamp | null;
    color: Team.Color | null;
    currency: string;
    fixture: string;
    group: string | null;
    hasMultiSport: boolean;
    injured: boolean;
    leg: Leg | null;
    organiser: boolean;
    overall: number;
    paid: number;
    paymentType: Payment.Type | null;
    position: Position | null;
    reserve: boolean;
    team: string | null;
    user: string;
  }

  export enum Position {
    Free = 'free',
    GoalKeeper = 'goalKeeper',
    Defense = 'defense',
    Midfield = 'midfield',
    Offense = 'offense',
  }

  export interface Positions {
    best: Position | null;
    [Player.Position.Offense]: number;
    [Player.Position.Defense]: number;
    [Player.Position.Midfield]: number;
    [Player.Position.GoalKeeper]: number;
  }

  export interface Abilities {
    overall: number;
    dribbling: number;
    shooting: number;
    passing: number;
    strength: number;
    defense: number;
    speed: number;
    goalKeeping: number;
  }

  export enum Leg {
    Left = 'L',
    Right = 'R',
    Both ='B'
  }

  export enum Class {
    S = 'S',
    A = 'A',
    B = 'B',
    C = 'C',
    D = 'D',
    X = 'X',
  }

  export const ClassThreshold: { [key in Class]: number } = {
    [Class.S]: 90,
    [Class.A]: 80,
    [Class.B]: 70,
    [Class.C]: 60,
    [Class.D]: 1,
    [Class.X]: 0,
  }

  export interface Threshold {
    min: number;
    max: number;
  }

  export const classAllId = 'all';

  export function classesToOptions(withAll?: boolean): Options.SelectModel<Class | string, Threshold>[] {
    const options = Object.keys(ClassThreshold).map((key, index, array) => ({
      id: key,
      name: key,
      data: {
        min: ClassThreshold[key],
        max: index > 0 ? ClassThreshold[array[index - 1]] : Number.MAX_VALUE
      }
    }));

    if (withAll) {
      options.unshift({
        id: classAllId,
        name: 'All',
        data: {
          min: Number.MIN_VALUE,
          max: Number.MAX_VALUE,
        }
      })
    }

    return options;
  }
}
