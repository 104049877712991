import { Component, OnInit } from '@angular/core';
import { FaConfig, FaIconLibrary, FontAwesomeModule } from "@fortawesome/angular-fontawesome"

import { AppUpdateService } from '@app/services/app-update.service';
import { RouterOutlet } from '@angular/router';
import { NotificationComponent } from './modules/shared/notification/notification.component';
import { fas } from "@fortawesome/free-solid-svg-icons"
import { fab } from "@fortawesome/free-brands-svg-icons"
import { far } from "@fortawesome/free-regular-svg-icons"

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [FontAwesomeModule, NotificationComponent, RouterOutlet]
})
export class AppComponent implements OnInit {
  constructor(
    private readonly library: FaIconLibrary,
    private readonly faConfig: FaConfig,
    private readonly appUpdateService: AppUpdateService,
  ) {
    library.addIconPacks(fas, fab, far);
    faConfig.fixedWidth = true;
  }

  ngOnInit(): void {
    this.appUpdateService.checkAvailableUpdate();
  }
}
