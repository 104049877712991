export enum SectionsEnum {
  Admin = 'admin',
  Core = 'core',
  Edit = 'edit',
  Eula = 'eula',
  Fixtures = 'fixtures',
  Groups = 'groups',
  Info = 'info',
  Invitations = 'invitations',
  Login = 'login',
  Logout = 'logout',
  Members = 'members',
  Messages = 'messages',
  New = 'new',
  Pitches = 'pitches',
  Profile = 'profile',
  Register = 'register',
  Settings = 'settings',
  Users = 'users',
  Teams = 'teams',
  Venues = 'venues',
}
