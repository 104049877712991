import { Pipe, PipeTransform } from '@angular/core';
import { PlayersService } from '@app/domain/players/services/players.service';
import { Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { Fixture } from "@app/domain/fixtures/models/fixture.model"

@Pipe({
    name: 'playerCount',
    standalone: true
})
export class PlayerCountPipe implements PipeTransform {
  constructor(private readonly playersService: PlayersService) {}

  transform(fixture: string): Observable<number> {
    if (!fixture) {
      return of(0).pipe(take(1));
    }
    return this.playersService.observeCountForFixture(fixture);
  }
}

@Pipe({
    name: 'playerCountStatus',
    standalone: true
})
export class PlayerCountStatusPipe implements PipeTransform {
  constructor(private readonly playersService: PlayersService) {}

  transform(playerCount: number, fixture: Fixture.Model): Fixture.PlayerCountStatus {
    if (!fixture || !playerCount || isNaN(playerCount)) {
      return Fixture.PlayerCountStatus.Uncounted;
    }
    const { maxTeams, playersPerTeam } = fixture;
    const maxPlayers = playersPerTeam * maxTeams;
    const reserves = playerCount - maxPlayers < 0 ? 0 : playerCount - maxPlayers;
    if (playerCount === maxPlayers && (reserves === 0 || reserves % maxTeams === 0)) {
      return Fixture.PlayerCountStatus.Filled;
    }
    if (playerCount > maxPlayers) {
      return Fixture.PlayerCountStatus.OverBooked;
    }
    return Fixture.PlayerCountStatus.UnderBooked;
  }
}
