import { Injectable } from '@angular/core';
import {
  Auth,
  signOut,
  updateProfile,
  RecaptchaVerifier,
  user,
  User as AuthUser,
  ConfirmationResult,
  signInWithPhoneNumber,
} from '@angular/fire/auth';
import { firstValueFrom } from 'rxjs';

import { FunctionsService } from '@app/domain/functions/functions.service';
import { Authorisation } from '@app/domain/auth/models/auth.model';
import { User } from '@app/domain/users/models/user.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  confirmationResult: ConfirmationResult | null;
  applicationVerifier: RecaptchaVerifier;

  constructor(
    private readonly auth: Auth,
    private readonly functionsService: FunctionsService,
  ) {}

  async authUser$() {
    return firstValueFrom(user(this.auth));
  }

  async validateRegistration(formValue: Authorisation.Register) {
    try {
      const validation = await this.functionsService.call<Authorisation.Register, boolean>('validationForRegistration', formValue);
      return validation;
    } catch (error) {
      return false;
    }
  }

  async validateLogin(formValue: Authorisation.Login){
    try {
      const validation = await this.functionsService.call<Authorisation.Login, boolean>('validationForLogin', formValue);
      return validation;
    } catch (error) {
      return false;
    }
  }

  async signInWithPhoneNumber(phoneNumber: string) {
    this.confirmationResult = null;
    if (!this.applicationVerifier) {
      this.applicationVerifier = new RecaptchaVerifier(
        this.auth,
        'recaptcha-container',
        {
          size: 'invisible',
        },
      );
    }
    this.confirmationResult = await signInWithPhoneNumber(this.auth, phoneNumber, this.applicationVerifier);
    return this.confirmationResult;
  }

  async getCredentialsWithConfirmationCode(confirmationCode: string) {
    return this.confirmationResult?.confirm(confirmationCode) || null;
  }

  async signOut() {
    return signOut(this.auth);
  }

  async updateAuthUserProfile(authUser: Partial<User.Model>) {
    return updateProfile(this.auth.currentUser as AuthUser, {
      displayName: authUser.displayName,
      photoURL: authUser.photoUrl
    });
  }
}
