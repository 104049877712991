import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'optionSelected',
    standalone: true
})
export class OptionSelectedPipe<T> implements PipeTransform {
  transform(options: T[] | null, selectedOption: T): boolean {
    return options?.some(option => selectedOption['id'] && option['id']
      ? option['id'] === selectedOption['id']
      : option === selectedOption
    ) || false;
  }
}
