<h2 class="header">Select a new position for {{ inputs.player.user | userData | async | displayName }}</h2>

<div class="content">
  <app-pitch>
    <div class="positions">
      <div *ngFor="let position of positionOptions; trackBy: trackByIndex" class="sector" [class]="position">
        <button
          type="button"
          class="btn--lg btn--link"
          [class.active]="selectedPosition === position"
          (click)="selectPosition(position)"
        >
          <fa-icon *ngIf="selectedPosition !== position" icon="arrow-right" class="icon-prefix"></fa-icon>
          <app-player-position-badge
            [position]="position"
            [small]="selectedPosition !== position"
          ></app-player-position-badge>
          <app-player-class-badge
            [abilities]="inputs.player.abilities"
            [position]="position"
            [small]="selectedPosition !== position"
            [inverted]="selectedPosition === position"
          ></app-player-class-badge>
          <fa-icon *ngIf="selectedPosition !== position" icon="arrow-left" class="icon-suffix"></fa-icon>
        </button>
      </div>
    </div>
  </app-pitch>
</div>

<div class="actions">
  <button type="button" class="btn--lg btn--error" (click)="close(null)">
    <fa-icon class="icon-prefix" [icon]="ActionIcons.Cancel"></fa-icon>
    Cancel
  </button>

  <button type="button" class="btn--lg" [disabled]="!selectedPosition" (click)="submit()">
    <fa-icon class="icon-prefix" [icon]="ActionIcons.Submit"></fa-icon>
    Save
  </button>
</div>
