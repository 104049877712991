import {Directive, Input, HostBinding} from '@angular/core';

@Directive({
    // tslint:disable-next-line:directive-selector
    selector: 'img[default]',
    // tslint:disable-next-line:no-host-metadata-property
    host: {
        '(error)': 'updateUrl()',
        '(load)': 'load()',
        '[attr.src]': '_src'
    },
    standalone: true,
})
export class ImagePreloadDirective {
  @Input() set src(value: string | null | undefined) {
    this._src = value || this.default || '';
  }
  @Input() default: string;
  @HostBinding('class') className: string;

  _src: string | null | undefined

  updateUrl(): void {
    this._src = this.default;
  }

  load(): void {
    this.className = 'image-loaded';
  }
}
