export enum ActionsEnum {
  Activate = 'Activate',
  Add = 'Add',
  Alert = 'Alert',
  Back = 'Back',
  Ban = 'Ban',
  Cancel = 'Cancel',
  Center = 'Center',
  Clear = 'Clear',
  Close = 'Close',
  Copy = 'Copy',
  Date = 'Date',
  Deactivate = 'Deactivate',
  Disable = 'Disable',
  Dislike = 'Dislike',
  Directions = 'Directions',
  Delete = 'Delete',
  Down = 'Down',
  Edit = 'Edit',
  Error = 'Error',
  Follow = 'Follow',
  Global = 'Global',
  Go = 'Go',
  Goal = 'Goal',
  Hide = 'Hide',
  Info = 'Info',
  Injured = 'Injured',
  Invite = 'Invite',
  Join = 'Join',
  Leave = 'Leave',
  Link = 'Link',
  Like = 'Like',
  ListClose = 'List close',
  ListOpen = 'List open',
  LogIn = 'Log in',
  LogOut = 'Log out',
  Menu = 'Menu',
  Messages = 'Messages',
  Minus = 'Minus',
  Multiply = 'Multiply',
  Ok = 'Ok',
  Pay = 'Pay',
  Play = 'Play',
  Question = 'Question',
  Refresh = 'Refresh',
  Save = 'Save',
  Search = 'Search',
  Show = 'Show',
  Sort = 'Sort',
  Start = 'Start',
  Submit = 'Submit',
  Success = 'Success',
  Team = 'Team',
  Time = 'Time',
  Unable = 'Unable',
  Unpaid = 'Unpaid',
  Up = 'Up',
  Upload = 'Upload',
  Wait = 'Wait',
  Warning = 'Warning',
  ZoomIn = 'Zoom in',
  ZoomOut = 'Zoom out'
}
