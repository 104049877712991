<label
  *ngIf="label || icon" [for]="id + '-date'"
  class="input__label"
  [class.required]="isRequired"
>
  <fa-icon *ngIf="icon" class="icon-prefix" [icon]="icon"></fa-icon>
  {{ label }}
</label>
<div class="input__container">
  <input
    #dateInput
    [id]="id + '-date'"
    [attr.name]="id + '-date'"
    [type]="InputTypeEnum.Date"
    [value]="date"
    autocomplete="off"
    [placeholder]="datePlaceholder"
    class="input__element date"
    [class.filled]="date"
    [disabled]="disabled"
    [readOnly]="readonly"
    [attr.min]="min"
    (input)="writeDate(dateInput.value)"
  />
  <input
    #timeInput
    [id]="id + '-time'"
    [attr.name]="id + '-time'"
    [type]="InputTypeEnum.Time"
    [value]="time"
    autocomplete="off"
    [placeholder]="timePlaceholder"
    class="input__element time"
    [class.filled]="time"
    [disabled]="disabled || !date"
    [readOnly]="readonly"
    (input)="writeTime(timeInput.value)"
  />
</div>
