export const rapidApiConfig = {
  apiKey: '60a590fb79msha7ced782b61d732p1ad960jsnef01e0c60554',
  apiUrl: 'https://weatherbit-v1-mashape.p.rapidapi.com',
  host: 'weatherbit-v1-mashape.p.rapidapi.com',
};

export const rapidApiHeaders = {
  'x-rapidapi-host': rapidApiConfig.host,
  'x-rapidapi-key': rapidApiConfig.apiKey
};
