import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';

import { AbbreviationPipe } from './abbreviation/abbreviation.pipe';
import { PhotoUrlPipe } from './photo-url/photo-url.pipe';
import { FlagPipe } from './flag/flag.pipe';
import { SafeHtmlPipe } from './safe-html/safe-html.pipe';
import { ReferenceChangesPipe, ReferenceDataPipe, ReferencePipe } from './reference/reference.pipe';
import { AwardChangesPipe, AwardDataPipe } from './reference/award-reference.pipe';
import { FixtureChangesPipe, FixtureDataPipe } from './reference/fixture-reference.pipe';
import {
  GroupChangesPipe,
  GroupDataPipe, GroupFixturesCountPipe,
  GroupMembersCountPipe
} from './reference/group-reference.pipe';
import { UserChangesPipe, UserDataPipe } from './reference/user-reference.pipe';
import {
  PitchChangesPipe,
  PitchDataPipe,
  VenueChangesPipe,
  VenueDataPipe,
  VenueFixturesCountPipe
} from './reference/venue-reference.pipe';
import { DisplayNamePipe } from './display-name/display-name.pipe';
import { PlayerCountPipe, PlayerCountStatusPipe } from './players/player-count.pipe';
import { PlayerClassPipe } from './players/player-class.pipe';
import { PlayerScorePipe } from './players/player-score.pipe';
import { PlayerPositionPipe } from './players/player-position.pipe';
import {
  FixtureEditablePipe,
  FixturePlayersEditablePipe,
  FixturePositionsEditablePipe,
  FixtureTeamsEditablePipe,
  FixturePayablePipe
} from './fixtures/fixture-actions.pipe';
import { TeamClassPipe } from './teams/team-class.pipe';
import { TeamOverallPipe } from './teams/team-overall.pipe';
import { TeamAbilitiesPipe } from './teams/team-abilities.pipe';
import { PricePerPlayerPipe, PriceAfterDiscountPipe, PricePipe } from './price/price.pipe';
import { ForecastPipe } from './forecast/forecast.pipe';
import { PlaytimePipe } from './playtime/playtime.pipe';
import { PitchCoverPipe } from './pitch/pitch-cover.pipe';
import { PitchFlooringPipe } from './pitch/pitch-flooring.pipe';
import { PitchPricePipe } from './pitch/pitch-price.pipe';
import { PitchSizePipe } from './pitch/pitch-size.pipe';

@NgModule({
    imports: [CommonModule, AbbreviationPipe,
        PhotoUrlPipe,
        FlagPipe,
        SafeHtmlPipe,
        ReferencePipe,
        ReferenceDataPipe,
        ReferenceChangesPipe,
        AwardDataPipe,
        AwardChangesPipe,
        GroupDataPipe,
        GroupChangesPipe,
        GroupMembersCountPipe,
        GroupFixturesCountPipe,
        UserDataPipe,
        UserChangesPipe,
        FixtureDataPipe,
        FixtureChangesPipe,
        VenueDataPipe,
        VenueChangesPipe,
        VenueFixturesCountPipe,
        PitchDataPipe,
        PitchChangesPipe,
        DisplayNamePipe,
        PlayerCountPipe,
        PlayerCountStatusPipe,
        PlayerClassPipe,
        PlayerScorePipe,
        PlayerPositionPipe,
        FixtureEditablePipe,
        FixtureTeamsEditablePipe,
        FixturePositionsEditablePipe,
        FixturePlayersEditablePipe,
        FixturePayablePipe,
        TeamClassPipe,
        TeamOverallPipe,
        TeamAbilitiesPipe,
        PricePipe,
        PricePerPlayerPipe,
        PriceAfterDiscountPipe,
        ForecastPipe,
        PlaytimePipe,
        PitchCoverPipe,
        PitchFlooringPipe,
        PitchSizePipe,
        PitchPricePipe],
    providers: [
        CurrencyPipe,
        PlayerScorePipe,
        PlayerClassPipe,
        PricePipe
    ],
    exports: [
        AbbreviationPipe,
        PhotoUrlPipe,
        FlagPipe,
        SafeHtmlPipe,
        ReferencePipe,
        ReferenceDataPipe,
        ReferenceChangesPipe,
        AwardDataPipe,
        AwardChangesPipe,
        GroupDataPipe,
        GroupChangesPipe,
        GroupMembersCountPipe,
        GroupFixturesCountPipe,
        UserDataPipe,
        UserChangesPipe,
        FixtureDataPipe,
        FixtureChangesPipe,
        VenueDataPipe,
        VenueChangesPipe,
        VenueFixturesCountPipe,
        PitchDataPipe,
        PitchChangesPipe,
        DisplayNamePipe,
        PlayerCountPipe,
        PlayerCountStatusPipe,
        PlayerClassPipe,
        PlayerScorePipe,
        PlayerPositionPipe,
        FixtureEditablePipe,
        FixtureTeamsEditablePipe,
        FixturePositionsEditablePipe,
        FixturePlayersEditablePipe,
        FixturePayablePipe,
        TeamClassPipe,
        TeamOverallPipe,
        TeamAbilitiesPipe,
        PricePipe,
        PricePerPlayerPipe,
        PriceAfterDiscountPipe,
        ForecastPipe,
        PlaytimePipe,
        PitchCoverPipe,
        PitchFlooringPipe,
        PitchSizePipe,
        PitchPricePipe,
    ]
})
export class PipesModule {}
