import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { PlayerClassBadgeModule } from '@app/modules/shared/badges/player-class-badge/player-class-badge.module';
import {
  PlayerPositionBadgeModule
} from '@app/modules/shared/badges/player-position-badge/player-position-badge.module';


import { PaymentInfoModule } from '@app/modules/shared/payment-info/payment-info.module';
import { PipesModule } from '@app/modules/shared/pipes/pipes.module';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { FormModule } from '@app/modules/shared/form/form.module';

import { ModalService } from '@app/modules/shared/modal/services/modal.service';
import { ModalComponent } from './modal.component';
import {
  ConfirmationModalComponent
} from '@app/modules/shared/modal/components/confirmation-modal/confirmation-modal.component';
import {
  PositionSelectModalComponent
} from '@app/modules/shared/modal/components/position-select-modal/position-select-modal.component';
import { TeamSelectModalComponent } from './components/team-select-modal/team-select-modal.component';
import { PaymentModalComponent } from '@app/modules/shared/modal/components/payment-modal/payment-modal.component';
import { NewUpdateModalComponent } from '@app/modules/shared/modal/components/new-update-modal/new-update-modal.component';
import {
  PhoneNumberConfirmationModalComponent
} from '@app/modules/shared/modal/components/phone-number-confirmation-modal/phone-number-confirmation-modal.component';
import { PaymentInfoModalComponent } from './components/payment-info-modal/payment-info-modal.component';

@NgModule({
    imports: [CommonModule, FontAwesomeModule, FormModule, ReactiveFormsModule, PipesModule, PaymentInfoModule, PlayerPositionBadgeModule, PlayerClassBadgeModule, ModalComponent,
    ConfirmationModalComponent,
    NewUpdateModalComponent,
    TeamSelectModalComponent,
    PositionSelectModalComponent,
    PaymentModalComponent,
    PhoneNumberConfirmationModalComponent,
    PaymentInfoModalComponent]
})
export class ModalModule {
  static forRoot(): ModuleWithProviders<ModalModule> {
    return {
      ngModule: ModalModule,
      providers: [ModalService]
    };
  }
}
