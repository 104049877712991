import { Pipe, PipeTransform } from '@angular/core';
import { Statistics } from '@app/config/statistics';
import { Player } from '@app/domain/players/models/player.model';
import { PlayerScorePipe } from '@app/modules/shared/pipes/players/player-score.pipe';

@Pipe({
    name: 'teamOverall',
    standalone: true,
})
export class TeamOverallPipe implements PipeTransform {
  constructor(private readonly playerScorePipe: PlayerScorePipe) {}

  transform(players: Player.Model[]): number {
    const overall = (players || [])
      .map(({ abilities, overall, position}) =>
        this.playerScorePipe.transform(abilities, position, overall)
      )
      .reduce((total, next) => total + next, 0);
    return Math.round(overall / (players.length || 1));
  }
}
