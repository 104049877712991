import { Injectable } from '@angular/core';
import { Functions, httpsCallable } from '@angular/fire/functions';

import { NotificationService } from '@app/modules/shared/notification/services/notification.service';

@Injectable({
  providedIn: 'root'
})
export class FunctionsService {
  constructor(
    private readonly functions: Functions,
    private readonly notificationService: NotificationService
  ) { }

  async call<Data = any, Result = void>(functionName: string, data: Data) {
    const callable = httpsCallable<Data, Result>(this.functions, functionName);
    try {
      const response = await callable(data);
      return response.data;
    } catch (error: any) {
      this.notificationService.error(error.message);
      throw error;
    }
  }
}
