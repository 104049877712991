import { Options } from '@app/domain/options/models/options.model';

export namespace StaticCountries {
  export interface Model {
    id: number;
    name: string;
    shortName?: string;
    photoUrl: string;
  }
}

export const defaultCountry: Options.SelectModel = {
  id: 'UNA',
  name: 'United Nations',
  photoUrl: '/assets/images/flags/square/united-nations.svg',
};

export const staticCountries: Options.SelectModel[] = [
  {
    id: 'AFG',
    name: 'Afghanistan',
    photoUrl: '/assets/images/flags/square/afghanistan.svg',
  }, {
    id: 'ALB',
    name: 'Albania',
    photoUrl: '/assets/images/flags/square/albania.svg',
  }, {
    id: 'ALG',
    name: 'Algeria',
    photoUrl: '/assets/images/flags/square/algeria.svg',
  }, {
    id: 'ASA',
    name: 'American Samoa',
    photoUrl: '/assets/images/flags/square/american-samoa.svg',
  }, {
    id: 'AND',
    name: 'Andorra',
    photoUrl: '/assets/images/flags/square/andorra.svg',
  }, {
    id: 'ANG',
    name: 'Angola',
    photoUrl: '/assets/images/flags/square/angola.svg',
  }, {
    id: 'AIA',
    name: 'Anguilla',
    photoUrl: '/assets/images/flags/square/anguilla.svg',
  }, {
    id: 'ATG',
    name: 'Antigua and Barbuda',
    photoUrl: '/assets/images/flags/square/antigua-and-barbuda.svg',
  }, {
    id: 'ARG',
    name: 'Argentina',
    photoUrl: '/assets/images/flags/square/argentina.svg',
  }, {
    id: 'ARM',
    name: 'Armenia',
    photoUrl: '/assets/images/flags/square/armenia.svg',
  }, {
    id: 'ARU',
    name: 'Aruba',
    photoUrl: '/assets/images/flags/square/aruba.svg',
  }, {
    id: 'AUS',
    name: 'Australia',
    photoUrl: '/assets/images/flags/square/australia.svg',
  }, {
    id: 'AUT',
    name: 'Austria',
    photoUrl: '/assets/images/flags/square/austria.svg',
  }, {
    id: 'AZE',
    name: 'Azerbaijan',
    photoUrl: '/assets/images/flags/square/azerbaijan.svg',
  }, {
    id: 'BAH',
    name: 'Bahamas',
    photoUrl: '/assets/images/flags/square/bahamas.svg',
  }, {
    id: 'BHR',
    name: 'Bahrain',
    photoUrl: '/assets/images/flags/square/bahrain.svg',
  }, {
    id: 'BAN',
    name: 'Bangladesh',
    photoUrl: '/assets/images/flags/square/bangladesh.svg',
  }, {
    id: 'BRB',
    name: 'Barbados',
    photoUrl: '/assets/images/flags/square/barbados.svg',
  }, {
    id: 'BSQ',
    name: 'Basque Country',
    photoUrl: '/assets/images/flags/square/basque-country.svg',
  }, {
    id: 'BLR',
    name: 'Belarus',
    photoUrl: '/assets/images/flags/square/belarus.svg',
  }, {
    id: 'BEL',
    name: 'Belgium',
    photoUrl: '/assets/images/flags/square/belgium.svg',
  }, {
    id: 'BLZ',
    name: 'Belize',
    photoUrl: '/assets/images/flags/square/belize.svg',
  }, {
    id: 'BEN',
    name: 'Benin',
    photoUrl: '/assets/images/flags/square/benin.svg',
  }, {
    id: 'BER',
    name: 'Bermuda',
    photoUrl: '/assets/images/flags/square/bermuda.svg',
  }, {
    id: 'BHU',
    name: 'Bhutan',
    photoUrl: '/assets/images/flags/square/bhutan.svg',
  }, {
    id: 'BOL',
    name: 'Bolivia',
    photoUrl: '/assets/images/flags/square/bolivia.svg',
  }, {
    id: 'BIH',
    name: 'Bosnia and Herzegovina',
    photoUrl: '/assets/images/flags/square/bosnia-and-herzegovina.svg',
  }, {
    id: 'BOT',
    name: 'Botswana',
    photoUrl: '/assets/images/flags/square/botswana.svg',
  }, {
    id: 'BRA',
    name: 'Brazil',
    photoUrl: '/assets/images/flags/square/brazil.svg',
  }, {
    id: 'VGB',
    name: 'British Virgin Islands',
    photoUrl: '/assets/images/flags/square/british-virgin-islands.svg',
  }, {
    id: 'BRU',
    name: 'Brunei',
    photoUrl: '/assets/images/flags/square/brunei.svg',
  }, {
    id: 'BUL',
    name: 'Bulgaria',
    photoUrl: '/assets/images/flags/square/bulgaria.svg',
  }, {
    id: 'BFA',
    name: 'Burkina Faso',
    photoUrl: '/assets/images/flags/square/burkina-faso.svg',
  }, {
    id: 'BDI',
    name: 'Burundi',
    photoUrl: '/assets/images/flags/square/burundi.svg',
  }, {
    id: 'CAM',
    name: 'Cambodia',
    photoUrl: '/assets/images/flags/square/cambodia.svg',
  }, {
    id: 'CMR',
    name: 'Cameroon',
    photoUrl: '/assets/images/flags/square/cameroon.svg',
  }, {
    id: 'CAN',
    name: 'Canada',
    photoUrl: '/assets/images/flags/square/canada.svg',
  }, {
    id: 'CPV',
    name: 'Cape Verde',
    photoUrl: '/assets/images/flags/square/cape-verde.svg',
  }, {
    id: 'CAY',
    name: 'Cayman Islands',
    photoUrl: '/assets/images/flags/square/cayman-islands.svg',
  }, {
    id: 'CTA',
    name: 'Central African Republic',
    photoUrl: '/assets/images/flags/square/central-african-republic.svg',
  }, {
    id: 'CHA',
    name: 'Chad',
    photoUrl: '/assets/images/flags/square/chad.svg',
  }, {
    id: 'CHI',
    name: 'Chile',
    photoUrl: '/assets/images/flags/square/chile.svg',
  }, {
    id: 'CHN',
    name: 'China PR',
    photoUrl: '/assets/images/flags/square/china.svg',
  }, {
    id: 'COL',
    name: 'Colombia',
    photoUrl: '/assets/images/flags/square/colombia.svg',
  }, {
    id: 'COM',
    name: 'Comoros',
    photoUrl: '/assets/images/flags/square/comoros.svg',
  }, {
    id: 'CGO',
    name: 'Congo',
    photoUrl: '/assets/images/flags/square/united-nations.svg',
  }, {
    id: 'COK',
    name: 'Cook Islands',
    photoUrl: '/assets/images/flags/square/cook-islands.svg',
  }, {
    id: 'CRO',
    name: 'Costa Rica',
    photoUrl: '/assets/images/flags/square/costa-rica.svg',
  }, {
    id: 'CRO',
    name: 'Croatia',
    photoUrl: '/assets/images/flags/square/croatia.svg',
  }, {
    id: 'CUB',
    name: 'Cuba',
    photoUrl: '/assets/images/flags/square/cuba.svg',
  }, {
    id: 'CUW',
    name: 'Curaçao',
    photoUrl: '/assets/images/flags/square/curacao.svg',
  }, {
    id: 'CYP',
    name: 'Cyprus',
    photoUrl: '/assets/images/flags/square/cyprus.svg',
  }, {
    id: 'CZE',
    name: 'Czech Republic',
    photoUrl: '/assets/images/flags/square/czech-republic.svg',
  }, {
    id: 'COD',
    name: 'Democratic Republic of Congo',
    photoUrl: '/assets/images/flags/square/denmark.svg',
  }, {
    id: 'DEN',
    name: 'Denmark',
    photoUrl: '/assets/images/flags/square/denmark.svg',
  }, {
    id: 'DJI',
    name: 'Djibouti',
    photoUrl: '/assets/images/flags/square/djibouti.svg',
  }, {
    id: 'DMA',
    name: 'Dominica',
    photoUrl: '/assets/images/flags/square/dominica.svg',
  }, {
    id: 'DOM',
    name: 'Dominican Republic',
    photoUrl: '/assets/images/flags/square/dominican-republic.svg',
  }, {
    id: 'ECU',
    name: 'Ecuador',
    photoUrl: '/assets/images/flags/square/ecuador.svg',
  }, {
    id: 'EGY',
    name: 'Egypt',
    photoUrl: '/assets/images/flags/square/egypt.svg',
  }, {
    id: 'SLV',
    name: 'El Salvador',
    photoUrl: '/assets/images/flags/square/el-salvador.svg',
  }, {
    id: 'ENG',
    name: 'England',
    photoUrl: '/assets/images/flags/square/england.svg',
  }, {
    id: 'EQG',
    name: 'Equatorial Guinea',
    photoUrl: '/assets/images/flags/square/equatorial-guinea.svg',
  }, {
    id: 'ERI',
    name: 'Eritrea',
    photoUrl: '/assets/images/flags/square/eritrea.svg',
  }, {
    id: 'EST',
    name: 'Estonia',
    photoUrl: '/assets/images/flags/square/estonia.svg',
  }, {
    id: 'ETH',
    name: 'Ethiopia',
    photoUrl: '/assets/images/flags/square/ethiopia.svg',
  }, {
    id: 'EUR',
    name: 'European Union',
    photoUrl: '/assets/images/flags/square/european-union.svg',
  }, {
    id: 'FRO',
    name: 'Faroe Islands',
    photoUrl: '/assets/images/flags/square/faroe-islands.svg',
  }, {
    id: 'FIJ',
    name: 'Fiji',
    photoUrl: '/assets/images/flags/square/fiji.svg',
  }, {
    id: 'FIN',
    name: 'Finland',
    photoUrl: '/assets/images/flags/square/finland.svg',
  }, {
    id: 'FRA',
    name: 'France',
    photoUrl: '/assets/images/flags/square/france.svg',
  }, {
    id: 'GAB',
    name: 'Gabon',
    photoUrl: '/assets/images/flags/square/gabon.svg',
  }, {
    id: 'GAM',
    name: 'Gambia',
    photoUrl: '/assets/images/flags/square/gambia.svg',
  }, {
    id: 'GEO',
    name: 'Georgia',
    photoUrl: '/assets/images/flags/square/georgia.svg',
  }, {
    id: 'GER',
    name: 'Germany',
    photoUrl: '/assets/images/flags/square/germany.svg',
  }, {
    id: 'GHA',
    name: 'Ghana',
    photoUrl: '/assets/images/flags/square/ghana.svg',
  }, {
    id: 'GIB',
    name: 'Gibraltar',
    photoUrl: '/assets/images/flags/square/gibraltar.svg',
  }, {
    id: 'GRE',
    name: 'Greece',
    photoUrl: '/assets/images/flags/square/greece.svg',
  }, {
    id: 'GRN',
    name: 'Grenada',
    photoUrl: '/assets/images/flags/square/grenada.svg',
  }, {
    id: 'GUM',
    name: 'Guam',
    photoUrl: '/assets/images/flags/square/guam.svg',
  }, {
    id: 'GUA',
    name: 'Guatemala',
    photoUrl: '/assets/images/flags/square/guatemala.svg',
  }, {
    id: 'GUI',
    name: 'Guinea',
    photoUrl: '/assets/images/flags/square/guinea.svg',
  }, {
    id: 'GNB',
    name: 'Guinea-Bissau',
    photoUrl: '/assets/images/flags/square/guinea-bissau.svg',
  }, {
    id: 'GUY',
    name: 'Guyana',
    photoUrl: '/assets/images/flags/square/united-nations.svg',
  }, {
    id: 'HAI',
    name: 'Haiti',
    photoUrl: '/assets/images/flags/square/haiti.svg',
  }, {
    id: 'HON',
    name: 'Honduras',
    photoUrl: '/assets/images/flags/square/honduras.svg',
  }, {
    id: 'HKG',
    name: 'Hong Kong',
    photoUrl: '/assets/images/flags/square/hong-kong.svg',
  }, {
    id: 'HUN',
    name: 'Hungary',
    photoUrl: '/assets/images/flags/square/hungary.svg',
  }, {
    id: 'ISL',
    name: 'Iceland',
    photoUrl: '/assets/images/flags/square/iceland.svg',
  }, {
    id: 'IND',
    name: 'India',
    photoUrl: '/assets/images/flags/square/india.svg',
  }, {
    id: 'IDN',
    name: 'Indonesia',
    photoUrl: '/assets/images/flags/square/indonesia.svg',
  }, {
    id: 'IRN',
    name: 'Iran',
    photoUrl: '/assets/images/flags/square/iran.svg',
  }, {
    id: 'IRQ',
    name: 'Iraq',
    photoUrl: '/assets/images/flags/square/iraq.svg',
  }, {
    id: 'IRL',
    name: 'Ireland RP',
    photoUrl: '/assets/images/flags/square/ireland.svg',
  }, {
    id: 'ISR',
    name: 'Israel',
    photoUrl: '/assets/images/flags/square/israel.svg',
  }, {
    id: 'ITA',
    name: 'Italy',
    photoUrl: '/assets/images/flags/square/italy.svg',
  }, {
    id: 'CIV',
    name: 'Ivory Coast',
    photoUrl: '/assets/images/flags/square/ivory-coast.svg',
  }, {
    id: 'JAM',
    name: 'Jamaica',
    photoUrl: '/assets/images/flags/square/jamaica.svg',
  }, {
    id: 'JPN',
    name: 'Japan',
    photoUrl: '/assets/images/flags/square/japan.svg',
  }, {
    id: 'JOR',
    name: 'Jordan',
    photoUrl: '/assets/images/flags/square/jordan.svg',
  }, {
    id: 'KAZ',
    name: 'Kazakhstan',
    photoUrl: '/assets/images/flags/square/kazakhstan.svg',
  }, {
    id: 'KEN',
    name: 'Kenya',
    photoUrl: '/assets/images/flags/square/kenya.svg',
  }, {
    id: 'KVX',
    name: 'Kosovo',
    photoUrl: '/assets/images/flags/square/kosovo.svg',
  }, {
    id: 'KUW',
    name: 'Kuwait',
    photoUrl: '/assets/images/flags/square/kuwait.svg',
  }, {
    id: 'KGZ',
    name: 'Kyrgyzstan',
    photoUrl: '/assets/images/flags/square/kyrgyzstan.svg',
  }, {
    id: 'LAO',
    name: 'Laos',
    photoUrl: '/assets/images/flags/square/laos.svg',
  }, {
    id: 'LVA',
    name: 'Latvia',
    photoUrl: '/assets/images/flags/square/latvia.svg',
  }, {
    id: 'LBN',
    name: 'Lebanon',
    photoUrl: '/assets/images/flags/square/lebanon.svg',
  }, {
    id: 'LES',
    name: 'Lesotho',
    photoUrl: '/assets/images/flags/square/lesotho.svg',
  }, {
    id: 'LBR',
    name: 'Liberia',
    photoUrl: '/assets/images/flags/square/liberia.svg',
  }, {
    id: 'LBY',
    name: 'Libya',
    photoUrl: '/assets/images/flags/square/libya.svg',
  }, {
    id: 'LIE',
    name: 'Liechtenstein',
    photoUrl: '/assets/images/flags/square/liechtenstein.svg',
  }, {
    id: 'LTU',
    name: 'Lithuania',
    photoUrl: '/assets/images/flags/square/lithuania.svg',
  }, {
    id: 'LUX',
    name: 'Luxembourg',
    photoUrl: '/assets/images/flags/square/luxembourg.svg',
  }, {
    id: 'MAC',
    name: 'Macau',
    photoUrl: '/assets/images/flags/square/macau.svg',
  }, {
    id: 'MAD',
    name: 'Madagascar',
    photoUrl: '/assets/images/flags/square/madagascar.svg',
  }, {
    id: 'MWI',
    name: 'Malawi',
    photoUrl: '/assets/images/flags/square/malawi.svg',
  }, {
    id: 'MAS',
    name: 'Malaysia',
    photoUrl: '/assets/images/flags/square/malaysia.svg',
  }, {
    id: 'MDV',
    name: 'Maldives',
    photoUrl: '/assets/images/flags/square/maldives.svg',
  }, {
    id: 'MLI',
    name: 'Mali',
    photoUrl: '/assets/images/flags/square/mali.svg',
  }, {
    id: 'MLT',
    name: 'Malta',
    photoUrl: '/assets/images/flags/square/malta.svg',
  }, {
    id: 'MTN',
    name: 'Mauritania',
    photoUrl: '/assets/images/flags/square/mauritania.svg',
  }, {
    id: 'MRI',
    name: 'Mauritius',
    photoUrl: '/assets/images/flags/square/mauritius.svg',
  }, {
    id: 'MEX',
    name: 'Mexico',
    photoUrl: '/assets/images/flags/square/mexico.svg',
  }, {
    id: 'MDA',
    name: 'Moldova',
    photoUrl: '/assets/images/flags/square/moldova.svg',
  }, {
    id: 'MOC',
    name: 'Monaco',
    photoUrl: '/assets/images/flags/square/monaco.svg',
  }, {
    id: 'MNG',
    name: 'Mongolia',
    photoUrl: '/assets/images/flags/square/mongolia.svg',
  }, {
    id: 'MNE',
    name: 'Montenegro',
    photoUrl: '/assets/images/flags/square/montenegro.svg',
  }, {
    id: 'MSR',
    name: 'Montserrat',
    photoUrl: '/assets/images/flags/square/montserrat.svg',
  }, {
    id: 'MAR',
    name: 'Morocco',
    photoUrl: '/assets/images/flags/square/morocco.svg',
  }, {
    id: 'MOZ',
    name: 'Mozambique',
    photoUrl: '/assets/images/flags/square/mozambique.svg',
  }, {
    id: 'MYA',
    name: 'Myanmar',
    photoUrl: '/assets/images/flags/square/myanmar.svg',
  }, {
    id: 'NAM',
    name: 'Namibia',
    photoUrl: '/assets/images/flags/square/namibia.svg',
  }, {
    id: 'NEP',
    name: 'Nepal',
    photoUrl: '/assets/images/flags/square/nepal.svg',
  }, {
    id: 'NED',
    name: 'Netherlands',
    photoUrl: '/assets/images/flags/square/netherlands.svg',
  }, {
    id: 'NZL',
    name: 'New Zealand',
    photoUrl: '/assets/images/flags/square/new-zealand.svg',
  }, {
    id: 'NCA',
    name: 'Nicaragua',
    photoUrl: '/assets/images/flags/square/nicaragua.svg',
  }, {
    id: 'NIG',
    name: 'Niger',
    photoUrl: '/assets/images/flags/square/niger.svg',
  }, {
    id: 'NGA',
    name: 'Nigeria',
    photoUrl: '/assets/images/flags/square/nigeria.svg',
  }, {
    id: 'PRK',
    name: 'North Korea',
    photoUrl: '/assets/images/flags/square/north-korea.svg',
  }, {
    id: 'MKD',
    name: 'North Macedonia',
    photoUrl: '/assets/images/flags/square/north-macedonia.svg',
  }, {
    id: 'NOR',
    name: 'Norway',
    photoUrl: '/assets/images/flags/square/norway.svg',
  }, {
    id: 'OMA',
    name: 'Oman',
    photoUrl: '/assets/images/flags/square/oman.svg',
  }, {
    id: 'PAK',
    name: 'Pakistan',
    photoUrl: '/assets/images/flags/square/pakistan.svg',
  }, {
    id: 'PLE',
    name: 'Palestine',
    photoUrl: '/assets/images/flags/square/palestine.svg',
  }, {
    id: 'PAN',
    name: 'Panama',
    photoUrl: '/assets/images/flags/square/panama.svg',
  }, {
    id: 'PNG',
    name: 'Papua New Guinea',
    photoUrl: '/assets/images/flags/square/papua-new-guinea.svg',
  }, {
    id: 'PAR',
    name: 'Paraguay',
    photoUrl: '/assets/images/flags/square/paraguay.svg',
  }, {
    id: 'PER',
    name: 'Peru',
    photoUrl: '/assets/images/flags/square/peru.svg',
  }, {
    id: 'PHI',
    name: 'Philippines',
    photoUrl: '/assets/images/flags/square/philippines.svg',
  }, {
    id: 'POL',
    name: 'Poland',
    photoUrl: '/assets/images/flags/square/poland.svg',
  }, {
    id: 'POR',
    name: 'Portugal',
    photoUrl: '/assets/images/flags/square/portugal.svg',
  }, {
    id: 'PUR',
    name: 'Puerto Rico',
    photoUrl: '/assets/images/flags/square/puerto-rico.svg',
  }, {
    id: 'QAT',
    name: 'Qatar',
    photoUrl: '/assets/images/flags/square/qatar.svg',
  }, {
    id: 'ROU',
    name: 'Romania',
    photoUrl: '/assets/images/flags/square/romania.svg',
  }, {
    id: 'RUS',
    name: 'Russia',
    photoUrl: '/assets/images/flags/square/russia.svg',
  }, {
    id: 'RWA',
    name: 'Rwanda',
    photoUrl: '/assets/images/flags/square/rwanda.svg',
  }, {
    id: 'SKN',
    name: 'Saint Kitts and Nevis',
    photoUrl: '/assets/images/flags/square/saint-kitts-and-nevis.svg',
  }, {
    id: 'LCA',
    name: 'Saint Lucia',
    photoUrl: '/assets/images/flags/square/saint-lucia.svg',
  }, {
    id: 'VIN',
    name: 'Saint Vincent and the Grenadines',
    photoUrl: '/assets/images/flags/square/spain.svg',
  }, {
    id: 'SAM',
    name: 'Samoa',
    photoUrl: '/assets/images/flags/square/samoa.svg',
  }, {
    id: 'SMR',
    name: 'San Marino',
    photoUrl: '/assets/images/flags/square/san-marino.svg',
  }, {
    id: 'STP',
    name: 'São Tomé and Príncipe',
    photoUrl: '/assets/images/flags/square/sao-tome-and-principe.svg',
  }, {
    id: 'KSA',
    name: 'Saudi Arabia',
    photoUrl: '/assets/images/flags/square/saudi-arabia.svg',
  }, {
    id: 'SCO',
    name: 'Scotland',
    photoUrl: '/assets/images/flags/square/scotland.svg',
  }, {
    id: 'SEN',
    name: 'Senegal',
    photoUrl: '/assets/images/flags/square/senegal.svg',
  }, {
    id: 'SRB',
    name: 'Serbia',
    photoUrl: '/assets/images/flags/square/serbia.svg',
  }, {
    id: 'SEY',
    name: 'Seychelles',
    photoUrl: '/assets/images/flags/square/seychelles.svg',
  }, {
    id: 'SLE',
    name: 'Sierra Leone',
    photoUrl: '/assets/images/flags/square/sierra-leone.svg',
  }, {
    id: 'SIN',
    name: 'Singapore',
    photoUrl: '/assets/images/flags/square/singapore.svg',
  }, {
    id: 'SMA',
    name: 'Sint Maarten',
    photoUrl: '/assets/images/flags/square/sint-maarten.svg',
  }, {
    id: 'SVK',
    name: 'Slovakia',
    photoUrl: '/assets/images/flags/square/slovakia.svg',
  }, {
    id: 'SVN',
    name: 'Slovenia',
    photoUrl: '/assets/images/flags/square/slovenia.svg',
  }, {
    id: 'SOL',
    name: 'Solomon Islands',
    photoUrl: '/assets/images/flags/square/solomon-islands.svg',
  }, {
    id: 'SOM',
    name: 'Somalia',
    photoUrl: '/assets/images/flags/square/somalia.svg',
  }, {
    id: 'RSA',
    name: 'South Africa',
    photoUrl: '/assets/images/flags/square/south-africa.svg',
  }, {
    id: 'KOR',
    name: 'South Korea',
    photoUrl: '/assets/images/flags/square/south-korea.svg',
  }, {
    id: 'SSD',
    name: 'South Sudan',
    photoUrl: '/assets/images/flags/square/south-sudan.svg',
  }, {
    id: 'ESP',
    name: 'Spain',
    photoUrl: '/assets/images/flags/square/spain.svg',
  }, {
    id: 'SRI',
    name: 'Sri Lanka',
    photoUrl: '/assets/images/flags/square/sri-lanka.svg',
  }, {
    id: 'SDN',
    name: 'Sudan',
    photoUrl: '/assets/images/flags/square/sudan.svg',
  }, {
    id: 'SUR',
    name: 'Suriname',
    photoUrl: '/assets/images/flags/square/suriname.svg',
  }, {
    id: 'SWE',
    name: 'Sweden',
    photoUrl: '/assets/images/flags/square/sweden.svg',
  }, {
    id: 'SWI',
    name: 'Switzerland',
    photoUrl: '/assets/images/flags/square/switzerland.svg',
  }, {
    id: 'SYR',
    name: 'Syria',
    photoUrl: '/assets/images/flags/square/syria.svg',
  }, {
    id: 'TAI',
    name: 'Taiwan',
    photoUrl: '/assets/images/flags/square/taiwan.svg',
  }, {
    id: 'TJK',
    name: 'Tajikistan',
    photoUrl: '/assets/images/flags/square/tajikistan.svg',
  }, {
    id: 'TAN',
    name: 'Tanzania',
    photoUrl: '/assets/images/flags/square/tanzania.svg',
  }, {
    id: 'THA',
    name: 'Thailand',
    photoUrl: '/assets/images/flags/square/thailand.svg',
  }, {
    id: 'TBT',
    name: 'Tibet',
    photoUrl: '/assets/images/flags/square/tibet.svg',
  }, {
    id: 'TOG',
    name: 'Togo',
    photoUrl: '/assets/images/flags/square/togo.svg',
  }, {
    id: 'TGA',
    name: 'Tonga',
    photoUrl: '/assets/images/flags/square/tonga.svg',
  }, {
    id: 'TRI',
    name: 'Trinidad and Tobago',
    photoUrl: '/assets/images/flags/square/trinidad-and-tobago.svg',
  }, {
    id: 'TUN',
    name: 'Tunisia',
    photoUrl: '/assets/images/flags/square/tunisia.svg',
  }, {
    id: 'TUR',
    name: 'Turkey',
    photoUrl: '/assets/images/flags/square/turkey.svg',
  }, {
    id: 'TKM',
    name: 'Turkmenistan',
    photoUrl: '/assets/images/flags/square/turkmenistan.svg',
  }, {
    id: 'TCA',
    name: 'Turks and Caicos Islands',
    photoUrl: '/assets/images/flags/square/turks-and-caicos.svg',
  }, {
    id: 'UGA',
    name: 'Uganda',
    photoUrl: '/assets/images/flags/square/uganda.svg',
  }, {
    id: 'UKR',
    name: 'Ukraine',
    photoUrl: '/assets/images/flags/square/ukraine.svg',
  }, {
    id: 'UAE',
    name: 'United Arab Emirates',
    photoUrl: '/assets/images/flags/square/united-arab-emirates.svg',
  }, {
    id: 'UKI',
    name: 'United Kingdom',
    photoUrl: '/assets/images/flags/square/united-kingdom.svg',
  }, {
    id: 'UNA',
    name: 'United Nations',
    photoUrl: '/assets/images/flags/square/united-nations.svg',
  }, {
    id: 'USA',
    name: 'United States',
    photoUrl: '/assets/images/flags/square/united-states-of-america.svg',
  }, {
    id: 'URU',
    name: 'Uruguay',
    photoUrl: '/assets/images/flags/square/uruguay.svg',
  }, {
    id: 'UZB',
    name: 'Uzbekistan',
    photoUrl: '/assets/images/flags/square/uzbekistan.svg',
  }, {
    id: 'VAN',
    name: 'Vanuatu',
    photoUrl: '/assets/images/flags/square/vanuatu.svg',
  }, {
    id: 'SCV',
    name: 'Vatican City',
    photoUrl: '/assets/images/flags/square/vatican-city.svg',
  }, {
    id: 'VEN',
    name: 'Venezuela',
    photoUrl: '/assets/images/flags/square/venezuela.svg',
  }, {
    id: 'VIE',
    name: 'Vietnam',
    photoUrl: '/assets/images/flags/square/vietnam.svg',
  }, {
    id: 'VIR',
    name: 'Virgin Islands U.S.',
    photoUrl: '/assets/images/flags/square/virgin-islands.svg',
  }, {
    id: 'WAL',
    name: 'Wales',
    photoUrl: '/assets/images/flags/square/wales.svg',
  }, {
    id: 'YEM',
    name: 'Yemen',
    photoUrl: '/assets/images/flags/square/yemen.svg',
  }, {
    id: 'ZAM',
    name: 'Zambia',
    photoUrl: '/assets/images/flags/square/zimbabwe.svg',
  },
];
