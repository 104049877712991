import {
  Directive,
  ElementRef,
  Host,
  HostListener,
  Inject,
  Optional
} from '@angular/core';
import {
  AbstractControl,
  ControlContainer,
  FormArray,
  FormGroup
} from '@angular/forms';

@Directive({
    selector: '[scrollToInvalidControl]',
    standalone: true
})
export class ScrollToInvalidControlDirective {
  constructor(
    private elementRef: ElementRef,
    @Host() @Optional() @Inject(ControlContainer) private controlContainer
  ) {
    if (!this.controlContainer) {
      throw new Error('No ControlContainer have been found.');
    }
  }

  private static scrollToElement(element: HTMLElement): void {
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      });
    }
  }

  private static markFormControlsTouched(
    controlContainer: FormGroup | FormArray
  ): void {
    const controls =
      controlContainer instanceof FormArray
        ? controlContainer.controls
        : Object.values(controlContainer.controls);

    controls.forEach((control: AbstractControl) => {
      control.markAsTouched();

      if (control instanceof FormGroup) {
        ScrollToInvalidControlDirective.markFormControlsTouched(control);
      }

      if (control instanceof FormArray) {
        ScrollToInvalidControlDirective.markFormControlsTouched(control);
      }
    });
  }

  @HostListener('submit')
  scrollToInvalidElement(): void {
    const controlContainer = this.controlContainer.form;

    if (
      controlContainer &&
      controlContainer.invalid &&
      controlContainer.controls
    ) {
      ScrollToInvalidControlDirective.markFormControlsTouched(controlContainer);

      const formControlInvalid = this.elementRef.nativeElement.querySelector(
        '.ng-invalid'
      );

      if (formControlInvalid) {
        return ScrollToInvalidControlDirective.scrollToElement(
          formControlInvalid
        );
      } else {
        const formGroupInvalid = this.elementRef.nativeElement.querySelectorAll(
          'form .ng-invalid'
        );
        if (formGroupInvalid && formGroupInvalid.length) {
          return ScrollToInvalidControlDirective.scrollToElement(
            formGroupInvalid[0]
          );
        }
      }
    }
  }
}
