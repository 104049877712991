import { firebaseConfig } from 'src/config/firebase.config';
import { rapidApiConfig, rapidApiHeaders } from 'src/config/rapid-api.config';
import { googleConfig } from 'src/config/google.config';

export const environment = {
  production: true,
  domain: 'https://futbalowski.com',
  firebase: firebaseConfig,
  rapidApi: {
    config: rapidApiConfig,
    header: rapidApiHeaders,
  },
  google: googleConfig,
  useEmulators: {
    database: false,
    firestore: false,
    auth: false,
    functions: false,
    storage: false,
  },
};
