import { Pipe, PipeTransform } from '@angular/core';

import { Fixture } from '@app/domain/fixtures/models/fixture.model';
import { Group } from '@app/domain/groups/models/group.model';
import { GroupsService } from '@app/domain/groups/services/groups.service';
import { Player } from '@app/domain/players/models/player.model';
import { User } from '@app/domain/users/models/user.model';
import { UsersService } from '@app/domain/users/services/users.service';

function isOrganiser({ user, userGroupRoles, fixture }: {
  user: User.Model | undefined,
  userGroupRoles: Group.MemberRoles | null,
  fixture: Fixture.Model
}): boolean {
  return Boolean(user?.admin ||
    fixture?.organiser === user?.id ||
    (fixture?.group && userGroupRoles?.groups[fixture.group]?.admin))
}

@Pipe({
    name: 'fixtureEditable',
    standalone: true
})
export class FixtureEditablePipe implements PipeTransform {
  private readonly user: User.Model | undefined;
  private readonly userGroupRoles: Group.MemberRoles | null;

  constructor(
    private readonly usersService: UsersService,
    private readonly groupsService: GroupsService,
  ) {
    this.user = this.usersService.currentUser;
    this.userGroupRoles = this.groupsService.userGroupRoles;
  }

  transform(fixture: Fixture.Model): boolean {
    return isOrganiser({ user: this.user, fixture, userGroupRoles: this.userGroupRoles });
  }
}

@Pipe({
    name: 'fixtureTeamsEditable',
    standalone: true
})
export class FixtureTeamsEditablePipe implements PipeTransform {
  private readonly user: User.Model | undefined;
  private readonly userGroupRoles: Group.MemberRoles | null;

  constructor(
    private readonly usersService: UsersService,
    private readonly groupsService: GroupsService,
  ) {
    this.user = this.usersService.currentUser;
    this.userGroupRoles = this.groupsService.userGroupRoles;
  }

  transform(fixture: Fixture.Model): boolean {
    return Boolean(
      fixture?.status === Fixture.Status.CheckInClosed &&
      isOrganiser({ user: this.user, fixture, userGroupRoles: this.userGroupRoles })
    )
  }
}

@Pipe({
    name: 'fixturePositionsEditable',
    standalone: true
})
export class FixturePositionsEditablePipe implements PipeTransform {
  private readonly user: User.Model | undefined;
  private readonly userGroupRoles: Group.MemberRoles | null;

  constructor(
    private readonly usersService: UsersService,
    private readonly groupsService: GroupsService,
  ) {
    this.user = this.usersService.currentUser;
    this.userGroupRoles = this.groupsService.userGroupRoles;
  }

  transform(fixture: Fixture.Model, player: Player.Model): boolean {
    return Boolean(
      (
        fixture?.status === Fixture.Status.CheckInOpen ||
        fixture?.status === Fixture.Status.CheckInClosed ||
        fixture?.status === Fixture.Status.InProgress
      ) && (
        (player?.user === this.user?.id && fixture?.status === Fixture.Status.CheckInOpen) ||
        isOrganiser({ user: this.user, fixture, userGroupRoles: this.userGroupRoles })
      )
    )
  }
}

@Pipe({
    name: 'fixturePlayersEditable',
    standalone: true
})
export class FixturePlayersEditablePipe implements PipeTransform {
  private readonly user: User.Model | undefined;
  private readonly userGroupRoles: Group.MemberRoles | null;

  constructor(
    private readonly usersService: UsersService,
    private readonly groupsService: GroupsService,
  ) {
    this.user = this.usersService.currentUser;
    this.userGroupRoles = this.groupsService.userGroupRoles;
  }

  transform(fixture: Fixture.Model, player: Player.Model): boolean {
    return Boolean(
      (
        fixture?.status === Fixture.Status.CheckInOpen ||
        fixture?.status === Fixture.Status.CheckInClosed ||
        fixture?.status === Fixture.Status.InProgress
      ) &&
      fixture?.organiser !== player?.user &&
      isOrganiser({ user: this.user, fixture, userGroupRoles: this.userGroupRoles })
    )
  }
}

@Pipe({
    name: 'fixturePayable',
    standalone: true
})
export class FixturePayablePipe implements PipeTransform {
  transform(fixture: Fixture.Model, player: Player.Model): boolean {
    return Boolean(
      fixture?.status !== Fixture.Status.Initial &&
      fixture?.status !== Fixture.Status.CheckInOpen &&
      player?.paymentType === null
    );
  }
}
