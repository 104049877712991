<h2 class="header">Select a new team for {{ inputs.player.user | userData | async | displayName }}</h2>

<div class="content">
  <div class="team__selector">
    <button
      *ngFor="let team of teamOptions; trackBy: trackById"
      type="button"
      class="btn--lg"
      [class.btn--inverted]="team.id === selectedTeam?.id"
      [class.active]="team.id === selectedTeam?.id"
      (click)="selectTeam(team)"
    >
      <app-team-color-badge [color]="team.color"></app-team-color-badge>
      {{ team.name }}
    </button>
  </div>
</div>

<div class="actions">
  <button type="button" class="btn--lg btn--error" [disabled]="isLoading$ | async" (click)="close(null)">
    <fa-icon class="icon-prefix" [icon]="ActionIcons.Cancel"></fa-icon>
    Cancel
  </button>

  <button
    type="button"
    class="btn--lg"
    [disabled]="!this.selectedTeam"
    [loading]="isLoading$ | async"
    (click)="submit()"
  >
    <fa-icon class="icon-prefix" [icon]="ActionIcons.Submit"></fa-icon>
    Save
  </button>
</div>

<ng-template #loader>
  <app-loader></app-loader>
</ng-template>
