import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Icons } from '@app/config/icons';
import { Team } from '@app/domain/teams/models/team.model';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { NgIf, NgClass } from '@angular/common';

@Component({
    selector: 'app-team-color-badge',
    templateUrl: './team-color-badge.component.html',
    styleUrls: ['./team-color-badge.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, FaIconComponent, NgClass]
})
export class TeamColorBadgeComponent implements OnInit {
  @Input() color: Team.Color | null;

  readonly ActionIcons = Icons.Actions;
  readonly TeamColor = Team.Color;

  constructor() { }

  ngOnInit(): void {
  }

}
