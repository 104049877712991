import { GeoPoint } from "@angular/fire/firestore";
import { DomainModel } from '@app/domain/models/domain.model';
import { Options } from '@app/domain/options/models/options.model';

export namespace Venue {
  export interface Model extends DomainModel {
    capacity: number;
    cover: PitchCover[];
    currency: string;
    discountPerPlayer: number;
    flooring: PitchFlooring[];
    location: Location;
    name: string;
    pitches: number;
    photoUrl: string | null;
    price: number[];
    size: PitchSize[];
    url: string | null;
  }

  export interface Location {
    address: string;
    city: string;
    coordinates: GeoPoint;
    country: string;
  }

  export interface Pitch extends DomainModel {
    cover: PitchCover[];
    currency: string;
    flooring: PitchFlooring;
    name: string;
    photoUrl: string | null;
    pricePerHour: number;
    pricePerUnit: number;
    priceUnit: number;
    size: PitchSize;
    venue: string;
  }

  export enum PitchSize {
    '5v5' = '5v5',
    '6v6' = '6v6',
    '7v7' = '7v7',
    '9v9' = '9v9',
    '11v11' = '11v11',
  }

  export enum PitchCover {
    Inside = 'inside',
    Outside = 'outside',
    Tent = 'tent'
  }

  export enum PitchFlooring {
    ArtificialGrass = 'artificialGrass',
    Grass = 'grass',
    Polyurethane = 'polyurethane',
    Rubber = 'rubber',
    Slag = 'slag',
    Vinyl = 'vinyl'
  }

  export function toOptions(items: Model[], withData = true): Options.SelectModel<string, Model | null>[] {
    return (items || []).map(item => ({
      id: item.id,
      name: item.name,
      photoUrl: item.photoUrl,
      data: withData ? item : null,
    }));
  }

  export function pitchesToOptions(items: Pitch[], withData = true): Options.SelectModel<string, Pitch | null>[] {
    return (items || []).map(item => ({
      id: item.id,
      name: item.name,
      photoUrl: item.photoUrl,
      data: withData ? item : null,
    }));
  }
}
