import { Directive, HostBinding, Input } from '@angular/core';

@Directive({
    selector: 'button[loading]',
    host: {
        '[class.loading]': 'loading',
        '[attr.disabled]': 'isDisabled || null',
    },
    standalone: true,
})
export class LoadingDirective {
  @Input() loading: boolean;
  @Input() @HostBinding('disabled') disabled: boolean;

  get isDisabled(): boolean {
    return this.disabled || this.loading;
  }
}
