import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';

import { User } from '@app/domain/users/models/user.model';
import { UsersService } from '@app/domain/users/services/users.service';
import Model = User.Model;

@Pipe({
    name: 'userData',
    standalone: true
})
export class UserDataPipe implements PipeTransform {
  constructor(private readonly usersService: UsersService) {}

  async transform(id: string): Promise<Model | null> {
    return this.usersService.fetch(id);
  }
}

@Pipe({
    name: 'userChanges',
    standalone: true
})
export class UserChangesPipe implements PipeTransform {
  constructor(private readonly usersService: UsersService) {}

  transform(id: string): Observable<Model | null> {
    return this.usersService.observe(id);
  }
}
