import { Component } from '@angular/core';
import { NgControl } from '@angular/forms';

import { Icons } from '@app/config/icons';
import { BaseControlValueAccessorComponent } from '@app/modules/shared/form/components/base-control-value-accessor/base-control-value-accessor.component';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';

@Component({
    selector: 'app-checkbox',
    templateUrl: './checkbox.component.html',
    styleUrls: ['./checkbox.component.scss'],
    standalone: true,
    imports: [FaIconComponent],
})
export class CheckboxComponent extends BaseControlValueAccessorComponent<boolean> {
  readonly ActionIcons = Icons.Actions;

  constructor(override controlDirective: NgControl) {
    super(controlDirective);
  }

  changeValue(): void {
    this.value = !Boolean(this.value);
  }
}
