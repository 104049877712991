import { CollectionReference, orderBy, query, Query, where, limit, QueryConstraint } from "@angular/fire/firestore"
import { Fixture } from '@app/domain/fixtures/models/fixture.model';

import { Queries } from '@app/domain/models/queries.model';
import { Timestamp } from 'firebase/firestore';


export namespace FixturesQueryParams {
  export interface DateTime extends Queries.FirebaseParams {
    date?: Timestamp;
  }

  export interface Venue extends Queries.FirebaseParams {
    venue: string;
  }

  export interface Groups extends Queries.FirebaseParams {
    groups: string[];
    open?: boolean;
  }
}

export class FixturesQueries<DocumentData> {
  constructor(private collectionRef: CollectionReference<DocumentData>) {}

  sortedByDate(): Query<DocumentData> {
    return query(
      this.collectionRef,
      orderBy('date', 'desc')
    );
  }

  upcoming(params?: Queries.FirebaseParams) {
    return query(
      this.collectionRef,
      where('status', 'in', [
        Fixture.Status.Initial,
        Fixture.Status.CheckInOpen,
        Fixture.Status.CheckInClosed,
        Fixture.Status.InProgress
      ]),
      orderBy('date', params?.orderByDirection || 'desc')
    );
  }

  previous(params?: Queries.FirebaseParams) {
    return query(
      this.collectionRef,
      where('status', 'in', [
        Fixture.Status.Finished,
        Fixture.Status.Cancelled
      ]),
      orderBy('date', params?.orderByDirection || 'desc')
    );
  }

  venue(params: FixturesQueryParams.Venue) {
    return query(
      this.collectionRef,
      where('venue', '==', params.venue),
      orderBy('date', params?.orderByDirection || 'desc')
    );
  }

  groups(params: FixturesQueryParams.Groups) {
    const groups: Array<string | null> = [...params.groups];
    if (params.open) {
      groups.push(null);
    }
    const queries: QueryConstraint[] = groups?.length ? [
      where('group', 'in', groups),
    ] : [
      where('group', '==', null),
    ]
    if (params.limit) {
      queries.push(limit(params.limit))
    }
    return query(
      this.collectionRef,
      ...queries,
      orderBy('date', params.orderByDirection || 'desc')
    )
  }
}
