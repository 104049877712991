import { Pipe, PipeTransform } from '@angular/core';
import { PricePipe } from '@app/modules/shared/pipes/price/price.pipe';

@Pipe({
    name: 'pitchPrice',
    standalone: true
})
export class PitchPricePipe implements PipeTransform {
  constructor(private readonly pricePipe: PricePipe) {}

  transform(prices: number[], currency: string = 'PLN'): string {
    if (!prices?.length) {
      return 'N/A';
    }
    if (prices.length === 1) {
      return this.priceTransform(prices[0], currency);
    }
    const min = Math.min(...prices);
    const max = Math.max(...prices);
    if (min === max) {
      return this.priceTransform(prices[0], currency);
    }
    return `${this.priceTransform(min, currency)}-${this.priceTransform(max, currency)}`;
  }

  private priceTransform(price: number, currency): string {
    return this.pricePipe.transform(price, currency);
  }
}
