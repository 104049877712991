import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AvatarModule } from '@app/modules/shared/avatar/avatar.module';


import { PipesModule } from '@app/modules/shared/pipes/pipes.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { PaymentInfoComponent } from './payment-info.component';

@NgModule({
    exports: [
        PaymentInfoComponent
    ],
    imports: [
    CommonModule,
    AvatarModule,
    FontAwesomeModule,
    PipesModule,
    PaymentInfoComponent,
]
})
export class PaymentInfoModule { }
