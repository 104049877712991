<label
  *ngIf="(label || icon) && type !== InputTypeEnum.Hidden"
  [for]="id"
  class="input__label"
  [class.required]="isRequired"
>
  <fa-icon *ngIf="icon" class="icon-prefix" [icon]="icon"></fa-icon>
  {{ label }}
</label>
<div class="input__container">
  <label *ngIf="prefix" [for]="id" class="input__prefix">{{ prefix }}</label>
  <ng-container *ngFor="let input of valueArray; let index = index; trackBy: trackByIndex">
    <input
      #fieldInput
      [id]="id + index"
      [attr.name]="id + index"
      [type]="type === InputTypeEnum.Number ? InputTypeEnum.Tel : type"
      [(ngModel)]="valueArray[index]"
      autocomplete="off"
      [placeholder]="placeholder"
      class="input__element"
      [class.filled]="valueArray[index]"
      [class.has-suffix]="suffix || showPasswordToggle"
      [disabled]="disabled"
      [pattern]="pattern || (type === InputTypeEnum.Number ? '[0-9]{1}' : '[a-zA-Z0-9]{1}')"
      [readOnly]="readonly"
      [attr.maxLength]="1"
      [attr.max]="type === InputTypeEnum.Number ? 9 : null"
      (focus)="fieldInput.select()"
      (keyup)="onKeyUp($event, index)"
      (keydown)="onKeyDown(fieldInput.value, $event, index)"
      (paste)="paste($event)"
    />
  </ng-container>
  <span class="input__suffix">{{ suffix }}</span>
</div>
