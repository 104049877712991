<label
  [for]="id"
  class="image-upload__label"
  [class.disabled]="disabled"
  [class.progress]="(uploading$ | async) || (processing$ | async)"
>

  <span *ngIf="label || icon" class="image-upload__label__text" [class.required]="isRequired">
    <fa-icon *ngIf="icon" class="icon-prefix" [icon]="icon"></fa-icon>
    {{ label }}
  </span>
  <input
    [id]="id"
    [attr.name]="id"
    type="file"
    autocomplete="off"
    [placeholder]="placeholder"
    class="image-upload__element"
    [disabled]="disabled || (uploading$ | async) || (processing$ | async)"
    [readOnly]="readonly"
    [attr.accept]="extensions"
    (change)="upload($event)"
  />
  <div class="image-upload__image">
    <img *ngIf="value" [src]="value | photoUrl" alt="Profile Picture" />
    <div class="image-upload__icon" [class.empty]="!value">
      <fa-icon [icon]="value ? ActionIcons.Edit : ActionIcons.Upload"></fa-icon>
    </div>
    <div
      *ngIf="(uploading$ | async) || (processing$ | async)"
      class="image-upload__overlay"
    >
      <app-progress-bar
        *ngIf="uploading$ | async"
        [progress]="uploadProgress$ | async"
        class="image-upload__progress"
      ></app-progress-bar>
      <div *ngIf="processing$ | async" class="image-upload__processing">
        <span>Processing...</span>
        <app-loader></app-loader>
      </div>
    </div>
  </div>
</label>
