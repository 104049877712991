import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';


import { NotificationService } from '@app/modules/shared/notification/services/notification.service';
import { PipesModule } from '@app/modules/shared/pipes/pipes.module';
import { NotificationComponent } from './notification.component';

@NgModule({
    imports: [
    CommonModule,
    FontAwesomeModule,
    PipesModule,
    NotificationComponent,
],
    exports: [NotificationComponent]
})
export class NotificationModule {
  static forRoot(): ModuleWithProviders<NotificationModule> {
    return {
      ngModule: NotificationModule,
      providers: [NotificationService]
    };
  }
}
