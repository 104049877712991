import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { trackById } from '@app/utils/track-by';
import { Notification } from '@app/modules/shared/notification/models/notification.model';
import { NotificationService } from '@app/modules/shared/notification/services/notification.service';
import { PhotoUrlPipe } from '../pipes/photo-url/photo-url.pipe';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { LogoComponent } from '../logo/logo.component';
import { NgFor, NgClass, NgIf } from '@angular/common';

@Component({
    selector: 'app-notification',
    templateUrl: './notification.component.html',
    styleUrls: ['./notification.component.scss'],
    standalone: true,
    imports: [NgFor, NgClass, LogoComponent, NgIf, FaIconComponent, PhotoUrlPipe]
})
export class NotificationComponent implements OnInit, OnDestroy {
  @Input() id = 'default-notification';

  notifications: Notification.Model[] = [];

  trackById = trackById;

  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    private router: Router,
    private notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    this.subscribeToNotificationChanges();
    this.subscribeToRouteChanges();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
  }

  removeNotification(notification: Notification.Model): void {
    if (!this.notifications.includes(notification)) {
      return;
    }

    this.notifications = this.notifications.filter(x => x !== notification);
  }

  private subscribeToNotificationChanges(): void {
    this.notificationService
      .newNotification(this.id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(notification => {
        if (!notification.message) {
          this.notifications = this.notifications.filter(
            x => x.keepAfterRouteChange
          );
          this.notifications.forEach(x => delete x.keepAfterRouteChange);

          return;
        }

        this.notifications.push(notification);

        if (notification.autoClose) {
          setTimeout(() => this.removeNotification(notification), 10000);
        }
      });
  }

  private subscribeToRouteChanges(): void {
    this.router.events.pipe(takeUntil(this.unsubscribe$)).subscribe(event => {
      if (event instanceof NavigationStart) {
        this.notificationService.clear(this.id);
      }
    });
  }
}
