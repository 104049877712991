import { Injectable } from '@angular/core';

import { Group } from '@app/domain/groups/models/group.model';
import { GroupsService } from '@app/domain/groups/services/groups.service';

import { User } from '@app/domain/users/models/user.model';
import { UsersService } from '@app/domain/users/services/users.service';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserResolver  {
  constructor(private readonly usersService: UsersService) {}

  async resolve(): Promise<User.Model | null> {
    return this.usersService.currentUser || await this.usersService.fetchCurrent();
  }
}

@Injectable({
  providedIn: 'root'
})
export class UserGroupRolesResolver  {
  constructor(private readonly groupsService: GroupsService, private readonly usersService: UsersService, private readonly userResolver: UserResolver) {}

  async resolve(): Promise<Group.MemberRoles | null> {
    if (!this.usersService.currentUser) {
      await this.userResolver.resolve();
    }
    return this.groupsService.userGroupRoles || await firstValueFrom(this.groupsService.observeUserGroupRoles());
  }
}
