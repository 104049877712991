import { TitleCasePipe } from '@angular/common';
import { DomainModel } from '@app/domain/models/domain.model';
import { Options } from '@app/domain/options/models/options.model';
import { Player } from '@app/domain/players/models/player.model';

export namespace Team {
  export interface Model extends DomainModel {
    order: number;
    name: string;
    color: Color;
    players: number;
    fixture: string;
    goals: number;
    wins: number;
    losses: number;
    draws: number;
    photoUrl: string | null;
    setup: Setup;
    stance: Stance;
  }

  export enum Color {
    White = 'white',
    Black = 'black',
    Red = 'red',
  }

  export function toOptions(items: Model[], withId?: boolean): Options.SelectModel[] {
    return (items || []).map(item => ({
      id: item.id,
      name: `${new TitleCasePipe().transform(item.color)}${withId ? ' (' + item.fixture + ')' : ''}`,
      icon: 'tshirt',
      data: item
    }));
  }

  export interface Setup {
    [Player.Position.GoalKeeper]: string | null;
    [Player.Position.Defense]: string[];
    [Player.Position.Midfield]: string[];
    [Player.Position.Offense]: string[];
    [Player.Position.Free]: string[];
  }

  export enum Stance {
    Defensive = '3-3',
    Balanced = '3-2-1',
    Offensive = '3-1-2',
    Linear = '2-2-2',
    Centralised = '2-3-1',
    Blitz = '1-2-3'
  }

  export enum Class {
    S = 'S',
    A = 'A',
    B = 'B',
    C = 'C',
    D = 'D',
    X = 'X',
  }

  export const ClassThreshold: { [key in Class]: number } = {
    [Class.S]: 90,
    [Class.A]: 80,
    [Class.B]: 70,
    [Class.C]: 60,
    [Class.D]: 1,
    [Class.X]: 0,
  }
}
