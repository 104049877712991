import { CdkVirtualScrollViewport, CdkFixedSizeVirtualScroll, CdkVirtualForOf } from '@angular/cdk/scrolling';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { NgControl, FormsModule } from '@angular/forms';
import { OptionSelectedPipe } from '@app/modules/shared/form/pipes/option-selected.pipe';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Subject } from 'rxjs';
import { debounceTime, take, takeUntil, tap } from 'rxjs/operators';

import { Icons } from '@app/config/icons';
import { Options } from '@app/domain/options/models/options.model';
import { DropdownComponent } from '@app/modules/shared/dropdown/components/dropdown-menu/dropdown.component';
import { MultiSelectConfigModel } from '@app/modules/shared/form/components/multi-select/models/multi-select-config.model';
import { BaseControlValueAccessorComponent } from '@app/modules/shared/form/components/base-control-value-accessor/base-control-value-accessor.component';
import { ZoneUtilsService } from '@app/services/zone-utils.service';
import { PhotoUrlPipe } from '../../../pipes/photo-url/photo-url.pipe';
import { ClickElsewhereDirective } from '../../../directives/click-elsewhere.directive';
import { DropdownComponent as DropdownComponent_1 } from '../../../dropdown/components/dropdown-menu/dropdown.component';
import { ImagePreloadDirective } from '../../../directives/image-preload.directive';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'app-multi-select',
    templateUrl: './multi-select.component.html',
    styleUrls: ['./multi-select.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        FaIconComponent,
        NgFor,
        FormsModule,
        ImagePreloadDirective,
        DropdownComponent_1,
        ClickElsewhereDirective,
        CdkVirtualScrollViewport,
        CdkFixedSizeVirtualScroll,
        CdkVirtualForOf,
        PhotoUrlPipe,
        OptionSelectedPipe,
    ],
})
export class MultiSelectComponent<IdType = string, DataType = any>
  extends BaseControlValueAccessorComponent<(IdType | Options.SelectModel<IdType, DataType>)[]>
  implements OnInit, OnDestroy {
  set activeOptionIndex(index: number) {
    this.scrollToIndex(index);
    this.activeOptionPosition = index;
  }
  get activeOptionIndex(): number {
    return this.activeOptionPosition;
  }

  get calculatedListHeight(): number {
    const heightOfAllOptions =
      this.filteredOptions?.length * (this.config?.optionHeight || this.selectConfig.optionHeight);

    return heightOfAllOptions < (this.config?.listMaxHeight || this.selectConfig.listMaxHeight)
      ? heightOfAllOptions
      : this.config?.listMaxHeight || this.selectConfig.listMaxHeight;
  }
  get placeholderText(): string {
    return this.disabled || this.readonly
      ? this.disabledPlaceholder
      : this.placeholder;
  }
  @ViewChild('filterInput', { read: ElementRef }) filterInput: ElementRef<HTMLInputElement>;
  @ViewChild('selectContainer', { read: ElementRef }) selectContainer: ElementRef;
  @ViewChild('cdkVirtualScrollViewport', {
    read: CdkVirtualScrollViewport,
    static: false
  }) cdkVirtualScrollViewport: CdkVirtualScrollViewport;
  @ViewChild(DropdownComponent) dropdown: DropdownComponent;
  @Input() label: string;
  @Input() icon: IconProp;
  @Input() placeholder = 'Select...';
  @Input() disabledPlaceholder = 'No results';
  @Input() set options(options: (IdType | Options.SelectModel<IdType, DataType>)[]) {
    this.allOptions = options || [];
    this.filteredOptions = options || [];
    this.select();
  }
  @Input() set config(config: Partial<MultiSelectConfigModel>) {
    this.selectConfig = { ...this.selectConfig, ...config };
  }
  get config(): Partial<MultiSelectConfigModel> {
    return this.selectConfig;
  }
  @Input() staticUrl = false;

  @HostBinding('class.input-mode') inputMode = false;

  isListVisible: boolean;
  selectedOptions: (IdType | Options.SelectModel<IdType, DataType>)[];
  filteredOptions: (IdType | Options.SelectModel<IdType, DataType>)[] = [];

  readonly ActionIcons = Icons.Actions;

  private allOptions: (IdType | Options.SelectModel<IdType, DataType>)[] = [];
  private isFocused = false;
  private selectConfig: MultiSelectConfigModel = {
    listMaxHeight: 200,
    optionHeight: 40,
    filterable: false,
    clearable: false,
  };
  private activeOptionPosition = -1;
  private readonly filter$ = new Subject<string>();
  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    override controlDirective: NgControl,
    private readonly zoneUtilsService: ZoneUtilsService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly optionSelectedPipe: OptionSelectedPipe<IdType | Options.SelectModel<IdType, DataType>>,
  ) {
    super(controlDirective);
  }

  ngOnInit(): void {
    this.registerFilter();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
  }

  onFilterKeyDown(event: KeyboardEvent): void {
    switch (event.key) {
      case 'Down':
      case 'ArrowDown':
      case 'Up':
      case 'ArrowUp':
        event.preventDefault();
        event.stopPropagation();

        return;
      case 'Enter':
        if (this.isListVisible) {
          this.selectOption(
            this.filteredOptions[this.activeOptionIndex],
            event
          );
        }

        return;
      case 'Backspace':
      case 'Delete':
        return;
      default:
        if (!this.filteredOptions.length) {
          event.preventDefault();
          event.stopPropagation();
        }

        return;
    }
  }

  onFilterKeyUp(event: KeyboardEvent): void {
    if (this.filteredOptions.length === 1 && !this.selectedOptions) {
      this.activeOptionIndex = -1;
    }
    switch (event.key) {
      case 'Down':
      case 'ArrowDown':
        event.preventDefault();
        event.stopPropagation();
        if (this.isListVisible) {
          if (this.activeOptionIndex < this.filteredOptions.length - 1) {
            this.activeOptionIndex++;
          }
        } else {
          this.showList();
        }

        return;
      case 'Up':
      case 'ArrowUp':
        event.preventDefault();
        event.stopPropagation();
        if (this.isListVisible) {
          if (this.activeOptionIndex > 0) {
            this.activeOptionIndex--;
          } else if (this.activeOptionIndex === -1) {
            this.activeOptionIndex = this.filteredOptions.length - 1;
          }
        } else {
          this.showList();
        }

        return;
      case 'Backspace':
      case 'Delete':
        event.preventDefault();
        event.stopPropagation();
        if (this.config.filterable) {
          this.filterOptions();
        }

        return;
      case 'Enter':
        event.preventDefault();
        event.stopPropagation();

        return;
      case 'Escape':
        event.preventDefault();
        event.stopPropagation();
        this.hideList();

        return;
      default:
        this.filterOptions();

        return;
    }
  }

  onFilterMouseDown(event: MouseEvent): void {
    if (this.disabled) {
      return;
    }
    event.preventDefault();
    event.stopPropagation();
    if (this.isFocused) {
      this.toggleList();
    } else {
      this.setFocus();
    }
  }

  onFilterFocus(): void {
    if (this.disabled) {
      return;
    }
    this.turnOnInputMode();
    this.isFocused = true;
  }

  onFilterBlur(): void {
    this.isFocused = false;
    setTimeout(() => this.turnOffInputMode(), 200);
  }

  override writeValue(value: (IdType | Options.SelectModel<IdType, DataType>)[]): void {
    super.writeValue(value || []);
    this.select();
  }

  select(): void {
    this.selectedOptions = this.value?.length
      ? this.allOptions.filter(option =>
        (this.value as (IdType | Options.SelectModel<IdType, DataType>)[]).find(selectedOption => selectedOption['id'] && option['id']
          ? selectedOption['id'] === option['id']
          : selectedOption === option
        ))
      : [];
  }

  trackByFn(
    _index: number,
    option: IdType | Options.SelectModel<IdType, DataType>
  ): IdType | Options.SelectModel<IdType, DataType> {
    return option?.['id'] || option;
  }

  showList(): void {
    if (this.disabled) {
      return;
    }
    this.isListVisible = true;

    this.dropdown?.show();

    this.zoneUtilsService.scheduleDelayedAction()
      .pipe(take(1))
      .subscribe(() => this.scrollToIndex(this.activeOptionIndex));
  }

  toggleList(): void {
    if (this.isListVisible) {
      this.hideList();
    } else {
      this.showList();
    }
  }

  hideList(): void {
    this.isListVisible = false;
    this.dropdown.hide();
    if (
      this.filterInput.nativeElement.value !== null &&
      this.filterInput.nativeElement.value !== ''
    ) {
      this.filter$.next('');
    }

    this.filterInput.nativeElement.value = '';
    if (!this.selectedOptions) {
      this.activeOptionIndex = -1;
    }
  }

  turnOnInputMode(): void {
    this.inputMode = true;
    this.showList();
  }

  turnOffInputMode(): void {
    this.inputMode = false;
    this.hideList();
  }

  selectOption(
    selectedOption: IdType | Options.SelectModel<IdType, DataType>,
    event?: Event
  ): void {
    if (event) {
      event.preventDefault();
    }
    if (this.disabled) {
      return;
    }
    const value = (this.value as (IdType | Options.SelectModel<IdType, DataType>)[]);
    const optionSelected = this.optionSelectedPipe.transform(this.value, selectedOption);
    if (optionSelected) {
      this.value = value.filter(option => option['id'] && selectedOption['id']
        ? option['id'] !== selectedOption['id']
        : option !== selectedOption
      );
    } else {
      this.value = [...value,
        selectedOption?.['id']
          ? { id: selectedOption['id'], data: selectedOption['data'] }
          : selectedOption
      ];
    }
    this.select();
  }

  clearSelect(selectedOption: IdType | Options.SelectModel<IdType, DataType>): void {
   this.selectOption(selectedOption);
  }

  filterOptions(): void {
    if (this.disabled) {
      return;
    }
    this.isListVisible = true;
    const query = this.filterInput.nativeElement.value;

    this.filter$.next(query?.toLocaleLowerCase() || '');
  }

  private setFocus(): void {
    if (this.disabled) {
      return;
    }
    this.filterInput.nativeElement.focus();
  }

  private resetActiveIndex(selectedOption: IdType | Options.SelectModel<IdType, DataType>): void {
    this.activeOptionIndex = this.filteredOptions.findIndex(option => {
      if (selectedOption?.['id'] && option?.['id']) {
        return selectedOption['id'] === option['id'];
      }
      return JSON.stringify(selectedOption) === JSON.stringify(option);
    });
  }

  private scrollToIndex(index: number): void {
    if (this.cdkVirtualScrollViewport) {
      const center = Math.floor(
        (this.config.listMaxHeight || this.selectConfig.listMaxHeight) / (this.config.optionHeight || this.selectConfig.optionHeight) / 2
      );
      this.cdkVirtualScrollViewport.scrollToIndex(
        index >= center ? index - center : 0
      );
    }
  }

  private registerFilter(): void {
    this.filter$
      .pipe(
        debounceTime(0),
        tap(queryString => {
          if (!queryString) {
            this.filteredOptions = [...this.allOptions];
            return;
          }

          this.filteredOptions = this.allOptions.filter(option => {
            const optionName = option['name'] || `${option}`;

            return optionName?.toLocaleLowerCase().includes(queryString) || '';
          });
          this.changeDetectorRef.markForCheck();
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }
}
