import { Pipe, PipeTransform } from '@angular/core';

export const defaultImageSize = 128;
export type ImageSize = 512 | 256 | 128 | 64 | 32;

@Pipe({
    name: 'photoUrl',
    standalone: true
})
export class PhotoUrlPipe implements PipeTransform {
  constructor() {}

  transform(
    refUrl: string | null | undefined,
    size: ImageSize = defaultImageSize,
    staticURL?: boolean,
  ): string {
    if (!refUrl) {
      return '';
    }

    if (staticURL) {
      return refUrl;
    }

    return refUrl.replace(
      `${defaultImageSize}x${defaultImageSize}`,
      `${size}x${size}`
    );
  }
}
