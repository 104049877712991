<span *ngIf="!user; else avatar" class="link">
  <app-loader [size]="LoaderSizeEnum.Small"></app-loader>
</span>
<ng-template #avatar>
  <a class="link" [routerLink]="user?.id
    ? group
      ? ['/', SectionsEnum.Core, SectionsEnum.Groups, group, SectionsEnum.Members, user.id]
      : ['/', SectionsEnum.Core, SectionsEnum.Users, user?.id]
    : null"
  >
    <img
      class="profile__photo"
      [src]="user?.photoUrl | photoUrl: imageSize"
      [alt]="user?.displayName | abbreviation"
      loading="lazy"
      default="assets/images/player.svg"
      [title]="user | displayName"
    />
    <app-flag *ngIf="showFlag" class="flag" [countryCode]="user?.country"></app-flag>
  </a>
</ng-template>
