<h2 class="header">
  <ng-container *ngIf="!inputs?.headerText">Are you sure?</ng-container>
  {{ inputs?.headerText }}
</h2>

<div *ngIf="inputs?.contentHtml" class="content" [innerHTML]="inputs?.contentHtml || ''"></div>

<div class="actions">
  <button *ngIf="!inputs?.hideCancel" type="button" class="btn--lg btn--error" (click)="close(false)">
    <fa-icon class="icon-prefix" [icon]="ActionIcons.Cancel"></fa-icon>
    <ng-container *ngIf="!inputs?.cancelText">Cancel</ng-container>
    {{ inputs?.cancelText }}
  </button>

  <button *ngIf="!inputs?.hideConfirm" type="button" class="btn--lg" (click)="close(true)">
    <fa-icon class="icon-prefix" [icon]="ActionIcons.Submit"></fa-icon>
    <ng-container *ngIf="!inputs?.confirmText">Yes</ng-container>
    {{ inputs?.confirmText }}
  </button>
</div>
