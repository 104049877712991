<ng-container *ngIf="color; else reserve">
  <fa-icon
    *ngIf="color === TeamColor.White"
    class="shirt--contour"
    transform="grow-2"
    [icon]="ActionIcons.Team"
  ></fa-icon>
  <fa-icon class="shirt" [ngClass]="'shirt--' + color" [icon]="ActionIcons.Team"></fa-icon>
</ng-container>
<ng-template #reserve>
  <fa-icon class="shirt" [icon]="ActionIcons.Ban"></fa-icon>
</ng-template>
