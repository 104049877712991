import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { Loader } from '@app/modules/shared/loader/models/loader.model';
import { NgClass } from '@angular/common';

@Component({
    selector: 'app-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgClass]
})
export class LoaderComponent {
  @Input() size = Loader.Size.Normal;
  @Input() @HostBinding('class.sticky') overlay = false;
}
