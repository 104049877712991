import { ApplicationRef, Injectable, Type } from '@angular/core';
import { Observable } from 'rxjs';

import {
  ConfirmationModalComponent
} from '@app/modules/shared/modal/components/confirmation-modal/confirmation-modal.component';
import { ModalComponent } from '@app/modules/shared/modal/modal.component';
import { ModalInputs } from '@app/modules/shared/modal/models/modal-inputs.model';
import { Modal } from '@app/modules/shared/modal/models/modal.model';

@Injectable()
export class ModalService {
  private modalContainer: HTMLElement | undefined;

  constructor(private appRef: ApplicationRef) {}

  confirm(inputs?: ModalInputs.Confirmation): Observable<boolean | undefined> {
    return this.open<
      ModalInputs.Confirmation,
      boolean,
      ConfirmationModalComponent
    >(ConfirmationModalComponent, inputs).onConfirm();
  }

  open<
    InputType,
    ResultType,
    ModalComponentType extends Modal.Model<InputType, ResultType>
  >(
    component: Type<ModalComponentType>,
    inputs?: InputType
  ): Modal.ModalRef<InputType, ResultType> {
    this.setupModalContainerDiv();

    const modalContainerRef = this.appRef.bootstrap(
      ModalComponent,
      this.modalContainer
    );

    const modalComponentRef = modalContainerRef.instance.createModal<
      InputType,
      ResultType,
      ModalComponentType
    >(component);

    if (inputs) {
      modalComponentRef?.instance.onInjectInputs(inputs);
    }

    return new Modal.ModalRef<InputType, ResultType>(
      modalContainerRef,
      modalComponentRef
    );
  }

  private setupModalContainerDiv(): void {
    this.modalContainer = document.createElement('app-modal');
    document
      .getElementsByTagName('app-root')[0]
      .appendChild(this.modalContainer);
  }
}
