import { Component, ChangeDetectionStrategy } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

import { Icons } from '@app/config/icons';
import { Options } from '@app/domain/options/models/options.model';
import { Player } from '@app/domain/players/models/player.model';
import { ModalInputs } from '@app/modules/shared/modal/models/modal-inputs.model';
import { Modal } from '@app/modules/shared/modal/models/modal.model';
import { trackByIndex } from '@app/utils/track-by';
import { DisplayNamePipe } from '../../../pipes/display-name/display-name.pipe';
import { UserDataPipe } from '../../../pipes/reference/user-reference.pipe';
import { PlayerClassBadgeComponent } from '../../../badges/player-class-badge/player-class-badge.component';
import { PlayerPositionBadgeComponent } from '../../../badges/player-position-badge/player-position-badge.component';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { NgFor, NgIf, AsyncPipe } from '@angular/common';
import { PitchComponent } from '../../../pitch/pitch.component';

@Component({
    selector: 'app-position-select-modal',
    templateUrl: './position-select-modal.component.html',
    styleUrls: ['./position-select-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [PitchComponent, NgFor, NgIf, FaIconComponent, PlayerPositionBadgeComponent, PlayerClassBadgeComponent, AsyncPipe, UserDataPipe, DisplayNamePipe]
})
export class PositionSelectModalComponent extends Modal.Model<ModalInputs.PositionSelect, Player.Position | null>{
  inputs: ModalInputs.TeamSelect;
  positionOptions: Player.Position[] =
    Object.values(Player.Position).filter(position => position !== Player.Position.Free);
  selectedPosition: Player.Position | null;

  readonly ActionIcons = Icons.Actions;
  readonly Position = Player.Position;
  readonly trackByIndex = trackByIndex;

  override onInjectInputs(inputs: ModalInputs.TeamSelect) {
    this.inputs = inputs;
    const player = inputs.player;
    this.selectedPosition = player.position;
  }

  submit(): void {
    this.close(this.selectedPosition);
  }

  selectPosition(position: Player.Position): void {
    this.selectedPosition = position;
  }
}
