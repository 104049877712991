import { CollectionReference, orderBy, query, where, WhereFilterOp } from '@angular/fire/firestore';

import { Queries } from '@app/domain/models/queries.model';

export namespace VenuesQueryParams {
  export interface Capacity extends Queries.FirebaseParams {
    capacity?: number;
    operator?: WhereFilterOp;
  }
}

export class VenuesQueries<DocumentData> {
  constructor(private collectionRef: CollectionReference<DocumentData>) {}

  capacity(params?: VenuesQueryParams.Capacity) {
    return query(
      this.collectionRef,
      where('capacity', params?.operator || '>=', params?.capacity || 0),
      orderBy('capacity', 'asc')
    );
  }

  pitches<Model>(ref: CollectionReference<Model>) {
    return query(
      ref,
      orderBy('name')
    )
  }
}
