<label
  *ngIf="label || icon"
  [for]="id"
  class="radio__label"
  [class.required]="isRequired"
>
  <fa-icon *ngIf="icon" class="icon-prefix" [icon]="icon"></fa-icon>
  {{ label }}
</label>
<div class="radio__container">
  <label
    *ngFor="let option of options; let index = index; trackBy: trackByFn"
    [for]="id + index"
    class="radio__button"
    [class.radio__button--checked]="
      value?.['id'] ? value['id'] === option?.['id'] : value === option
    "
    [class.disabled]="disabled || readonly"
    [ngClass]="'radio__button--' + size"
  >
    <input
      [id]="id + index"
      [attr.name]="id"
      type="radio"
      [value]="option?.['id'] || option"
      autocomplete="off"
      class="radio__element"
      [disabled]="disabled || readonly"
      [readOnly]="readonly"
      [checked]="value?.['id'] ? value['id'] === option?.['id'] : value === option"
      (change)="selectOption(option)"
    />
    {{ option?.['name'] || option }}
  </label>
</div>
