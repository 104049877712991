import { RolesEnum } from '@app/config/roles.enum';
import { Venue } from '@app/domain/venues/models/venue.model';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { ActionsEnum } from '@app/config/actions.enum';
import { SectionsEnum } from '@app/config/sections.enum';
import { Fixture } from '@app/domain/fixtures/models/fixture.model';
import { Messages } from '@app/domain/messages/models/messages.model';
import { Payment } from '@app/domain/models/payment.model';

export namespace Icons {
  export const Actions: { [Key in ActionsEnum]: IconProp } = {
    [ActionsEnum.Activate]: 'play',
    [ActionsEnum.Add]: 'plus',
    [ActionsEnum.Alert]: 'exclamation-triangle',
    [ActionsEnum.Back]: 'arrow-left',
    [ActionsEnum.Ban]: 'ban',
    [ActionsEnum.Cancel]: 'times',
    [ActionsEnum.Center]: 'crosshairs',
    [ActionsEnum.Clear]: 'times',
    [ActionsEnum.Close]: 'times',
    [ActionsEnum.Copy]: 'copy',
    [ActionsEnum.Date]: 'calendar',
    [ActionsEnum.Deactivate]: 'stop',
    [ActionsEnum.Disable]: 'slash',
    [ActionsEnum.Dislike]: 'heart-broken',
    [ActionsEnum.Delete]: 'trash',
    [ActionsEnum.Down]: 'arrow-down',
    [ActionsEnum.Edit]: 'edit',
    [ActionsEnum.Error]: 'exclamation-circle',
    [ActionsEnum.Directions]: 'route',
    [ActionsEnum.Global]: 'globe',
    [ActionsEnum.Go]: 'arrow-right',
    [ActionsEnum.Goal]: 'bullseye',
    [ActionsEnum.Follow]: 'location-arrow',
    [ActionsEnum.Hide]: 'eye-slash',
    [ActionsEnum.Info]: 'info-circle',
    [ActionsEnum.Injured]: 'square-plus',
    [ActionsEnum.Invite]: 'envelope-open-text',
    [ActionsEnum.Join]: 'sign-in-alt',
    [ActionsEnum.Leave]: 'sign-out-alt',
    [ActionsEnum.Link]: 'link',
    [ActionsEnum.Like]: 'heart',
    [ActionsEnum.ListClose]: 'angle-double-up',
    [ActionsEnum.ListOpen]: 'angle-double-down',
    [ActionsEnum.LogIn]: 'arrow-right-to-bracket',
    [ActionsEnum.LogOut]: 'arrow-right-from-bracket',
    [ActionsEnum.Menu]: 'ellipsis-v',
    [ActionsEnum.Messages]: 'envelope',
    [ActionsEnum.Minus]: 'minus',
    [ActionsEnum.Multiply]: 'star',
    [ActionsEnum.Ok]: 'check',
    [ActionsEnum.Pay]: 'hand-holding-usd',
    [ActionsEnum.Play]: 'play',
    [ActionsEnum.Question]: 'question-circle',
    [ActionsEnum.Refresh]: 'sync',
    [ActionsEnum.Save]: 'save',
    [ActionsEnum.Search]: 'magnifying-glass',
    [ActionsEnum.Sort]: 'sort',
    [ActionsEnum.Start]: 'stopwatch',
    [ActionsEnum.Show]: 'eye',
    [ActionsEnum.Submit]: 'check',
    [ActionsEnum.Success]: 'smile',
    [ActionsEnum.Team]: 'tshirt',
    [ActionsEnum.Time]: 'clock',
    [ActionsEnum.Unable]: 'sad-cry',
    [ActionsEnum.Unpaid]: 'triangle-exclamation',
    [ActionsEnum.Up]: 'arrow-up',
    [ActionsEnum.Upload]: 'upload',
    [ActionsEnum.Wait]: 'hourglass-half',
    [ActionsEnum.Warning]: 'exclamation-triangle',
    [ActionsEnum.ZoomIn]: 'search-plus',
    [ActionsEnum.ZoomOut]: 'search-minus'
  };

  export const Sections: { [key in SectionsEnum]: IconProp } = {
    [SectionsEnum.Admin]: 'cogs',
    [SectionsEnum.Core]: 'cog',
    [SectionsEnum.Eula]: 'balance-scale',
    [SectionsEnum.Edit]: 'edit',
    [SectionsEnum.Fixtures]: 'futbol',
    [SectionsEnum.Groups]: 'users-between-lines',
    [SectionsEnum.Info]: 'info-circle',
    [SectionsEnum.Invitations]: 'inbox',
    [SectionsEnum.Login]: 'arrow-right-to-bracket',
    [SectionsEnum.Logout]: 'arrow-right-from-bracket',
    [SectionsEnum.Members]: 'users',
    [SectionsEnum.Messages]: 'envelope',
    [SectionsEnum.New]: 'plus',
    [SectionsEnum.Pitches]: 'border-none',
    [SectionsEnum.Profile]: 'user',
    [SectionsEnum.Register]: 'file-signature',
    [SectionsEnum.Settings]: 'user-cog',
    [SectionsEnum.Users]: 'users',
    [SectionsEnum.Teams]: 'tshirt',
    [SectionsEnum.Venues]: 'ring',
  };

  export const MessageCategories: { [key in Messages.CategoryEnum]: IconProp } = {
    [Messages.CategoryEnum.Alerts]: 'exclamation-triangle',
    [Messages.CategoryEnum.Fixtures]: 'futbol',
    [Messages.CategoryEnum.General]: 'envelope',
    [Messages.CategoryEnum.Teams]: 'tshirt',
  };

  export const Payments: { [key in Payment.Type]: IconProp } = {
    [Payment.Type.Revolut]: 'credit-card',
    [Payment.Type.Blik]: 'mobile-screen',
    [Payment.Type.PayPal]: ['fab', 'cc-paypal'],
    [Payment.Type.Transfer]: 'building-columns',
    [Payment.Type.Cash]: 'money-bill',
  }

  export const Weather: { [key: string]: IconProp } = {
    // Open weather map icons (legacy)
    '01d': 'sun',
    '01n': 'moon',
    '02d': 'cloud-sun',
    '02n': 'cloud-moon',
    '03d': 'cloud-sun',
    '03n': 'cloud-moon',
    '04d': 'cloud',
    '04n': 'cloud',
    '09d': 'cloud-showers-heavy',
    '09n': 'cloud-showers-heavy',
    '10d': 'cloud-sun-rain',
    '10n': 'cloud-moon-rain',
    '11d': 'cloud-bolt',
    '11n': 'cloud-bolt',
    '13d': 'snowflake',
    '13n': 'snowflake',
    '50d': 'smog',
    '50n': 'smog',
    // Weatherbit icons
    'a01d': 'smog',
    'a01n': 'smog',
    'a02d': 'smog',
    'a02n': 'smog',
    'a03d': 'smog',
    'a03n': 'smog',
    'a04d': 'smog',
    'a04n': 'smog',
    'a05d': 'smog',
    'a05n': 'smog',
    'a06d': 'smog',
    'a06n': 'smog',
    'c01d': 'sun',
    'c01n': 'moon',
    'c02d': 'cloud-sun',
    'c02n': 'cloud-moon',
    'c03d': 'cloud-sun',
    'c03n': 'cloud-moon',
    'c04d': 'cloud',
    'c04n': 'cloud',
    'd01d': 'cloud-rain',
    'd01n': 'cloud-rain',
    'd02d': 'cloud-rain',
    'd02n': 'cloud-rain',
    'd03d': 'cloud-rain',
    'd03n': 'cloud-rain',
    'f01d': 'cloud-showers-heavy',
    'f01n': 'cloud-showers-heavy',
    'r01d': 'cloud-rain',
    'r01n': 'cloud-rain',
    'r02d': 'cloud-rain',
    'r02n': 'cloud-rain',
    'r03d': 'cloud-showers-water',
    'r03n': 'cloud-showers-water',
    'r04d': 'cloud-sun-rain',
    'r04n': 'cloud-moon-rain',
    'r05d': 'cloud-sun-rain',
    'r05n': 'cloud-moon-rain',
    'r06d': 'cloud-showers-heavy',
    'r06n': 'cloud-showers-heavy',
    's01d': 'snowflake',
    's01n': 'snowflake',
    's02d': 'snowflake',
    's02n': 'snowflake',
    's03d': 'snowflake',
    's03n': 'snowflake',
    's04d': 'snowflake',
    's04n': 'snowflake',
    's05d': 'wind',
    's05n': 'wind',
    's06d': 'snowflake',
    's06n': 'snowflake',
    't01d': 'cloud-bolt',
    't01n': 'cloud-bolt',
    't02d': 'cloud-bolt',
    't02n': 'cloud-bolt',
    't03d': 'cloud-bolt',
    't03n': 'cloud-bolt',
    't04d': 'poo-storm',
    't04n': 'poo-storm',
    't05d': 'poo-storm',
    't05n': 'poo-storm',
    'u00d': 'cloud-rain',
    'u00n': 'cloud-rain',
  };

  export const Roles: { [key in RolesEnum]: IconProp } = {
    [RolesEnum.Admin]: 'user-gear',
    [RolesEnum.Analyst]: 'chart-line',
    [RolesEnum.Organiser]: 'crown',
  }

  export const FixtureStatuses: { [key in Fixture.Status]: IconProp } = {
    [Fixture.Status.Initial]: 'hourglass-start',
    [Fixture.Status.CheckInOpen]: 'unlock',
    [Fixture.Status.CheckInClosed]: 'lock',
    [Fixture.Status.InProgress]: 'play-circle',
    [Fixture.Status.Finished]: 'check-circle',
    [Fixture.Status.Cancelled]: 'times-circle'
  }

  export const Venues: {
    capacity: IconProp;
    cover: IconProp;
    flooring: IconProp;
    location: IconProp;
    price: IconProp;
    size: IconProp;
  } = {
    capacity: 'people-line',
    cover: 'person-shelter',
    flooring: 'shoe-prints',
    location: 'map-marker-alt',
    price: 'circle-dollar-to-slot',
    size: 'border-top-left',
  }
}
