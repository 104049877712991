import { Icons } from '@app/config/icons';
import { Timestamp } from 'firebase/firestore';

import { DomainModel } from '@app/domain/models/domain.model';
import { Forecast, LegacyForecast } from '@app/domain/forecast/models/forecast.model';
import { Options } from '@app/domain/options/models/options.model';
import { DateFormatter } from '@app/utils/date-formatter';

export namespace Fixture {
  export interface Model extends DomainModel {
    checkInOpen: Timestamp;
    checkInClose: Timestamp;
    currency: string;
    date: Timestamp;
    dateEnd: Timestamp;
    discountPerPlayer: number;
    forecast?: LegacyForecast.Model | Forecast.Model | null;
    group: string | null;
    maxTeams: number;
    organiser: string;
    playersPerTeam: number;
    paid: number;
    pitch: string;
    playtime: number;
    price: number;
    priceAfterDiscount?: number;
    pricePerPlayer?: number;
    status: Status;
    venue: string;
  }

  export enum Status {
    Initial = 'initial',
    CheckInOpen = 'checkInOpen',
    CheckInClosed = 'checkInClosed',
    InProgress = 'inProgress',
    Finished = 'finished',
    Cancelled = 'cancelled',
  }

  export const isStatusFinished = (status: Status) => [
    Status.Finished,
    Status.Cancelled,
  ].includes(status)

  export const isStatusActive = (status: Status) => [
    Status.Initial,
    Status.CheckInOpen,
    Status.CheckInClosed,
    Status.InProgress,
  ].includes(status)

  export enum PlayerCountStatus {
    UnderBooked = 'underbooked',
    Filled = 'filled',
    OverBooked = 'overbooked',
    Uncounted = 'uncounted'
  }

  export enum Cards {
    Yellow = 'yellow',
    SecondYellow = 'secondYellow',
    Red = 'red'
  }

  export interface GoldenShoe {
    player: string | null;
    goals: number;
  }

  export const playersPerTeamOptions = [5, 6, 7, 8, 9, 10, 11];

  export const maxTeamsOptions = [2, 3, 4];

  export const statusAllId = 'all';

  export const statusAll = {
    id: statusAllId,
    name: 'All',
  }

  export function statusToOptions(withAll?: boolean): Options.SelectModel<Status | string>[] {
    const options: Options.SelectModel<Status | string>[] = Object.keys(Status).map(key => ({
      id: Status[key],
      name: key.replace(/([A-Z])/g, ' $1').trim(),
      icon: Icons.FixtureStatuses[Status[key]]
    }))

    if (withAll) {
      options.unshift(statusAll)
    }

    return options;
  }

  export function toOptions(items: Model[], withData = true): Options.SelectModel[] {
    return (items || []).map(item => ({
      id: item.id,
      name: DateFormatter.toFormattedDateTime(item.date.toMillis(), 'y.MM.DD, HH:mm'),
      data: withData ? item : null
    }));
  }
}
