import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'abbreviation',
    standalone: true
})
export class AbbreviationPipe implements PipeTransform {
  transform(value: string | null | undefined, maxLength?: number): string {
    return value
      ? value.split(' ').map((word, index) =>
        index === 0
          ? maxLength && word.length > maxLength ? (word.substring(0, maxLength) + '.') : word
          : `${word.charAt(0).toLocaleUpperCase()}.`
      ).join(' ')
      : '?';
  }
}
