import { Pipe, PipeTransform } from '@angular/core';
import { FixturesService } from '@app/domain/fixtures/services/fixtures.service';
import { of } from "rxjs"

import { GroupsService } from '@app/domain/groups/services/groups.service';
import { take } from "rxjs/operators"

@Pipe({
    name: 'groupData',
    standalone: true
})
export class GroupDataPipe implements PipeTransform {
  constructor(private readonly groupsService: GroupsService) {}

  async transform(id: string | null | undefined){
    if (!id) {
      return null;
    }
    return this.groupsService.fetch(id);
  }
}

@Pipe({
    name: 'groupChanges',
    standalone: true
})
export class GroupChangesPipe implements PipeTransform {
  constructor(private readonly groupsService: GroupsService) {}

  transform(id: string | null) {
    if (!id) {
      return of(null).pipe(take(1));
    }
    return this.groupsService.observe(id);
  }
}

@Pipe({
    name: 'groupMembersCount',
    standalone: true
})
export class GroupMembersCountPipe implements PipeTransform {
  constructor(private readonly groupsService: GroupsService) {}

  transform(id: string | null) {
    if (!id) {
      return of(0).pipe(take(1));
    }
    return this.groupsService.observeMembersCount(id);
  }
}

@Pipe({
    name: 'groupFixturesCount',
    standalone: true
})
export class GroupFixturesCountPipe implements PipeTransform {
  constructor(private readonly fixturesService: FixturesService) {}

  transform(id: string) {
    if (!id) {
      return of(0).pipe(take(1));
    }
    return this.fixturesService.observeForGroupCount(id);
  }
}
