<h2 class="header">Select a payment method</h2>

<div class="content">
  <h2 class="fee">Fee: {{ (inputs.fixture.pricePerPlayer || 0) | price: inputs.fixture.currency }}</h2>
  <app-select
    *ngIf="!(isLoading$ | async); else loader"
    id="teams"
    [formControl]="paymentControl"
    [options]="paymentOptions"
  ></app-select>

  <h3 [ngSwitch]="paymentControl.value?.id">
    <ng-container *ngSwitchCase="PaymentType.Revolut">
      <a
        *ngIf="organiser.payments.revolut; else noRevolut"
        [href]="'https://revolut.me/' + organiser.payments.revolut"
        target="_blank"
        rel="noopener nofollow"
      >
        revolut.me/{{ organiser.payments.revolut}}
        <fa-icon class="icon-suffix" [icon]="ActionIcons.Link"></fa-icon>
      </a>
      <ng-template #noRevolut>No revolut account provided</ng-template>
    </ng-container>

    <ng-container *ngSwitchCase="PaymentType.Blik">
      <button
        *ngIf="organiser.payments.blik; else noPhone"
        class="btn--inverted"
        [copyToClipboard]="organiser.payments.blik.substring(3)"
      >
        {{ organiser.payments.blik }}
        <fa-icon class="icon-suffix" [icon]="ActionIcons.Copy"></fa-icon>
      </button>
      <ng-template #noPhone>No blik phone number provided</ng-template>
    </ng-container>

    <ng-container *ngSwitchCase="PaymentType.PayPal">
      <a
        *ngIf="organiser.payments.paypal; else noPaypal"
        [href]="'https://paypal.me/' + organiser.payments.paypal"
        target="_blank"
        rel="noopener nofollow"
      >
        paypal.me/{{ organiser.payments.paypal }}
        <fa-icon class="icon-suffix" [icon]="ActionIcons.Link"></fa-icon>
      </a>
      <ng-template #noPaypal>No paypal account provided</ng-template>
    </ng-container>

    <ng-container *ngSwitchCase="PaymentType.Transfer">
      <button
        *ngIf="organiser.payments.transfer; else noTransfer"
        class="btn--inverted"
        [copyToClipboard]="organiser.payments.transfer"
      >
        {{ organiser.payments.transfer }}
        <fa-icon class="icon-suffix" [icon]="ActionIcons.Copy"></fa-icon>
      </button>
      <ng-template #noTransfer>No account number provided</ng-template>
    </ng-container>

    <ng-container *ngSwitchCase="PaymentType.Cash">
      <ng-container *ngIf="!organiser.payments.cash">Organiser does not accept cash</ng-container>
    </ng-container>
  </h3>
</div>

<div class="actions">
  <button type="button" class="btn--lg btn--error" (click)="close(null)">
    <fa-icon class="icon-prefix" [icon]="ActionIcons.Cancel"></fa-icon>
    Cancel
  </button>

  <button
    type="button"
    class="btn--lg"
    [disabled]="!canSubmit"
    [loading]="isLoading$ | async"
    (click)="submit()"
  >
    <fa-icon class="icon-prefix" [icon]="ActionIcons.Submit"></fa-icon>
    I paid
  </button>
</div>

<ng-template #loader>
  <app-loader></app-loader>
</ng-template>
