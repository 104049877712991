<div
  *ngFor="let notification of notifications; trackBy: trackById"
  [ngClass]="['notification__box--' + notification.type]"
  class="notification__box"
>
  <div class="notification__circle">
    <div class="notification__logo">
      <app-logo [size]="80"></app-logo>
    </div>
    <img
      *ngIf="notification.imageUrl"
      class="notification__img"
      [src]="notification.imageUrl | photoUrl: 128: notification.staticUrl"
      alt="icon"
    />
    <fa-icon
      *ngIf="notification.icon as icon"
      [icon]="icon"
      class="notification__icon"
    ></fa-icon>
  </div>
  <div class="notification__message-container">
    <div class="notification__message-box">
      <p
        class="notification__message"
        [innerHTML]="notification.message"
        [ngClass]="[
          'notification__message--' + (notification.fontSize || 'md')
        ]"
      ></p>
    </div>
  </div>
</div>
