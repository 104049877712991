import { Injectable } from '@angular/core';
import { collection, doc, Firestore } from '@angular/fire/firestore';
import { UsersService } from '@app/domain/users/services/users.service';

@Injectable({
  providedIn: 'root'
})
export class FirebaseTools {
  constructor(private readonly firestore: Firestore) {}

  uniqueId() {
    return doc(collection(this.firestore, UsersService.collectionId)).id;
  }
}
