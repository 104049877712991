import { Component, ChangeDetectionStrategy, Input, HostBinding } from '@angular/core';
import { Statistics } from '@app/config/statistics';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-progress-bar',
    templateUrl: './progress-bar.component.html',
    styleUrls: ['./progress-bar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf]
})
export class ProgressBarComponent {
  @Input() set progress(value: number) {
    this._progressValue = Math.round(value);
    this.calculateProgress();
  }
  get progress(): number {
    return this._progress;
  }
  get style(): string {
    return `clip-path: polygon(0% 0%, ${this._progress}% 0%, ${this._progress}% 100%, 0% 100%);`;
  }
  get progressValue(): number {
    return this._progressValue;
  }
  @Input() set max(max: number) {
    this._max = Math.round(max);
    this.calculateProgress();
  }
  get max(): number {
    return this._max;
  }
  @Input() set min(min: number) {
    this._min = Math.round(min);
    this.calculateProgress();
  }
  get min(): number {
    return this._min;
  }
  @Input() reverse = false;
  @Input() minIndicator = 'E';
  @Input() maxIndicator = 'F';
  @Input() showText: boolean = true;
  @Input() showValue: boolean = false;
  @Input() label: string;
  @Input() @HostBinding('class.left-aligned') leftAligned: boolean;
  @Input() @HostBinding('class.right-aligned') rightAligned: boolean;

  private _min: number = 0;
  private _max: number = 100;
  private _progress: number = 0;
  private _progressValue: number = 0;

  private calculateProgress(): void {
    this._progress = Math.round(this._progressValue / this._max * 100);
  }
}
