import { Component, ChangeDetectionStrategy } from '@angular/core';

import { Icons } from '@app/config/icons';
import { ModalInputs } from '@app/modules/shared/modal/models/modal-inputs.model';
import { Modal } from '@app/modules/shared/modal/models/modal.model';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';

@Component({
    selector: 'app-new-update-modal',
    templateUrl: './new-update-modal.component.html',
    styleUrls: ['./new-update-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [FaIconComponent]
})
export class NewUpdateModalComponent extends Modal.Model<unknown, boolean> {
  readonly ActionIcons = Icons.Actions;

  onInjectInputs(inputs: ModalInputs.Confirmation): void {}
}
