import { ScrollingModule } from '@angular/cdk/scrolling';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';



import { PipesModule } from '@app/modules/shared/pipes/pipes.module';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { CodeComponent } from '@app/modules/shared/form/components/code/code.component';
import {
  DateTimePickerComponent
} from '@app/modules/shared/form/components/date-time-picker/date-time-picker.component';
import { InputComponent } from './components/input/input.component';
import { MultiSelectComponent } from '@app/modules/shared/form/components/multi-select/multi-select.component';
import { ImageUploadComponent } from './components/image-upload/image-upload.component';
import { RadioComponent } from './components/radio/radio.component';
import { SelectComponent } from './components/select/select.component';
import { SwitchComponent } from './components/switch/switch.component';
import { TextareaComponent } from './components/textarea/textarea.component';
import { TimePickerComponent } from '@app/modules/shared/form/components/time-picker/time-picker.component';
import { ScrollToInvalidControlDirective } from './directives/scroll-to-invalid-control.directive';
import { OptionSelectedPipe } from '@app/modules/shared/form/pipes/option-selected.pipe';



@NgModule({
    imports: [
    CommonModule,
    FontAwesomeModule,
    PipesModule,
    ScrollingModule,
    FormsModule,
    CheckboxComponent,
    CodeComponent,
    DateTimePickerComponent,
    ImageUploadComponent,
    InputComponent,
    MultiSelectComponent,
    RadioComponent,
    SelectComponent,
    SwitchComponent,
    TextareaComponent,
    TimePickerComponent,
    ScrollToInvalidControlDirective,
    OptionSelectedPipe,
],
    providers: [OptionSelectedPipe],
    exports: [
        CheckboxComponent,
        CodeComponent,
        DateTimePickerComponent,
        ImageUploadComponent,
        InputComponent,
        MultiSelectComponent,
        RadioComponent,
        SelectComponent,
        SwitchComponent,
        TextareaComponent,
        TimePickerComponent,
        ScrollToInvalidControlDirective,
        OptionSelectedPipe,
    ]
})
export class FormModule {}
