import { Injectable } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

import { Notification } from '@app/modules/shared/notification/models/notification.model';
import { Icons } from '@app/config/icons';

@Injectable()
export class NotificationService {
  private notificationSubject = new Subject<Notification.Model>();
  private defaultId = 'default-notification';

  newNotification(id = this.defaultId): Observable<Notification.Model> {
    return this.notificationSubject
      .asObservable()
      .pipe(filter(notification => notification && notification.id === id));
  }

  success(message: SafeHtml | string, options?: Partial<Notification.Model>): void {
    this.alert(
      new Notification.Model({
        icon: Icons.Actions.Success,
        autoClose: true,
        keepAfterRouteChange: true,
        ...options,
        type: Notification.Type.Success,
        message
      })
    );
  }

  error(message: SafeHtml | string, options?: Partial<Notification.Model>): void {
    this.alert(
      new Notification.Model({
        icon: Icons.Actions.Error,
        autoClose: true,
        keepAfterRouteChange: true,
        type: Notification.Type.Error,
        ...options,
        message
      })
    );
  }

  info(message: SafeHtml | string, options?: Partial<Notification.Model>): void {
    this.alert(
      new Notification.Model({
        icon: Icons.Actions.Info,
        autoClose: true,
        keepAfterRouteChange: true,
        type: Notification.Type.Info,
        ...options,
        message
      })
    );
  }

  warning(message: SafeHtml | string, options?: Partial<Notification.Model>): void {
    this.alert(
      new Notification.Model({
        icon: Icons.Actions.Warning,
        autoClose: true,
        keepAfterRouteChange: true,
        ...options,
        type: Notification.Type.Warning,
        message
      })
    );
  }

  clear(id = this.defaultId): void {
    this.notificationSubject.next(new Notification.Model({ id }));
  }

  private alert(notification: Notification.Model): void {
    notification.id = notification.id || this.defaultId;
    if (notification.imageUrl) {
      delete notification.icon;
    }
    this.notificationSubject.next(notification);
  }
}
