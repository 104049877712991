import { Component, Input } from '@angular/core';
import { NgControl } from '@angular/forms';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { BaseControlValueAccessorComponent } from '@app/modules/shared/form/components/base-control-value-accessor/base-control-value-accessor.component';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-switch',
    templateUrl: './switch.component.html',
    styleUrls: ['./switch.component.scss'],
    standalone: true,
    imports: [NgIf, FaIconComponent],
})
export class SwitchComponent extends BaseControlValueAccessorComponent<boolean> {
  @Input() label: string;
  @Input() icon: IconProp;
  @Input() options: string[] = ['Yes', 'No'];

  constructor(override controlDirective: NgControl) {
    super(controlDirective);
  }

  changeValue(): void {
    this.value = !Boolean(this.value);
  }
}
