<h2 class="header">Payment info</h2>

<div class="content">
  <app-payment-info [fixture]="inputs.fixture"></app-payment-info>
</div>

<div class="actions">
  <button type="button" class="btn--lg" (click)="close()">
    Close
  </button>
</div>
