import { Pipe, PipeTransform } from '@angular/core';
import { User } from '@app/domain/users/models/user.model';

@Pipe({
    name: 'displayName',
    standalone: true
})
export class DisplayNamePipe implements PipeTransform {
  transform(user: Partial<User.Model> | undefined): string {
    if (!user) {
      return '';
    }
    if (user.deleted) {
      return 'Deleted user';
    }
    return user.displayName || 'New user';
  }
}
